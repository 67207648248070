/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { Col, Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { useLocation, useParams } from "react-router";

import styles from "./styles.module.scss";

import SideBarLink from "@/Components/SideBarLink";
import ProfileIcon from "@/Assets/Icons/Coloured/profile.svg";
import { AccountPage } from "@/Pages/Profile/AccountPage";
import urlHelper from "@/Utils/urlHelper";
import PersonalInformationTab from "@/FlexPlan/Pages/Participants/ParticipantProfile/Tabs/PersonalInformation";
import PlanInformationTab from "@/FlexPlan/Pages/Participants/ParticipantProfile/Tabs/PlanInformation";
import { useFlexPlanParticipantContext } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Contexts/ParticipantProfileContext";

interface LocationState {
    from: string,
}

const ParticipantProfile = () => {
    // Hooks
    const location = useLocation<LocationState>();
    const { id } = useParams();

    // State
    const [isOpen, toggleOpen] = useState(false);

    // Context
    const { formState, setParticipantId } = useFlexPlanParticipantContext();

    // Functionality
    const toggle = () => toggleOpen(prev => !prev);
    useEffect(() => {
        setParticipantId(id);
    }, [id]);
    // Allow tabs reset the profile state. This ensures all tabs hold the same state

    const renderTabs = () => (
        <Row className="border-bottom my-2 tw-mx-5">
            <Nav tabs className="border-0">
                <NavItem className={classnames(urlHelper.urlContains(location, "personal-information") ? styles.activeTab : styles.tab, "font-weight-bold mx-4")}>
                    <NavLink className="px-0 border-0 text-primary" tag={Link} to={`/participants/${formState.id}/personal-information`}>
                        Personal Information
                    </NavLink>
                </NavItem>
                <NavItem className={classnames(urlHelper.urlContains(location, "plan-information") ? styles.activeTab : styles.tab, "font-weight-bold mx-4")}>
                    <NavLink className="px-0 border-0 text-primary" tag={Link} to={`/participants/${formState.id}/plan-information`}>
                        Plan Information
                    </NavLink>
                </NavItem>
            </Nav>
        </Row>
    );

    return (
        <Row className="box-shadow bg-white mt-5 mb-5 d-block d-md-flex mx-md-5">
            <Col md="4" lg="3" xl="2" className="border-right py-4 px-0 d-none d-md-flex flex-column ">
                <h3 className="m-3 ml-lg-4 pl-0 pl-lg-2 mb-md-4 d-none d-lg-block font-weight-bold">{formState?.fullname}</h3>
                <h5 className="m-3 ml-lg-4 pl-0 pl-lg-2 mb-md-4 d-block d-lg-none font-weight-bold">{formState?.fullname}</h5>
                <SideBarLink
                    icon={<ProfileIcon />}
                    text="Information"
                    isActive // It's the only sidebar link
                    to="/profile/info"
                />
            </Col>
            <Col>
                <Row>
                    <Col className={styles.header}>
                        <h4 className="d-inline text-white position-relative font-weight-bold">My Information</h4>
                    </Col>
                </Row>
                <Row>
                    <Navbar light className="col-12 px-0 d-md-none">
                        <NavbarToggler onClick={toggle} className="ml-auto mr-3 my-2" />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav navbar className="my-3">
                                <NavItem>
                                    <SideBarLink
                                        icon={<ProfileIcon />}
                                        text="My Information"
                                        isActive={location.pathname === "/profile/info"}
                                        to="/profile/info"
                                    />
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Row>
                <Route
                    path="/participants"
                    render={({ match: { url } }) => (
                        <Switch>
                            <Route
                                exact
                                path={`${url}/${id}/personal-information`}
                                render={() => (
                                    <>
                                        {renderTabs()}
                                        <PersonalInformationTab />
                                    </>
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/${id}/plan-information`}
                                render={() => (
                                    <>
                                        {renderTabs()}
                                        <PlanInformationTab />
                                    </>
                                )}
                            />
                            <Route path={`${url}/account`} component={AccountPage} />
                            <Redirect to={`${url}/info`} />
                        </Switch>
                    )}
                />
            </Col>
        </Row>
    );
};

export default ParticipantProfile;
