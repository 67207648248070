export interface sicSection {
    title: string;
    label: string;
    value: number;
    industryId?: number,
}

export interface sicRange {
    value: number;
    startRange: number;
    endRange: number
}

export const sicSections: sicSection[] = [
    { title: "All Sections", label: "All", value: 1 },
    { title: "Section A", label: "Agriculture, Forestry and Fishing", value: 2, industryId: 4 },
    { title: "Section B", label: "Mining and Quarrying", value: 3, industryId: 13 },
    { title: "Section C", label: "Manufacturing", value: 4, industryId: 12 },
    { title: "Section D", label: "Electricity, gas, steam and air conditioning supply", value: 5, industryId: 8 },
    { title: "Section E", label: "Water supply, sewerage, waste management and remediation activities", value: 6, industryId: 22 },
    { title: "Section F", label: "Construction", value: 7, industryId: 6 },
    { title: "Section G", label: "Wholesale and retail trade; repair of motor vehicles and motorcycles", value: 8, industryId: 23 },
    { title: "Section H", label: "Transportation and storage", value: 9, industryId: 20 },
    { title: "Section I", label: "Accommodation and food service activities", value: 10, industryId: 2 },
    { title: "Section J", label: "Information and communication", value: 11, industryId: 11 },
    { title: "Section K", label: "Financial and insurance activities", value: 12, industryId: 18 },
    { title: "Section L", label: "Real estate activities", value: 13, industryId: 17 },
    { title: "Section M", label: "Professional, scientific and technical activities", value: 14, industryId: 15 },
    { title: "Section N", label: "Administrative and support service activities", value: 15, industryId: 3 },
    { title: "Section O", label: "Public administration and defence; compulsory social security", value: 16, industryId: 16 },
    { title: "Section P", label: "Education", value: 17, industryId: 7 },
    { title: "Section Q", label: "Human health and social work activities", value: 18, industryId: 10 },
    { title: "Section R", label: "Arts, entertainment and recreation", value: 19, industryId: 5 },
    { title: "Section S", label: "Other service activities", value: 20, industryId: 14 },
    { title: "Section T",
        label: "Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use",
        value: 21,
        industryId: 14 },
    { title: "Section U", label: "Activities of extraterritorial organisations and bodies", value: 22, industryId: 24 },
];

export const sicRanges: sicRange[] = [
    { value: 1, startRange: 0, endRange: 100000 },
    { value: 2, startRange: 0, endRange: 4999 },
    { value: 3, startRange: 5000, endRange: 9999 },
    { value: 4, startRange: 10000, endRange: 34999 },
    { value: 5, startRange: 35000, endRange: 35999 },
    { value: 6, startRange: 36000, endRange: 40999 },
    { value: 7, startRange: 41000, endRange: 44999 },
    { value: 8, startRange: 45000, endRange: 48999 },
    { value: 9, startRange: 49000, endRange: 54999 },
    { value: 10, startRange: 55000, endRange: 57999 },
    { value: 11, startRange: 58000, endRange: 63999 },
    { value: 12, startRange: 64000, endRange: 67999 },
    { value: 13, startRange: 68000, endRange: 68999 },
    { value: 14, startRange: 69000, endRange: 76999 },
    { value: 15, startRange: 77000, endRange: 83999 },
    { value: 16, startRange: 84000, endRange: 84999 },
    { value: 17, startRange: 85000, endRange: 85999 },
    { value: 18, startRange: 86000, endRange: 89999 },
    { value: 19, startRange: 90000, endRange: 93999 },
    { value: 20, startRange: 94000, endRange: 96999 },
    { value: 21, startRange: 97000, endRange: 98999 },
    { value: 22, startRange: 99000, endRange: 99999 },
];
