import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Button, Col, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";
import { Image } from "@/Components/Image";
import Spinner from "@/Components/Spinner";
import { OrderDetailsModalHeader } from "@/Components/OrderDetailsModalHeader";
import CustomerInformation from "@/Components/CustomerInformation";
import OrderStatusIcon from "@/Components/OrderStatusIcon";
import { OrderStatusDropDown, orderStatuses } from "@/Components/OrderStatusDropDown";
import { DropZoneMulti } from "@/Components/Dropzones/DropZoneMulti";
import ComponentBuilder from "@/Components/ComponentBuilder";
import EscapeOnCloseWrapper from "@/Wrappers/EscapeOnCloseWrapper";
import { PaymentHistoryModal } from "@/Modals/PaymentHistoryModal";
import { OrderTypes } from "@/Apis/Orders";
import { ConfirmFormCompanyModal } from "@/Modals/ConfirmFormCompanyModal";
import { cancelStripeSubscription } from "@/Apis/Subscriptions";
import { FormCompanyTab, IdentityDocuments } from "@/Modals/SupplierOrderDetailsModal/FormCompanyTab";
import { FormEmailCredentialsTab } from "@/Modals/SupplierOrderDetailsModal/FormEmailCredentialsTab";
import { TransferCompanyTab } from "@/Modals/SupplierOrderDetailsModal/TransferCompanyTab";
import { BankAccountTab } from "@/Modals/SupplierOrderDetailsModal/BankAccountTab";
import globalActions from "@/Store/Global/actions";
import api from "@/Utils/api";

const tabs = {
    PRODUCT_FORM: 1,
    CUSTOMER_INFO: 2,
    ATTACHMENTS: 3,
    FORM_COMPANY: 4,
    EMAIL_CREDENTIALS: 5,
    TRANSFER_COMPANY: 6,
    BANK_ACCOUNT: 7,
};

const SupplierOrderDetailsModal = ({ id, onOrderStatusChanged }) => {
    const dispatch = useDispatch();
    const onPush = path => dispatch(push(path));

    const [activeTab, setActiveTab] = useState(tabs.PRODUCT_FORM);
    const [order, setOrder] = useState({
        supplierAttachments: [],
        orderFields: [],
        customerInformationFields: [],
    });
    const [loading, setLoading] = useState(true);
    const [displayFormCompanyModal, setDisplayFormCompanyModal] = useState(false);
    const [apiError, setApiError] = useState("");

    const [selectedStatus, setSelectedStatus] = useState(orderStatuses[0]);
    const [savedStatus, setSavedStatus] = useState();
    const [isStatusUpdating, setStatusUpdating] = useState(false);
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.get(`supplier-orders/${id}`)
            .then((response) => {
                setOrder(response);
                setSavedStatus(response.status);
                setSelectedStatus(response.status);
                setLoading(false);
            })
            .catch((error) => {
                onPush({ pathname: "/error", state: { error, referrer: window.location.href } });
            });
    }, [id]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setApiError("");
        }, 4000);

        return () => {
            clearTimeout(timeout);
        };
    }, [apiError]);

    const setStatusApi = (body) => {
        api.put(`supplier-orders/${order.id}/status`, body)
            .then(() => {
                setStatusUpdating(true);
                setSavedStatus(selectedStatus);
                setStatusUpdating(false);
                onOrderStatusChanged(order.id, selectedStatus);
            })
            .catch((error) => {
                if (error.errors && error.errors.status) {
                    setApiError(error.errors.status);
                } else {
                    setApiError(error.message || error);
                }
            });
    };

    const submitOrderStatusHandler = () => {
        setStatusApi({ status: selectedStatus });
    };

    const onStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const addAttachmentApi = async (files) => {
        await api.post(`supplier-orders/${order.id}/attachments`, { attachments: files })
            .catch((error) => {
                setApiError(error.message || error);
            });
    };

    const deleteAttachmentApi = async (file) => {
        if (file.fileName !== "") {
            await api.del(`supplier-orders/${order.id}/attachments/${file.fileName}`, {})
                .catch((error) => {
                    setApiError(error.message || error);
                });
        }
    };

    const toggleSetDisplayFormCompanyModal = () => {
        setDisplayFormCompanyModal(!displayFormCompanyModal);
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const saveFieldDetails = field => api.post(`supplier-orders/${id}/customer-information`, field);

    const deleteFieldDetails = fieldId => api.del(`supplier-orders/${id}/customer-information/${fieldId}`);

    const close = () => onPush("/supplier-orders");

    const cancelSubscription = () => {
        setLoading(true);
        cancelStripeSubscription(order.stripeSubscriptionId)
            .then(() => {
                onOrderStatusChanged(order.id, "Cancelled");
                close();
                dispatch(globalActions.setToastMessage(true, "Successfully cancelled order."));
            })
            .catch((error) => {
                setApiError(error.message || error);
            })
            .finally(() => setLoading(false));
    };

    const viewPaymentHistory = () => {
        setShowPaymentHistory(true);
    };

    const renderAnswer = (x) => {
        const field = [{ ...x, value: x.answer }];
        return (
            <div className={styles.orderFieldReadOnly} key={x.displayText}>
                <ComponentBuilder
                    fields={field}
                    onChange={() => () => {}}
                    disabled
                />
            </div>
        );
    };

    const renderProductForm = () => {
        if (order.orderFields.length === 0) {
            return (<p className="font-weight-bold">No customer data available</p>);
        }

        return order.orderFields.filter(x => x.displayText !== IdentityDocuments.ProofOfID && x.displayText !== IdentityDocuments.ProofOfAddress).map(x => (
            x.answer ? renderAnswer(x) : (
                <div className={styles.orderFieldReadOnly} key={x.displayText}>
                    <label>{x.displayText}</label><p>No answer provided</p>
                </div>
            )
        ));
    };

    return (
        <Modal isOpen centered size="lg" contentClassName="bg-white mx-5">
            <ModalBody className="d-flex flex-column p-3 p-sm-5 flex-grow-1 position-relative">
                <CloseButton onClick={close} />
                {loading ? <Spinner className="align-self-center" /> : (
                    <>
                        <Row>
                            <Col
                                sm="4"
                                className={classnames(styles.imageContainer, "d-flex justify-content-center mb-2 pr-3")}
                            >
                                {order.supplierLogo && <Image src={order.supplierLogo} alt="logo" />}
                                {order.serviceImage && <Image src={order.serviceImage} alt="logo" />}
                            </Col>
                            <Col>
                                <OrderDetailsModalHeader {...order} />
                                <OrderStatusIcon status={savedStatus} />
                                <OrderStatusDropDown
                                    onSubmit={submitOrderStatusHandler}
                                    onStatusChange={onStatusChange}
                                    selectedStatus={selectedStatus}
                                    isUpdating={isStatusUpdating}
                                />
                            </Col>
                        </Row>

                        {order.serviceDescription && <p>{order.serviceDescription}</p>}

                        <div className="d-flex flex-column pt-3">

                            {order.subscriptionPaymentStatus && (
                                <>
                                    <Button
                                        className="mb-3 mt-1"
                                        block
                                        color="primary"
                                        disabled={loading}
                                        onClick={viewPaymentHistory}
                                    >
                                        View Payment History
                                    </Button>
                                </>)}

                            {order.subscriptionPaymentStatus && order.status !== "Cancelled" && (
                                <>
                                    <Button
                                        className="mb-3 mt-1"
                                        block
                                        color="danger"
                                        disabled={loading}
                                        onClick={cancelSubscription}
                                    >
                                        Cancel Subscription
                                    </Button>
                                </>)}
                            <Button
                                className="mb-3 mt-1"
                                block
                                color="secondary"
                                disabled={loading}
                                onClick={() => onPush(`/messages/inbox?new&userId=${order.customerId}`)}
                            >
                                Contact Buyer
                            </Button>
                        </div>

                        {apiError && <div><p className="alert alert-danger">{apiError}</p></div>}

                        <Nav tabs className="my-4">
                            <NavItem>
                                <NavLink
                                    className={classnames(styles.tab, { [styles.activeTab]: activeTab === tabs.PRODUCT_FORM }, "px-0 mr-3 font-weight-bold border-0")}
                                    onClick={() => {
                                        toggleTab(tabs.PRODUCT_FORM);
                                    }}
                                >
                                    Product Form
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames(styles.tab, { [styles.activeTab]: activeTab === tabs.CUSTOMER_INFO }, "px-0 font-weight-bold border-0")}
                                    onClick={() => {
                                        toggleTab(tabs.CUSTOMER_INFO);
                                    }}
                                >
                                    Customer Information
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames(styles.tab, { [styles.activeTab]: activeTab === tabs.ATTACHMENTS }, "px-0 ml-3 font-weight-bold border-0")}
                                    onClick={() => {
                                        toggleTab(tabs.ATTACHMENTS);
                                    }}
                                >
                                    Attachments
                                </NavLink>
                            </NavItem>
                            {order.orderType === OrderTypes.FormationsProduct && (
                                <NavItem>
                                    <NavLink
                                        className={classnames(styles.tab, { [styles.activeTab]: activeTab === tabs.FORM_COMPANY }, "px-0 ml-3 font-weight-bold border-0")}
                                        onClick={() => {
                                            toggleTab(tabs.FORM_COMPANY);
                                        }}
                                    >
                                        Form Company
                                    </NavLink>
                                </NavItem>
                            )}
                            {order.orderType === OrderTypes.ReadyMadeFormationsProduct && (
                                <NavItem>
                                    <NavLink
                                        className={classnames(styles.tab, { [styles.activeTab]: activeTab === tabs.EMAIL_CREDENTIALS }, "px-0 ml-3 font-weight-bold border-0")}
                                        onClick={() => {
                                            toggleTab(tabs.EMAIL_CREDENTIALS);
                                        }}
                                    >
                                        Email Credentials
                                    </NavLink>
                                </NavItem>
                            )}
                            {order.orderType === OrderTypes.ReadyMadeFormationsProduct && (
                                <NavItem>
                                    <NavLink
                                        className={classnames(styles.tab, { [styles.activeTab]: activeTab === tabs.TRANSFER_COMPANY }, "px-0 ml-3 font-weight-bold border-0")}
                                        onClick={() => {
                                            toggleTab(tabs.TRANSFER_COMPANY);
                                        }}
                                    >
                                        Transfer Company
                                    </NavLink>
                                </NavItem>
                            )}
                            {order.orderType === OrderTypes.BankAccountProduct && (
                                <NavItem>
                                    <NavLink
                                        className={classnames(styles.tab, { [styles.activeTab]: activeTab === tabs.BANK_ACCOUNT }, "px-0 ml-3 font-weight-bold border-0")}
                                        onClick={() => {
                                            toggleTab(tabs.BANK_ACCOUNT);
                                        }}
                                    >
                                        Bank
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={tabs.PRODUCT_FORM}>
                                <FormCompanyTab
                                    setApiError={setApiError}
                                    order={order}
                                    toggleSetDisplayFormCompanyModal={toggleSetDisplayFormCompanyModal}
                                    loading={loading}
                                />
                                {renderProductForm()}
                            </TabPane>
                            <TabPane tabId={tabs.CUSTOMER_INFO}>
                                <CustomerInformation
                                    fields={order.customerInformationFields}
                                    saveFieldDetails={saveFieldDetails}
                                    deleteFieldDetails={deleteFieldDetails}
                                />
                            </TabPane>
                            <TabPane tabId={tabs.ATTACHMENTS}>
                                <DropZoneMulti
                                    onAdd={addAttachmentApi}
                                    onDelete={deleteAttachmentApi}
                                    initialFiles={order.supplierAttachments}
                                />
                            </TabPane>
                            <TabPane tabId={tabs.FORM_COMPANY}>
                                <FormCompanyTab
                                    setApiError={setApiError}
                                    order={order}
                                    toggleSetDisplayFormCompanyModal={toggleSetDisplayFormCompanyModal}
                                    loading={loading}
                                    formCompanyButton
                                />
                            </TabPane>
                            <TabPane tabId={tabs.EMAIL_CREDENTIALS}>
                                <FormEmailCredentialsTab
                                    orderId={order.id}
                                    loading={loading}
                                />
                            </TabPane>
                            {order.orderType === OrderTypes.ReadyMadeFormationsProduct && (
                                <TabPane tabId={tabs.TRANSFER_COMPANY}>
                                    <TransferCompanyTab
                                        orderId={order.id}
                                        loading={loading}
                                    />
                                </TabPane>
                            )}
                            {order.orderType === OrderTypes.BankAccountProduct && (
                                <TabPane tabId={tabs.BANK_ACCOUNT}>
                                    <BankAccountTab
                                        orderId={order.id}
                                        loading={loading}
                                    />
                                </TabPane>
                            )}
                        </TabContent>
                    </>
                )}
                {showPaymentHistory && (
                    <PaymentHistoryModal orderId={order.id} onClose={() => setShowPaymentHistory(false)} />)}

                {displayFormCompanyModal && (
                    <ConfirmFormCompanyModal
                        onClose={toggleSetDisplayFormCompanyModal}
                        orderId={order.id}
                        onSuccess={(status) => {
                            setOrder({ ...order, status });
                            setSavedStatus(status);
                            setSelectedStatus(status);
                            toggleSetDisplayFormCompanyModal();
                        }}
                    />
                )}
            </ModalBody>
        </Modal>
    );
};

SupplierOrderDetailsModal.propTypes = {
    id: PropTypes.string.isRequired,
    onOrderStatusChanged: PropTypes.func.isRequired,
};

const EscapedOrderDetailsModal = EscapeOnCloseWrapper(SupplierOrderDetailsModal);
export {
    EscapedOrderDetailsModal as SupplierOrderDetailsModal,
    SupplierOrderDetailsModal as PureSupplierOrderDetailsModal,
};
