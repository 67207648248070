/* eslint-disable import/extensions */
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { DebounceTextbox } from "@/Components/DebounceTextbox";
import { DropZoneArea } from "@/Components/Dropzones/DropZoneArea";
import { acceptedFileTypes } from "@/Utils/constants";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import Spinner from "@/Components/Spinner";
import formatDateHelper from "@/Utils/formatDateHelper";
import styles from "@/Components/Dropzones/DropZoneArea/styles.module.scss";
import { useDocuments } from "@/Hooks/Documents";
import { usePagination } from "@/Hooks/Pagination";
import { Document, DocumentStatus } from "@/Apis/Documents";
import { Urls } from "@/Apis/urls";
import { useForm } from "@/Hooks/useForm";

const DocumentsPage = () => {
    const currentUserEmail = useSelector((state: any) => state.user.email);
    const {
        items: documents,
        loading,
        paging,
        reloadItems,
        setSearchFieldsObject,
        showPreviousPage,
    } = usePagination<Document, { search: string, userEmail: string }>(Urls.documents.get, { pageNumber: 0, pageLength: 5 });

    const {
        onSave,
        onDelete,
        onDownload,
        toggleDeleteConfirmationModal,
        confirmDeleteModalOpen,
        processingAction,
        documentForDelete,
    } = useDocuments(reloadItems, showPreviousPage);

    const { onDebounceSearch, formState } = useForm<{ search: string, userEmail: string }>({
        search: "",
        userEmail: currentUserEmail,
    });

    useEffect(() => {
        setSearchFieldsObject(formState);
    }, [formState]);

    const onSaveDocuments = (docs: Document[]) => {
        const newDocs = docs?.map(x => ({ ...x, status: DocumentStatus.New }));
        onSave(newDocs);
    };

    return (
        <div className="border rounded-lg p-2 p-md-4 m-lg-3 mb-4 mt-2">
            <h4 className="font-weight-bold mb-4">Company Documents</h4>
            <DebounceTextbox
                placeholder="Search Documents"
                callBack={onDebounceSearch("search")}
                name="Search Documents"
                displaySearchIcon
                className="md:tw-w-1/3 tw-mt-10"
            />
            <DropZoneArea
                onAdd={onSaveDocuments}
                accept={acceptedFileTypes.images + acceptedFileTypes.documents}
                disabled={processingAction}
                className={classnames(styles.dropzone, "md:tw-w-2/3 tw-mt-7")}
            >
                {processingAction ? (
                    <Spinner className="tw-mx-auto tw-my-5" />
                ) : (
                    <div className="tw-py-6 tw-text-center">
                        <div>Drag and drop documents to upload here</div>
                        <div>Or Tap to select documents to upload</div>
                    </div>
                )}
            </DropZoneArea>
            <div className="tw-mt-7">
                <div className="tw-flex tw-flex-col tw-space-y-2">
                    {loading && (
                        <Spinner className="tw-mx-auto tw-my-10" text="Loading documents..." />
                    )}
                    {documents && !loading && documents.map(document => (
                        <div
                            className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-border tw-border-brand-primary tw-px-3 tw-py-1 tw-rounded-md md:tw-w-2/3"
                            key={document.id}
                        >
                            <span className="tw-text-brand-primary">{document.name}</span>
                            <div className="tw-flex tw-flex-row tw-items-center md:tw-space-x-2">
                                <span className="tw-italic">{formatDateHelper.format(document.dateCreated, "DD/MM/YYYY")}</span>
                                <Button color="transparent" onClick={() => onDownload(document.id)} disabled={processingAction}>
                                    <i className="fa fa-download tw-text-brand-primary" />
                                </Button>
                                <Button color="transparent" onClick={() => toggleDeleteConfirmationModal(document)} disabled={processingAction}>
                                    <i className="fa fa-trash tw-text-red-600" />
                                </Button>
                            </div>
                        </div>
                    ))}
                    {documents && !loading && documents.length > 0 && paging}
                </div>
                {/* Confirm modal for deleting document */}
                <ConfirmCancelModal
                    isOpen={confirmDeleteModalOpen}
                    text={`Are you sure you want to delete document "${documentForDelete?.name}"`}
                    onConfirm={onDelete}
                    onClose={toggleDeleteConfirmationModal}
                    buttonConfirmText="Yes"
                />
            </div>
        </div>
    );
};
export { DocumentsPage };
