// eslint-disable-next-line no-nested-ternary
const toBool = (value: string) => (["True", "true"].find(x => x === value) ? true : ["False", "false"].find(x => x === value) ? false : undefined);

// Any custom token values will be sent over a string, we can try to convert them to their intended types
export const convertToken = <T>(token: any) => {
    const tempToken = token;
    Object.entries(token).forEach(([key, value]) => {
        if (typeof value === "string") { // Only custom values are definitely strings
            if (toBool(value) !== undefined) tempToken[key] = toBool(value);
            else if (Number(value)) tempToken[key] = Number(value);
        }
    });

    return tempToken as T;
};

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const deepEqual = (x, y) => {
    const ok = Object.keys; const tx = typeof x; const
        ty = typeof y;
    return x && y && tx === "object" && tx === ty ? (
        ok(x).length === ok(y).length
        && ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
};

export const instanceOf = <T>(object: any): object is T => "member" in object;
