import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Badge, Button, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import styles from "./styles.module.scss";

import { useAPI } from "@/Apis/useAPI";
import { removeProduct } from "@/Apis/Products";
import { AddToBasket } from "@/Components/AddToBasket";
import { ContextMenu } from "@/Components/ContextMenu";
import { Image } from "@/Components/Image";
import { ServiceTilePriceSection } from "@/Components/ServiceTilePriceSection";
import { ServiceMoreDetailsModal } from "@/Modals/ServiceMoreDetailsModal";
import { ProductVariants } from "@/Utils/ProductVariants";
import { ProductQuantity } from "@/Components/ProductQuantity";
import { ClickAwayListener } from "@/Components/ClickAwayListener";
import globalActions from "@/Store/Global/actions";
import { PricingPopover } from "@/Components/PricingPopover";
// eslint-disable-next-line import/no-cycle
import { UpsellModal } from "@/Modals/UpsellModal";
// eslint-disable-next-line import/extensions
import EyeHideIcon from "@/Assets/Icons/Coloured/eye-hide.svg";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { PurchaseUpsellsModal } from "@/Modals/PurchaseUpsellsModal";
import { Urls } from "@/Apis/urls";

const ServiceMiniSiteTile = (props) => {
    const [qty, setQty] = useState(props.minimumQuantity || 1);
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [showQuantityPopover, setShowQuantityPopover] = useState(false);
    const [upsellItems, setUpsellItems] = useState([]);
    const [showUpsell, setShowUpsell] = useState(false);
    const dispatch = useDispatch();
    const sendGtmEvent = useGTMDispatch();

    const { put } = useAPI();
    const isAdmin = useSelector(state => state.user.isAdmin);
    const [isProductTileHidden, setIsProductTileHidden] = useState(props.isProductTileHidden);
    const [isDeleteProductOpenModal, setIsDeleteProductOpenModal] = useState(false);
    const [isPurchaseUpsellsOpenModal, setIsPurchaseUpsellsOpenModal] = useState(false);

    const toggleQuantityPopover = () => {
        setShowQuantityPopover(!showQuantityPopover);
    };

    const sendToGtmEventHandler = (eventName) => {
        sendGtmEvent(
            {
                event: eventName,
                ecommerce: {
                    items: [
                        {
                            item_id: `${props.id}`,
                            item_name: `${props.name}`,
                            currency: "GBP",
                            item_brand: `${props.supplierName}`,
                            item_category: `${props.categoryName}`,
                            price: props.initialChargeWithVatIfApplicable,
                            quantity: qty,
                        },
                    ],
                },
            },
        );
    };

    const addToCart = () => {
        props.onAddService(qty, null);
        setQty(props.minimumQuantity || 1);
        if (props.upsellItems?.length && !props.isBeingUsedForPackages) {
            setShowUpsell(true);
            setUpsellItems(props.upsellItems);
        }
        sendToGtmEventHandler("add_to_cart");
    };

    const addToCartAndHidePopover = () => {
        addToCart();
        toggleQuantityPopover();
    };

    const closeUpsellModal = () => setShowUpsell(false);

    const onCartClick = () => {
        if (props.canChooseQuantity === true) {
            toggleQuantityPopover();
        } else {
            addToCart();
        }
    };

    const toggleShowMoreDetails = () => {
        setShowMoreDetails(!showMoreDetails);
    };

    const viewMoreDetail = () => {
        if (!props.slugId || !props.slug) {
            toggleShowMoreDetails();
        } else {
            sendToGtmEventHandler("view_item");
            dispatch(push(`/product/${props.slugId}/${props.slug}`));
        }
    };

    const addServiceFromMoreDetails = () => {
        props.onAddService(qty);
        toggleShowMoreDetails();
    };

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(props.id);
        dispatch(globalActions.setToastMessage(true, "Product details copied to clipboard"));
    };

    const hideProductTile = () => {
        put(Urls.products.hideTile(props.id), { isHidden: !isProductTileHidden })
            .then(() => {
                setIsProductTileHidden(!isProductTileHidden);
            })
            .catch();
    };

    const onConfirmDeleteProduct = async () => {
        removeProduct(props.id)
            .then(() => {
                props.onRefreshData();
                dispatch(globalActions.setToastMessage(true, "Product tile deleted successfully!"));
            })
            .catch()
            .finally(() => {
                setIsDeleteProductOpenModal(false);
            });
    };

    const priceProps = {
        quantity: qty,
        onQtyChanged: setQty,
        minimumQuantity: props.minimumQuantity,
        addQuantity: ProductVariants.canBeCustomised(props.productVariant) ? false : props.addQuantity,
        disableAddService: props.disableAddService,
        postageCharge: props.postageCharge,
        paymentFrequency: props.paymentFrequency,
        initialChargeWithVatIfApplicable: props.initialChargeWithVatIfApplicable,
        isBeingUsedForPackages: props.isBeingUsedForPackages,
    };

    const renderAddToBasketPopover = () => (
        showQuantityPopover && !props.disableAddService ? (
            <ClickAwayListener onClickAway={toggleQuantityPopover}>
                <div className={classnames(styles.quantityPopover, "position-absolute dropdown-menu show p-3 text-center")}>
                    <h6>Select quantity</h6>
                    <ProductQuantity minimumValue={props.minimumQuantity} value={qty} onValueChanged={(val) => setQty(val)} />
                    <button
                        type="button"
                        className={classnames(styles.addToCartButton, "text-dark rounded w-100 mt-2")}
                        onClick={addToCartAndHidePopover}
                        disabled={qty === 0}
                    >
                        Add to Cart
                    </button>
                </div>
            </ClickAwayListener>
        ) : null
    );

    return (
        <>
            <Card
                data-testid={props.name}
                className={classnames(styles.cardContainer, props.cardClassName, "d-inline-flex flex-column mx-2 bg-white mt-3 position-relative rounded-lg")}
            >
                { props.isBeingUsedForPackages && props.isInBasket && (
                    <Badge
                        className={classnames(styles.packageBadge, "rounded-circle position-absolute")}
                        color="secondary"
                    >
                        <i className="fas fa-box-open h5" data-testid="added-to-package-icon" />
                    </Badge>)}
                <div className="text-left p-0 tw-flex tw-items-center">
                    {isAdmin && props.isMarketplacePage && isProductTileHidden && (
                        <div className="tw-w-7 tw-pb-2">
                            <EyeHideIcon className="tw-fill-red-600" />
                        </div>
                    )}
                    <div>
                        <div className={classnames("d-flex align-items-center", styles.nameContainer)}>
                            <h5 data-testid="product-name" className={classnames(styles.name, "flex-grow-1 mb-0 font-weight-bold")}>{props.name}</h5>
                            <ContextMenu
                                testId="context-menu"
                                showCopy
                                onClickCopy={copyToClipboard}
                                showHide={isAdmin && props.isMarketplacePage}
                                showHideText={isProductTileHidden ? "Unhide" : "Hide"}
                                onClickHide={hideProductTile}
                                showDelete={isAdmin && props.isMarketplacePage}
                                onClickDelete={() => setIsDeleteProductOpenModal(true)}
                                showUpsell={isAdmin && props.isMarketplacePage}
                                onClickUpsell={() => setIsPurchaseUpsellsOpenModal(true)}
                            />
                        </div>
                        <div className={classnames(styles.categoryName, "mt-1 mb-2 text-muted")}>
                            <Link to={`/marketplace/supplier-directory/${props.supplierId}`}>{props.supplierName}</Link>
                            <span> • </span>
                            <Link to={`/marketplace?categorySelections=${props.categoryId}`}>{props.categoryName}</Link>
                        </div>
                        <PricingPopover
                            productId={props.id}
                            initialChargeWithVatIfApplicable={props.initialChargeWithVatIfApplicable}
                            initialCharge={props.initialCharge}
                            paymentFrequency={props.paymentFrequency}
                            postageCharge={props.postageCharge}
                            minimumQuantity={props.minimumQuantity}
                            isVatRequired={props.isVatRequired}
                            componentName="tile"
                            hasNAProducts={props.hasNAProducts}
                            isOffsitePayment={props.isOffsitePayment}
                            tileDisplay
                            isDelayedPayment={props.isDelayedPayment}
                            delayedPaymentFor={props.delayedPaymentFor}
                            delayedPaymentPeriod={props.delayedPaymentPeriod}
                        />
                    </div>
                </div>
                <div className={classnames(props.expandedMobile ? "flex-column" : "flex-sm-column", "d-flex justify-content-between")}>
                    <p
                        className={classnames(props.isDelayedPayment ? styles.descriptionWithLabel : styles.description,
                            props.expandedMobile ? styles.expanded : styles.condensed, "mt-lg-2 mb-0")}
                        data-testid="product-tile-description"
                    >
                        {props.description}
                    </p>
                    <div className={classnames(styles.supplierImageContainer, props.expandedMobile ? styles.expanded : styles.condensed,
                        "bg-light d-flex justify-content-center align-items-center")}
                    >
                        {
                            props.image && <Image
                                className={styles.productImage}
                                src={props.image}
                                alt="Product Image"
                                testId="product-tile-image"
                            />
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div>
                        <Button
                            onClick={viewMoreDetail}
                            color="primary"
                            outline
                            data-testid="service-tile-more-details-button"
                        >
                            View Details
                        </Button>
                    </div>
                    <div id={`quantityPopover-${props.id}-${props.isUpsell}`}>
                        {
                            props.showEditButton ? (
                                <Button onClick={props.onEditService} className={classnames(styles.editButton, "text-dark")} data-testid="product-tile-edit-button">
                                    <i className="fas fa-pencil-alt mt-1" /> <small>Edit Product/Service</small>
                                </Button>
                            ) : (
                                <>
                                    <AddToBasket
                                        id={props.id}
                                        minimumQuantity={props.minimumQuantity}
                                        name={props.name}
                                        initialChargeWithVatIfApplicable={props.initialChargeWithVatIfApplicable}
                                        postageCharge={props.postageCharge}
                                        disableAddService={props.disableAddService}
                                        onAddService={props.onAddService}
                                        productVariant={props.productVariant}
                                        variations={props.variations}
                                        isBeingUsedForPackages={props.isBeingUsedForPackages}
                                        priceElement={<ServiceTilePriceSection
                                            {...priceProps}
                                            containerClassNames="w-100 bg-white d-flex h-20px"
                                            priceFontClassNames="font-weight-bold h6"
                                        />}
                                        onCartClick={onCartClick}
                                        isInBasket={props.isInBasket}
                                        quantityInBasket={props.itemInCart?.quantity}
                                        canChooseQuantity={props.canChooseQuantity}
                                    />
                                    {renderAddToBasketPopover()}
                                    { showUpsell && <UpsellModal productIds={upsellItems} onClose={closeUpsellModal} /> }
                                </>
                            )
                        }
                    </div>
                </div>
            </Card>
            {showMoreDetails && (
                <ServiceMoreDetailsModal
                    onClose={toggleShowMoreDetails}
                    onAddService={addServiceFromMoreDetails}
                    disableAddService={props.disableAddService}
                    moreDetails={props.moreDetails}
                    images={props.images}
                    name={props.name}
                    pricingComponent={
                        <ServiceTilePriceSection
                            quantity={qty}
                            onQtyChanged={setQty}
                            minimumQuantity={props.minimumQuantity}
                            addQuantity={props.addQuantity}
                            disableAddService={props.disableAddService}
                            postageCharge={props.postageCharge}
                            paymentFrequency={props.paymentFrequency}
                            initialChargeWithVatIfApplicable={props.initialChargeWithVatIfApplicable}
                            isBeingUsedForPackages={props.isBeingUsedForPackages}
                        />
                    }
                    data-testid="service-tile-more-details-modal"
                />
            )}
            <ConfirmCancelModal
                isOpen={isDeleteProductOpenModal}
                text="Are you sure you want to delete this product?"
                buttonConfirmText="Yes, delete"
                onConfirm={onConfirmDeleteProduct}
                onClose={() => setIsDeleteProductOpenModal(false)}
            />
            {isPurchaseUpsellsOpenModal && (
                <PurchaseUpsellsModal
                    isOpenModal={isPurchaseUpsellsOpenModal}
                    productId={props.id}
                    onCancel={() => setIsPurchaseUpsellsOpenModal(false)}
                />
            )}
        </>
    );
};

ServiceMiniSiteTile.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    initialChargeWithVatIfApplicable: PropTypes.number.isRequired,
    initialCharge: PropTypes.number.isRequired,
    isVatRequired: PropTypes.bool.isRequired,
    paymentFrequency: PropTypes.string,
    postageCharge: PropTypes.number,
    onEditService: PropTypes.func,
    onAddService: PropTypes.func,
    disableAddService: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    showEditButton: PropTypes.bool,
    isInBasket: PropTypes.bool,
    itemInCart: PropTypes.shape({
        id: PropTypes.string,
        quantity: PropTypes.number,
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.string.isRequired,
    })),
    moreDetails: PropTypes.string,
    addQuantity: PropTypes.bool.isRequired,
    minimumQuantity: PropTypes.number,
    isBeingUsedForPackages: PropTypes.bool,
    variations: PropTypes.shape({}),
    productVariant: PropTypes.string,
    categoryName: PropTypes.string,
    categoryId: PropTypes.string,
    supplierId: PropTypes.string,
    supplierName: PropTypes.string,
    subCategoryName: PropTypes.string,
    slug: PropTypes.string,
    slugId: PropTypes.number,
    isUpsell: PropTypes.bool,
    canChooseQuantity: PropTypes.bool,
    expandedMobile: PropTypes.bool,
    hasNAProducts: PropTypes.bool,
    isOffsitePayment: PropTypes.bool,
    isDelayedPayment: PropTypes.bool,
    delayedPaymentFor: PropTypes.number,
    delayedPaymentPeriod: PropTypes.number,
    upsellItems: PropTypes.arrayOf(PropTypes.string),
    isProductTileHidden: PropTypes.bool,
    isMarketplacePage: PropTypes.bool,
    onRefreshData: PropTypes.func,
    cardClassName: PropTypes.string,
};

ServiceMiniSiteTile.defaultProps = {
    showEditButton: false,
    disableAddService: false,
    isInBasket: false,
    images: [],
    isUpsell: false,
    canChooseQuantity: false,
    expandedMobile: false,
    hasNAProducts: false,
    isOffsitePayment: false,
};

export { ServiceMiniSiteTile };
