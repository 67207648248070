import React, { useState } from "react";
import { Button } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import api from "@/Utils/api";
import { openB64ImageInNewTab } from "@/Utils/base64EncodeHelper";

interface Props {
    setApiError: any;
    order: {
        orderFields: Array<any>
        status: string
    };
    toggleSetDisplayFormCompanyModal: any;
    loading: boolean;
    formCompanyButton?: boolean;
}

export enum IdentityDocuments {
    ProofOfID = "Proof of ID",
    ProofOfAddress = "Proof of Address (Last 6 months)"
}

export const FormCompanyTab = ({ setApiError, order, toggleSetDisplayFormCompanyModal, loading, formCompanyButton = false }: Props) => {
    const [docLoading, setDocLoading] = useState({});

    const getIdentityDocument = async (path) => {
        await api.post("company-formations/getIdentityDocument", { IdentityPath: path })
            .then((response) => {
                openB64ImageInNewTab(response.image);
            })
            .catch((error) => {
                setApiError(error.message || error);
            });
    };

    const getKYCDocument = async (document) => {
        setDocLoading({ ...docLoading, [document]: true });
        const documentPath = order.orderFields.find(x => x.displayText === document).answer;
        await getIdentityDocument(documentPath);
        setDocLoading({ ...docLoading, [document]: false });
    };

    return (
        <>
            {
                Object.values(IdentityDocuments).map(doc => (
                    order.orderFields.find(x => x.displayText === doc)
                        && (
                            <Button
                                key={doc}
                                block
                                outline
                                color="secondary"
                                className={classnames(styles.kycContainer, "d-flex mb-3 align-items-center")}
                                onClick={() => getKYCDocument(doc)}
                                data-testid={`orderModal_formCompany_${doc}`}
                            >
                                <i className="far fa-id-card fa-5x" />
                                <div className="overflow-hidden text-left flex-grow-1">
                                    <strong className="m-0 ml-2">{doc}</strong>
                                </div>
                                {docLoading[doc] && <Spinner />}
                            </Button>
                        )))
            }
            {formCompanyButton && (
                <div className="mt-3 d-flex justify-content-end">
                    <Button
                        className="px-5"
                        color="warning"
                        disabled={loading || order.status !== "NewOrder"}
                        onClick={toggleSetDisplayFormCompanyModal}
                        data-testid="orderModal_formCompany_formCompanyButton"
                    >
                        {order.status !== "NewOrder" ? "Company Submitted" : "Form Company"}
                    </Button>
                </div>)}
        </>
    );
};
