import { AcceptRejectPageType } from "@/Apis/Receipts";
import urlHelper from "@/Utils/urlHelper";

const getDashboardApi = "dashboard";
const getCategoriesApi = "categories";

const Urls = {
    receipts: {
        sendToRecipient: (id: string) => `receipts/send-to-recipient/${id}`,
        downloadPdf: (id: string) => `receipts/pdf/${id}`,
        recipientSearch: (search: string) => `receipts/recipients?emailSearch=${encodeURIComponent(search)}`,
        create: "receipts",
        acceptReject: (pageType: AcceptRejectPageType, id: string) => `receipts/${pageType}/${id}`,
        packages: {
            previous: "receipts/packages/previous",
            single: (id: string | number) => `receipts/packages/${id}`,
        },
        getSingle: (id: string) => `receipts/${id}`,
    },
    receiptSavedItems: {
        lookup: (searchTerm: string) => `receipts/receipt-saved-items/lookup?searchTerm=${searchTerm}`,
    },
    receiptAttachments: {
        base: "receipts/receipt-attachments",
        getFile: (id: string) => `receipts/receipt-attachments/get-file/${id}`,
    },
    account: {
        createCustomer: "account/create-customer",
        externalSignIn: "account/external-sign-in",
        linkExternalProvider: "account/link-external-provider",

    },
    postPurchase: {
        getAttachments: "products/attachments",
    },
    products: {
        hideTile: (id: string) => `products/hide/${id}`,
    },
    userUpsells: {
        base: "user-upsells",
        get: (id: string) => `user-upsells/${id}`,
        tags: {
            base: "tags",
        },
        userTypes: {
            base: "user-types",
        },
        userTagStatuses: {
            base: "user-tag-statuses",
            saveCustomerUserType: "user-tag-statuses/customer-user-type",
            moveTags: "user-tag-statuses/move-tags",
        },
    },
    orders: {
        failedSubscriptionPayment: "subscriptions/failed-order-payment",
        failedSubscriptionOrderResolved: "subscriptions/subscription-order-resolved",
    },
    profile: {
        document: {
            save: "document",
            downloadDocument: (documentId: string) => `document/${documentId}`,
            delete: (documentId: string) => `document/${documentId}`,
            get: (searchFields?: object) => `document${searchFields ? urlHelper.generateObjectToQueryString(searchFields) : ""}`,
        },
    },
    rmcs: {
        base: "rmcs",
        update: (id: string) => `rmcs/${id}`,
    },
    documents: {
        getDocumentUsers: (searchTerm: string) => `document/document-users?searchTerm=${encodeURIComponent(searchTerm)}`,
        get: (searchFields: object) => `document${searchFields && urlHelper.generateObjectToQueryString(searchFields)}`,
        updateCompanyDocument: (id: string) => `document/update-company-document/${id}`,
    },
    notificationTemplate: "notification-template",
    postForwardingAddresses: {
        base: "post-forwarding-addresses",
        get: (searchFields: object) => `post-forwarding-addresses${searchFields && urlHelper.generateObjectToQueryString(searchFields)}`,
        update: (id: string) => `post-forwarding-addresses/${id}`,
        getPdf: "post-forwarding-addresses/pdf",
    },
    packages: {
        redeemPackages: (code: string, productIds: string) => `packages/${encodeURI(code)}?productIds=${productIds}`,
    },
    bankAccounts: {
        base: "bank-accounts",
        get: (id: string) => `bank-accounts/${id}`,
        upsertLimitedCompany: (id: string) => `bank-accounts/${id}`,
    },
    reviews: {
        adminGetAll: (searchFields: object) => `reviews/admin/all${urlHelper.generateObjectToQueryString(searchFields)}`,
        get: (searchFields: object) => `reviews${urlHelper.generateObjectToQueryString(searchFields)}`,
    },
};

export {
    Urls,
    getDashboardApi,
    getCategoriesApi,
};
