/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { AuthenticationPage } from "@/Pages/AuthenticationPage";
import SuppliersIcon from "@/Assets/Icons/Coloured/build-package.svg";
import OneAccountIcon from "@/Assets/Icons/Coloured/package-code.svg";
import ReferralsIcon from "@/Assets/Icons/Coloured/build-a-package.svg";
import Logo from "@/Assets/Icons/Solid/logo.svg";
import CirclePattern from "@/Assets/Icons/Solid/circle-pattern.svg";

const SignUpBackgroundWrapper = props => (
    <AuthenticationPage hideFooter>
        <div className={classnames(styles.container, "tw-w-screen tw-h-screen 2xl:tw-flex 2xl:tw-justify-center 2xl:tw-items-center")}>
            <div className={classnames(
                styles.innerContainer,
                "tw-h-full tw-w-full tw-relative tw-flex tw-flex-row tw-drop-shadow-lg 2xl:tw-h-5/6 2xl:tw-w-2/3 2xl:tw-max-w-screen-2xl",
            )}
            >
                <div className="
                    tw-bg-brand-primary tw-basis-1/2 tw-bg-opacity-70 tw-hidden tw-relative tw-overflow-y-hidden
                    lg:tw-flex lg:tw-flex-col lg:tw-justify-between lg:tw-items-center 2xl:tw-rounded-l-lg"
                >
                    <div className="tw-absolute tw-top-0 tw-left-0">
                        <CirclePattern className="tw-ml-3 tw-mt-3" />
                    </div>
                    <div className="tw-self-start">
                        &nbsp;
                    </div>
                    <div className="tw-flex tw-flex-col tw-space-y-12">
                        <div className="tw-flex tw-flex-row tw-space-x-6 tw-items-center">
                            <SuppliersIcon className="tw-h-14 tw-w-14" />
                            <span className="tw-text-white tw-text-3xl">Hundreds of B2B Suppliers</span>
                        </div>
                        <div className="tw-flex tw-flex-row tw-space-x-6 tw-items-center">
                            <OneAccountIcon className="tw-h-14 tw-w-14" />
                            <span className="tw-text-white tw-text-3xl">Purchase with One Account</span>
                        </div>
                        <div className="tw-flex tw-flex-row tw-space-x-6 tw-items-center">
                            <ReferralsIcon className="tw-h-14 tw-w-14" />
                            <span className="tw-text-white tw-text-3xl">Earn through Referrals</span>
                        </div>
                    </div>
                    <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-center tw-space-x-6 tw-my-8">
                        <Logo className="tw-h-16 tw-w-16 tw-text-white" />
                        <span className="tw-text-white tw-text-xl">Business Support Club</span>
                    </div>
                    <div className="tw-absolute tw-bottom-0 tw-w-full tw-h-5 tw-bg-[#fbb648] 2xl:tw-rounded-bl-lg">&nbsp;</div>
                </div>
                <div className="
                    lg:tw-h-full tw-basis-full lg:tw-basis-1/2 tw-flex tw-p-4 tw-overflow-y-auto
                    2xl:tw-bg-white 2xl:tw-bg-opacity-90 2xl:tw-rounded-r-lg"
                >
                    <div className="
                        tw-bg-white tw-bg-opacity-90 tw-w-full tw-rounded-lg tw-drop-shadow-lg
                        tw-flex tw-flex-col tw-p-4 lg:tw-m-auto
                        2xl:tw-bg-opacity-0 2xl:tw-drop-shadow-none 2xl:tw-mx-16"
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </AuthenticationPage>
);

SignUpBackgroundWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SignUpBackgroundWrapper;
