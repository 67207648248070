import React, { useEffect, useState } from "react";

import { Paging } from "@/Components/Paging";
import { useAPI } from "@/Apis/useAPI";
import { Pagination } from "@/Hooks/Pagination/Types";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { PaginationResult } from "@/Apis/Pagination";

// This URL is a function that converts an object to a query string
const usePagination = <TResultsModel extends {}, TSearchFields extends {}>
    (url: (queryStringObj: object) => string, initialPagination: Pagination) => {
    const { setPopupErrorMessage } = useToastMessageContext();
    const {
        get,
        loading,
    } = useAPI({ handle500WithToastMessage: true });

    const [searchFieldObject, setSearchFieldsObject] = useState<TSearchFields>();
    const [pagination, setPagination] = useState<Pagination>(initialPagination);
    const setPageNumber = (pageNumber: number) => setPagination(prev => ({
        ...prev,
        pageNumber,
    }));

    const [items, setItems] = useState<TResultsModel[]>();
    const [reloadItemsToggle, setReloadItemsToggle] = useState<boolean>(false);

    const reloadItems = () => setReloadItemsToggle(prev => !prev);

    const showPreviousPage = () => {
        // If you delete the last item on the last page, select the previous page.
        const isLastOneOnLastPage = items && items?.length === 1
            && pagination.pageNumber > 0 && pagination.totalPageCount && pagination.pageNumber === pagination.totalPageCount - 1;
        if (isLastOneOnLastPage) {
            setPageNumber(pagination.pageNumber - 1);
        }
    };

    useEffect(() => {
        if (!searchFieldObject) {
            return;
        }

        get<PaginationResult<TResultsModel>>(url({
            ...searchFieldObject,
            ...pagination,
        }))
            .then(response => {
                setItems(response.items);
                setPagination(prev => ({ ...prev, totalPageCount: response.totalPages }));
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error);
                } else if (error.message) {
                    setPopupErrorMessage(error.message);
                }
            });
    }, [searchFieldObject, pagination.pageNumber, pagination.pageLength, reloadItemsToggle]);

    return {
        paging: <Paging
            pageCount={pagination.totalPageCount ?? 0}
            activePage={pagination.pageNumber}
            onChange={setPageNumber}
        />,
        setSearchFieldsObject,
        searchFieldObject,
        loading,
        items,
        reloadItems,
        reloadItemsToggle,
        setPageNumber,
        pageNumber: pagination.pageNumber,
        totalPages: pagination.totalPageCount,
        showPreviousPage,
    };
};

export { usePagination };
