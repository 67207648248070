import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Row } from "reactstrap";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import styles from "./styles.module.scss";

import { AccordionHeading } from "@/Components/AccordionHeading";
import { BasketContents } from "@/Components/BasketContents";
import BasketEmpty from "@/Components/BasketEmpty";
import ComponentBuilder from "@/Components/ComponentBuilder";
import { ConfiguredProduct } from "@/Components/ConfiguredProduct";
import Spinner from "@/Components/Spinner";
import { Image } from "@/Components/Image";
import { TermsAndConditions } from "@/Components/TermsAndConditions";
import { useBasketContext } from "@/Context/BasketContext";
import GuestAuthenticationModal from "@/Modals/GuestAuthenticationModal";
import { SupplierDisclaimerModal } from "@/Modals/SupplierDisclaimerModal";
import { groupBy } from "@/Utils/groupBy";
import sortingUtil from "@/Utils/sortingUtil";
import { GlobalFieldDescriptions, SupplierFieldDescriptions } from "@/Apis/Basket";

const BasketPage = () => {
    document.title = "BSC - Basket";
    const { basket, loading, reducedBasketFields, updateBasketField, saveBasketForm, agreeToTerms, toggleBasket } = useBasketContext();
    const [toggleFieldHeadings, setToggleFieldHeadings] = useState({});
    const history = useHistory();
    const isLoggedIn = useSelector<{user: { isLoggedIn: boolean }}>(state => state.user.isLoggedIn);
    const [error, setError] = useState("");
    const [showDataInformation, setShowDataInformation] = useState(false);
    const sendGtmEvent = useGTMDispatch();

    const checkout = (isRedirect: boolean) => () => {
        if (reducedBasketFields.length === 0 || basket.groups.flatMap(_ => _.items).every(x => x.requiredFieldsCompleted && !x.invalidFields)) {
            const push = isRedirect ? history.replace : history.push;
            if (isLoggedIn) {
                saveBasketForm();
                push("/checkout");
                sendGtmEvent(
                    {
                        event: "begin_checkout",
                        ecommerce: {
                            items: [
                                ...basket.groups.flatMap(x => x.items.map(i => ({
                                    item_id: `${i.productId}`,
                                    item_name: `${i.serviceName}`,
                                    currency: "GBP",
                                    item_brand: `${i.supplierName}`,
                                    item_category: `${i.category}`,
                                    price: i.price,
                                    quantity: i.quantity,
                                })))],
                        },
                    },
                );
            } else {
                history.push("/basket/sign-up");
            }
            return;
        }
        setError("Please fix errors on page.");
    };

    useEffect(() => {
        setToggleFieldHeadings(prevState => {
            const newState = { ...prevState };
            Object.keys(GlobalFieldDescriptions).forEach(key => {
                newState[GlobalFieldDescriptions[key]] = true;
            });
            newState[SupplierFieldDescriptions.CustomFields] = true;
            basket.groups.flatMap(_ => _.items).forEach(x => {
                newState[`${x.serviceName}Variations`] = true;
            });
            return newState;
        });
    }, [basket.groups]);

    const toggleShowDataInformation = () => {
        setShowDataInformation(prevState => !prevState);
    };

    const onToggleFieldHeading = (heading: GlobalFieldDescriptions | SupplierFieldDescriptions | string) => {
        setToggleFieldHeadings(prevState => ({ ...prevState, [heading]: !prevState[heading] }));
    };

    const onChange = (field: {
                        orderIds: string[],
                        serviceFieldIds: string[],
                        description: string,
                        orderId: string,
                        serviceFieldId: string, }) => (e, isValid = true) => {
        let value: any;
        if (!e) {
            value = e;
        } else {
            value = e.target ? e.target.value : e;

            if (e.target && e.target.type === "checkbox") {
                value = e.target.checked;
            }

            if (e.postCode) {
                value = JSON.stringify(e);
            }
        }

        if (field.description === SupplierFieldDescriptions.CustomFields) {
            updateBasketField(field.orderId, field.serviceFieldId, value, isValid);
        } else {
            field.orderIds.forEach((x, i) => updateBasketField(field.orderIds[i], field.serviceFieldIds[i], value, isValid));
        }
        setError("");
    };

    const renderGlobalFields = (globalFieldDescription: GlobalFieldDescriptions) => reducedBasketFields.filter(x => x.description === globalFieldDescription).length > 0 && (
        <AccordionHeading
            heading={globalFieldDescription}
            open={toggleFieldHeadings[globalFieldDescription]}
            onToggle={() => onToggleFieldHeading(globalFieldDescription)}
            className={classnames(styles.fieldHeader, "rounded-0 pt-3 pb-1 mb-4 text-primary")}
            iconClassName={classnames(styles.accordionIcon, "fas fa-chevron-circle-down h4 mr-1 position-relative")}
            headerClassName="font-weight-bold h6 text-uppercase"
        >
            <div className={classnames(styles.componentBuilderContainer, "mb-3 p-1")}>
                {reducedBasketFields.filter(x => x.description === globalFieldDescription)
                    .map(field => (
                        <div key={field.fieldId}>
                            <ComponentBuilder
                                fields={[field]}
                                onChange={onChange}
                                isError={!!error && field.isRequired}
                            />
                        </div>))}
            </div>
        </AccordionHeading>);

    // const renderSpecificSupplierFields = () => {
    //         .forEach((items, supplierName) => {
    //             const supplierCustomFields = (
    //             return supplierCustomFields;
    //         });
    // };

    const renderSupplierFields = () => (reducedBasketFields.filter(x => x.description === SupplierFieldDescriptions.CustomFields).length > 0
            || basket.groups.flatMap(_ => _.items).some(x => x.termsDocument))
            && (
                <AccordionHeading
                    heading="SUPPLIER SPECIFIC QUESTIONS"
                    open={toggleFieldHeadings[SupplierFieldDescriptions.CustomFields]}
                    onToggle={() => onToggleFieldHeading(SupplierFieldDescriptions.CustomFields)}
                    className={classnames(styles.fieldHeader, "rounded-0 pt-3 pb-1 mb-4 text-primary")}
                    iconClassName={classnames(styles.accordionIcon, "fas fa-chevron-circle-down h4 mr-1 position-relative")}
                    headerClassName="font-weight-bold h6 text-uppercase"
                >
                    <div className={classnames(styles.componentBuilderContainer, "p-1 pb-0")}>
                        {Array.from(groupBy(basket.groups.flatMap(_ => _.items).sort((x, y) => sortingUtil.sort(x, y, "supplierName", true)), _ => _.supplierName)
                            .entries())
                            .map(entry => {
                                const supplierName = entry[0];
                                const items = entry[1];
                                if (items.length === 0) {
                                    return <></>;
                                }
                                const renderableItems = items.map(order => ({
                                    fields: reducedBasketFields
                                        .filter(x => x.description === SupplierFieldDescriptions.CustomFields
                                                && x.supplierIds.some(s => s === order.supplierId)
                                                && order.fields.some(o => o.fieldId === x.fieldId && o.serviceFieldId === x.serviceFieldId)
                                                && order.orderId === x.orderId)
                                        .sort((a, b) => a.displayText.localeCompare(b.displayText)),
                                    order,
                                }))
                                    .filter(_ => _.fields.length > 0 || !!_.order.termsDocument);
                                // Only render the supplier fields for this supplier
                                // if we actually have something to render
                                if (renderableItems.length > 0) {
                                    return (
                                        <>
                                            <div className="mb-4 font-weight-bold h4">
                                                <Image
                                                    alt={`${supplierName} logo`}
                                                    src={items[0].logo}
                                                    className={classnames(styles.logoImage, "mr-3")}
                                                /> {supplierName}
                                            </div>
                                            {renderableItems.map(item => (
                                                <div key={item.order.orderId}>
                                                    {item.fields
                                                        .map(field => (
                                                            <div key={field.fieldId}>
                                                                <ComponentBuilder
                                                                    fields={[{
                                                                        ...field,
                                                                        orderIds: field.orderIds,
                                                                        serviceFieldIds: field.serviceFieldIds,
                                                                        orderId: item.order.orderId,
                                                                        serviceFieldId: field.serviceFieldId,
                                                                    }]}
                                                                    onChange={onChange}
                                                                    isError={!!error && field.isRequired}
                                                                />
                                                            </div>))}
                                                    {item.order.termsDocument && (
                                                        <>
                                                            <div className="font-weight-bold mb-3 mt-5 h5">{item.order.serviceName}</div>
                                                            <div className="d-flex justify-content-center">
                                                                <div className={styles.termsAndConditions}>
                                                                    <TermsAndConditions
                                                                        showPrivacy={false}
                                                                        businessName={`${item.order.supplierName} - ${item.order.serviceName}`}
                                                                        documentLocation={item.order.termsDocument}
                                                                        onToggle={() => agreeToTerms(item.order.orderId)}
                                                                        checked={item.order.termsAccepted === undefined ? false : item.order.termsAccepted}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>)}
                                                    <hr className="my-5" />
                                                </div>))}
                                        </>);
                                }
                                return <></>;
                            })}
                    </div>
                </AccordionHeading>
            );

    const renderVariations = () => (
        <>
            {basket.groups.flatMap(_ => _.items).map(basketItem => (basketItem.variations && (
                <div className="mb-4" key={basketItem.orderId}>
                    <AccordionHeading
                        heading={`${basketItem.serviceName} - Selected Variations`}
                        open={toggleFieldHeadings[`${basketItem.serviceName}Variations`]}
                        onToggle={() => onToggleFieldHeading(`${basketItem.serviceName}Variations`)}
                        className={classnames(styles.fieldHeader, "rounded-0 pt-3 pb-1 text-primary")}
                        iconClassName={classnames(styles.accordionIcon, "fas fa-chevron-circle-down h4 mr-1 position-relative")}
                        headerClassName="font-weight-bold h6 text-uppercase"
                    >
                        <ConfiguredProduct productId={basketItem.serviceId} />
                    </AccordionHeading>
                </div>)))}
        </>);

    if (loading) {
        return (
            <Row className="flex-grow-1 align-items-center justify-content-center border-top">
                <Spinner />
            </Row>
        );
    }

    if (basket.totalItems === 0 && !loading) {
        return (
            <Row className="flex-grow-1 align-items-center justify-content-center border-top">
                <BasketEmpty />
            </Row>
        );
    }

    return (
        <>
            <div className={classnames(styles.container, "d-flex m-0 m-md-3 m-lg-5")}>
                <div className={classnames(styles.fieldContainer, "rounded box-shadow mr-0 mr-lg-4 px-3 pt-2 px-lg-5 pt-lg-3 bg-white d-flex flex-column")}>
                    <div className="flex-grow-0 my-3 d-flex">
                        <Button onClick={history.goBack} className={classnames(styles.backButton, "bg-transparent text-black p-0 border-0 h4 mr-3")}>
                            <i className="text-primary fa fa-chevron-left" />
                        </Button>
                        <div>
                            <h4 className="h4 font-weight-bold">To checkout, please provide the following information:</h4>
                        </div>
                    </div>
                    {basket.groups.flatMap(_ => _.items).some(x => x.variations) && (
                        <div className="flex-grow-1">
                            {renderVariations()}
                        </div>
                    )}
                    {reducedBasketFields.length === 0 && (
                        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                            <h4>Your suppliers haven&apos;t requested any information to checkout</h4>
                        </div>
                    )}
                    {(reducedBasketFields.length > 0 || basket.groups.flatMap(_ => _.items).some(x => x.termsDocument)) && (
                        <div className="flex-grow-1">
                            {Object.keys(GlobalFieldDescriptions).map(key => (<div key={key}>{renderGlobalFields(GlobalFieldDescriptions[key])}</div>))}
                            {renderSupplierFields()}
                        </div>
                    )}
                    <div className="flex-grow-0 pb-4 text-center">
                        <div className="my-3 d-block d-lg-none">
                            <Button
                                data-testid="view-basket-button"
                                block
                                className={classnames(styles.viewItemsButton, "font-weight-bold h5 m-auto p-2 bg-transparent border-primary text-primary")}
                                onClick={() => toggleBasket(true)}
                            >
                                View Items
                            </Button>
                        </div>
                        <div className="text-danger">{error}&nbsp;</div>
                        <Button
                            color="primary"
                            data-testid="checkout-button"
                            block
                            className={classnames(styles.checkoutButton, "font-weight-bold h5 m-auto p-2")}
                            onClick={checkout(false)}
                            disabled={basket.groups.flatMap(_ => _.items).some(x => x.termsDocument && !x.termsAccepted)}
                        >
                            <i className="mr-1 fas fa-lock" />
                            Checkout
                        </Button>
                        <div className="mt-3 text-underline font-weight-bold">
                            <a href="#" onClick={() => toggleShowDataInformation()} data-testid="data-information-link">Data Information</a>
                        </div>
                    </div>
                </div>
                <div className={classnames(styles.sideCartContainer, "d-none d-lg-block")}>
                    <div className={classnames(styles.sideCartContent, "rounded box-shadow bg-white pt-3")}>
                        <BasketContents />
                    </div>
                </div>
            </div>

            {showDataInformation && (
                <SupplierDisclaimerModal
                    onClose={toggleShowDataInformation}
                    fields={reducedBasketFields}
                />
            )}
            <GuestAuthenticationModal />
        </>
    );
};

export { BasketPage };
