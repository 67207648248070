import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { exceedsMaxStringLength, isEmptyOrSpaces } from "@/Utils/stringHelper";
import { isNumericString, isValidABN, isValidAccountNumber, isValidBsb, isValidPhone } from "@/FlexPlan/Utils/validtor";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { validateEmail } from "@/Utils/validator";
import { LoqateAddress } from "@/FlexPlan/Types/Address";
import { FlexPlanSupplier, getFieldName, SupplierType } from "@/FlexPlan/Pages/Suppliers/Types";
import { SupplierSignUpTab } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Types";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

export interface FlexPlanSupplierSignUpContextState {
    formState: FlexPlanSupplier,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onAddressChange: () => void,
    onAddressSave: (address: LoqateAddress) => void,
    onBsbChange: (isFirstPart: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => void,
    onCheckboxChange: (field: keyof FlexPlanSupplier | string, errorField: string) => (checked: boolean) => void,
    onSubmit: () => void,
    errors: any,
    bsbFirstPart: string,
    bsbSecondPart: string,
    formSubmitting: boolean,
    supplierAlreadyExists?: FlexPlanSupplier,
    onAbnBlur: () => void,
    successfulSignUp: boolean,
    onSuccessfulSignUp: () => void,
    tab: SupplierSignUpTab,
    onTabChange: (tab: SupplierSignUpTab) => () => void,
    publicFieldsHasError: () => boolean,
    onRadioChange: (field: keyof FlexPlanSupplier, value: string) => () => void,
    onConfirmSupplierAlreadyExists: () => void,
    onCloseSupplierAlreadyExists: () => void,
    prepopulated: boolean,
}

export const initialState: FlexPlanSupplierSignUpContextState = {} as FlexPlanSupplierSignUpContextState;

const FlexPlanSupplierSignUpContext = React.createContext<FlexPlanSupplierSignUpContextState>(initialState);

export const useFlexPlanSupplierSignUpContext = () => useContext(FlexPlanSupplierSignUpContext);

interface Props {
    children: React.ReactNode,
}

const FlexPlanSupplierSignUpProvider = ({ children }: Props) => {
    // Context
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    // Hooks
    const {
        post,
        get,
        loading: formSubmitting,
    } = useAPI({ handle500WithToastMessage: true });
    const {
        goBack,
        push,
    } = useHistory();

    // State
    const [formState, setFormState] = useState<FlexPlanSupplier>({
        ndisRegistrationNumber: "",
        supplierType: SupplierType.Abn,
    } as FlexPlanSupplier);
    const [errors, setErrors] = useState<any>({});
    const [bsbFirstPart, setBsbFirstPart] = useState<string>("");
    const [bsbSecondPart, setBsbSecondPart] = useState<string>("");
    const [supplierAlreadyExists, setSupplierAlreadyExists] = useState<FlexPlanSupplier>();
    const [prepopulated, setPrepopulated] = useState<boolean>(false);
    const [successfulSignUp, setSuccessfulSignUp] = useState<boolean>(false);
    const [tab, setTab] = useState<SupplierSignUpTab>("public");
    const onTabChange = (tab: SupplierSignUpTab) => () => setTab(tab);

    const setErrorField = (fieldName: string) => setErrors(errors => ({
        ...errors,
        [fieldName]: true,
    }));

    const removeErrorField = (field: string) => setErrors(errors => {
        const updatedErrors = errors;
        delete updatedErrors[field];
        return updatedErrors;
    });

    const clearPublicFields = () => {
        setFormState(prev => ({
            ...prev,
            businessName: "",
            address: null,
            selfManaged: false,
            planManaged: false,
            ndia: false,
            ndisRegistrationNumber: "",
        }));
        setPrepopulated(false);
    };

    const clearPublicErrorFields = () => {
        removeErrorField(getFieldName("abn"));
        removeErrorField(getFieldName("businessName"));
        removeErrorField(getFieldName("address"));
        removeErrorField("plansAccepted");
        removeErrorField(getFieldName("ndisRegistrationNumber"));
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(formState => ({
            ...formState,
            [e.target.name]: e.target.value,
        }));
        removeErrorField(e.target.name);

        if (e.target.name === getFieldName("contactEmail")) {
            removeErrorField("email");
        } else if (e.target.name === getFieldName("abn") && prepopulated) {
            clearPublicFields();
        }
    };

    const onAddressChange = () => {
        removeErrorField(getFieldName("address"));
    };

    const onAddressSave = (address: LoqateAddress) => {
        setFormState(formState => ({
            ...formState,
            address,
        }));
    };

    const onBsbChange = (isFirstPart: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isFirstPart) {
            setBsbFirstPart(e.target.value);
            setFormState(formState => ({
                ...formState,
                bsb: e.target.value + bsbSecondPart,
            }));
        } else {
            setBsbSecondPart(e.target.value);
            setFormState(formState => ({
                ...formState,
                bsb: bsbFirstPart + e.target.value,
            }));
        }

        removeErrorField(getFieldName("bsb"));
    };

    const onCheckboxChange = (field: keyof FlexPlanSupplier | string, errorField: string) => (checked: boolean) => {
        setFormState(formState => ({
            ...formState,
            [field]: checked,
        }));

        removeErrorField(errorField);
    };

    const onAbnBlur = () => { // This is called on blur. Allows us to force a check whether the supplier already exists
        if (isValidABN(formState.abn)) {
            // Check whether the ABN already exists
            get<FlexPlanSupplier[]>(FlexPlanUrls.suppliers.search({ abn: formState.abn }))
                .then(response => {
                    if (response.length > 0) { // We have an existing flex plan supplier
                        // Set the public fields
                        const supplier = response[0];

                        if (supplier.hasPrivateFields) {
                            // Prevent the PM from continuing sign up
                            setSupplierAlreadyExists(supplier);
                            return;
                        }

                        setFormState(prev => ({
                            ...prev,
                            id: supplier.id,
                            supplierType: supplier.supplierType,
                            businessName: supplier.businessName,
                            address: supplier.address,
                            selfManaged: supplier.selfManaged,
                            planManaged: supplier.planManaged,
                            ndia: supplier.ndia,
                            ndisRegistrationNumber: supplier.ndisRegistrationNumber,
                        }));

                        clearPublicErrorFields();
                        setPrepopulated(true);
                        setSuccessMessage("ABN matches an existing supplier", true);
                    }
                });
        } else {
            setErrorField(getFieldName("abn"));
        }
    };

    const onSuccessfulSignUp = () => {
        goBack();
    };

    const onConfirmSupplierAlreadyExists = () => {
        if (!supplierAlreadyExists) return;
        push(`/suppliers/${supplierAlreadyExists.id}/public-information`);
    };

    const onCloseSupplierAlreadyExists = () => {
        setFormState(prev => ({
            ...prev,
            abn: undefined,
        }));
        setSupplierAlreadyExists(undefined); // Remove the flex plan supplier from state, close the modal
    };

    const publicFieldsHasError = (): boolean => {
        let errorFound: boolean = false;

        if (formState.supplierType === SupplierType.Abn && !isValidABN(formState.abn)) {
            errorFound = true;
            setErrorField(getFieldName("abn"));
        }

        if (isEmptyOrSpaces(formState.businessName) || exceedsMaxStringLength(formState.businessName)) {
            errorFound = true;
            setErrorField(getFieldName("businessName"));
        }

        if (isEmptyOrSpaces(formState.address)) {
            errorFound = true;
            setErrorField(getFieldName("address"));
        }

        if (!isEmptyOrSpaces(formState.ndisRegistrationNumber) && !isNumericString(formState.ndisRegistrationNumber)) {
            errorFound = true;
            setErrorField(getFieldName("ndisRegistrationNumber"));
        }

        return errorFound;
    };

    const privateFieldsHasError = (): boolean => {
        let errorFound: boolean = false;

        if (formState.contactPerson && exceedsMaxStringLength(formState.contactPerson)) {
            errorFound = true;
            setErrorField(getFieldName("contactPerson"));
        }

        if (formState.contactEmail && (!validateEmail(formState.contactEmail) || exceedsMaxStringLength(formState.contactEmail))) {
            errorFound = true;
            setErrorField(getFieldName("contactEmail"));
        }

        if (formState.contactNumber && (!isValidPhone(formState.contactNumber) || exceedsMaxStringLength(formState.contactNumber))) {
            errorFound = true;
            setErrorField(getFieldName("contactNumber"));
        }

        if (formState.bankName && exceedsMaxStringLength(formState.bankName)) {
            errorFound = true;
            setErrorField(getFieldName("bankName"));
        }

        if (formState.accountName && exceedsMaxStringLength(formState.accountName)) {
            errorFound = true;
            setErrorField(getFieldName("accountName"));
        }

        if (formState.bsb && !isValidBsb(formState.bsb)) {
            errorFound = true;
            setErrorField(getFieldName("bsb"));
        }

        if (formState.accountName && !isValidAccountNumber(formState.accountNumber)) {
            errorFound = true;
            setErrorField(getFieldName("accountNumber"));
        }

        return errorFound;
    };

    const hasErrors = (): boolean => {
        const publicFieldError = publicFieldsHasError();
        const privateFieldError = privateFieldsHasError();

        return publicFieldError || privateFieldError;
    };

    const onSubmit = () => {
        if (hasErrors()) {
            return;
        }

        post(FlexPlanUrls.suppliers.register, formState)
            .then(() => setSuccessfulSignUp(true))
            .catch(error => {
                if (error.validationFailed) {
                    setErrors(error.errors);
                } else if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    const onRadioChange = (field: keyof FlexPlanSupplier, value: string) => () => {
        // Clear the ABN and remove error fields
        setFormState(prev => ({
            ...prev,
            [field]: value,
            abn: value === SupplierType.Abn ? prev.abn : "", // Clear the ABN if we are no longer an ABN type
        }));

        if (prepopulated && (value === SupplierType.ExcludedSupply || value === SupplierType.Reimbursement)) {
            // Clear the error fields
            removeErrorField(getFieldName("abn"));

            if (prepopulated) {
                // Remove the prepopulated fields
                clearPublicFields();
            }
        }
    };

    return (
        <FlexPlanSupplierSignUpContext.Provider value={{
            formState,
            onChange,
            onAddressChange,
            onAddressSave,
            onBsbChange,
            onCheckboxChange,
            onSubmit,
            errors,
            bsbFirstPart,
            bsbSecondPart,
            formSubmitting,
            onAbnBlur,
            successfulSignUp,
            onSuccessfulSignUp,
            tab,
            onTabChange,
            publicFieldsHasError,
            onRadioChange,
            supplierAlreadyExists,
            onConfirmSupplierAlreadyExists,
            onCloseSupplierAlreadyExists,
            prepopulated,
        }}
        >
            {children}
        </FlexPlanSupplierSignUpContext.Provider>
    );
};

export { FlexPlanSupplierSignUpProvider };
