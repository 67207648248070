import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

import { LoqateAddress } from "@/FlexPlan/Types/Address";
import FormatDateHelper from "@/Utils/formatDateHelper";
import { EncodedFile } from "@/Utils/base64EncodeHelper";
import { deepEqual } from "@/Utils/objectHelper";
import { ApiValidationError } from "@/Apis/Errors";
import { useInvalidFields } from "@/Hooks/useInvalidFields";

// Hook for handling all of the different type of events
// emitted by BSC's weird and wonderful collection of input components
const useForm = <T> (initial: T = { } as T) => {
    const [formState, setFormState] = useState<T>(initial);
    const [formUpdated, setFormUpdated] = useState<boolean>(false);
    const { errors, setInvalidField, removeInvalidField } = useInvalidFields<T>();

    useEffect(() => {
        if (!deepEqual(formState, initial)) {
            setFormUpdated(true);
        }
    }, [formState]);

    // Make sure to set the name property on your input element
    // Use like so: onChange={onChange}
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(formState => ({
            ...formState,
            [e.target.name]: e.target.value,
        }));

        removeInvalidField(e.target.name);
    };

    const onChangeCustomProperty = (field: keyof T) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(formState => ({
            ...formState,
            [field]: e.target.value,
        }));

        removeInvalidField(e.target.name);
    };

    // Use like so: onChange={onCheckboxChange("firstName")}
    const onCheckboxChange = (field: keyof T, errorField: string | null = null) => (checked: boolean) => {
        setFormState(formState => ({
            ...formState,
            [field]: checked,
        }));

        // Remove plans accepted error
        removeInvalidField(errorField ?? field);
    };

    // Use like so onSave={onAddressChange}
    const onAddressChange = (address: LoqateAddress) => {
        setFormState(formState => ({
            ...formState,
            address,
        }));
    };

    // Use like so onChange={onSelectChange}
    const onReactSelectChange = (field: keyof T) => (e: { value: any }) => {
        setFormState(formState => ({
            ...formState,
            [field]: e?.value,
        }));
        removeInvalidField(field);
    };

    const onFloatDropdownSelect = (field: keyof T) => (value: string) => {
        setFormState(formState => ({ ...formState, [field]: value }));
    };

    const onDateTextboxChange = (field: keyof T) => (value) => {
        const newDate = value ? FormatDateHelper.format(value, "yyyy-MM-DDTHH:mm:ss") : "";
        setFormState(formState => ({ ...formState, [field]: newDate }));
        removeInvalidField(field);
    };

    const clearField = (field: keyof T | string, value: any = "") => {
        setFormState(formState => ({ ...formState, [field]: value }));
    };

    const onDebounceSearch = (field: keyof T) => (value: string) => {
        setFormState(formState => ({ ...formState, [field]: value }));
    };

    // Typically used to set all invalid fields from an API response
    const setInvalidFields = (errors: object) => {
        setInvalidFields(errors);
    };

    // For use with the drop zone area, when images drop
    const onFileDrop = (field: keyof T) => (files: EncodedFile[]) => {
        setFormState(formState => ({
            ...formState,
            [field]: [...(formState[field] as unknown as EncodedFile[]), ...files.map(file => ({ id: v4(), ...file }))],
        }));
    };

    const removeFile = (field: keyof T) => (file: EncodedFile) => {
        setFormState(formState => ({
            ...formState,
            [field]: (formState[field] as unknown as EncodedFile[]).filter(x => x.id !== file.id),
        }));
    };

    const onColourPickerChange = (field: keyof T) => (colour: string) => {
        setFormState(prev => ({
            ...prev,
            [field]: colour,
        }));
    };

    const setApiValidationErrors = (apiResponse: ApiValidationError) => {
        if (apiResponse.validationFailed) {
            Object.keys(apiResponse.errors).forEach(key => {
                const message = apiResponse.errors[key];
                setInvalidField(key, message);
            });
        }
    };

    return {
        formState,
        removeInvalidField,
        setInvalidField,
        setFormState,
        errors,
        invalidFields: errors,
        onChange,
        onCheckboxChange,
        onAddressChange,
        onReactSelectChange,
        onFloatDropdownSelect,
        clearField,
        onDateTextboxChange,
        onDebounceSearch,
        setInvalidFields,
        onFileDrop,
        removeFile,
        onChangeCustomProperty,
        onColourPickerChange,
        formUpdated,
        setApiValidationErrors,
    };
};

export { useForm };
