import React from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { useFlexPlanSupplierProfileContext, useFlexPlanSupplierProfileDispatchContext } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Contexts/SupplierProfileContext";
import { FlexPlanSupplier } from "@/FlexPlan/Pages/Suppliers/Types";
import { SupplierSections } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types";
import { SupplierProfileActions } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types/Reducer";

interface Props {
    onSave: (formState?: FlexPlanSupplier) => void,
    removeErrorField: (field: keyof FlexPlanSupplier) => void,
    onChangeEditableSections: (value: SupplierSections) => () => void,
    errors: any,
    openSections: SupplierSections[],
}

const ContactInformationSection = ({ onSave, removeErrorField, onChangeEditableSections, errors, openSections }: Props) => {
    const supplierProfileState = useFlexPlanSupplierProfileContext();
    const dispatch = useFlexPlanSupplierProfileDispatchContext();

    const onChange = (e: { target: { name: keyof FlexPlanSupplier, value: any } }) => {
        dispatch({
            type: SupplierProfileActions.Update,
            field: e.target.name,
            value: e.target.value,
        });
        removeErrorField(e.target.name);
    };

    return (
        <>
            <Row>
                <Col xs="12" className="d-flex justify-content-between mb-3">
                    <h4 className="font-weight-bold">Contact Information</h4>
                    <Button className="px-3" color="primary" onClick={onChangeEditableSections("Contact Information")}>Edit</Button>
                </Col>
            </Row>
            <>
                {openSections.includes("Contact Information") ? (
                    <>
                        <FloatLabelTextbox
                            label="Contact Person"
                            value={supplierProfileState.contactPerson}
                            name="contactPerson"
                            onChange={onChange}
                            error={errors.contactPerson}
                            maxLength={255}
                        />
                        <FloatLabelTextbox
                            label="Company Email Address"
                            value={supplierProfileState.contactEmail}
                            name="contactEmail"
                            onChange={onChange}
                            error={errors.contactEmail}
                            maxLength={255}
                        />
                        <FloatLabelTextbox
                            label="Contact Number"
                            value={supplierProfileState.contactNumber}
                            name="contactNumber"
                            onChange={onChange}
                            error={errors.contactNumber}
                            maxLength={255}
                        />
                        <div className="d-flex justify-content-end mt-3">
                            <ActionButtons onCancelClick={onChangeEditableSections("Contact Information")} onSaveClick={onSave} />
                        </div>
                    </>
                ) : (
                    <>
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Contact Person</Label>
                            <Label className="font-weight-bold">
                                {supplierProfileState.contactPerson}
                            </Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Contact Email Address</Label>
                            <Label className="font-weight-bold">
                                {supplierProfileState.contactEmail}
                            </Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Phone Number</Label>
                            <Label className="font-weight-bold">
                                {supplierProfileState.contactNumber}
                            </Label>
                        </FormGroup>
                    </>
                )}

            </>
        </>
    );
};

export { ContactInformationSection };
