import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { ConfigurationResponse, configurationUrl } from "@/Apis/Configuration";

const initialState: ConfigurationResponse = {
    paypalApiKey: "",
    cdnUrl: "",
    features: {},
    analytics: {},
    recaptchaKey: "",
    signalRConfiguration: {
        uri: "",
    },
    stripeKey: "",
    instrumentationKey: "",
    raygunApiKey: "",
    featuredCategories: [],
    featuredSuppliers: [],
    adminConfiguration: {},
    googleSignInConfiguration: {},
};

const ConfigurationContext = React.createContext(initialState);

const useConfigurationContext = () => useContext(ConfigurationContext);

const ConfigurationProvider = ({ children }) => {
    const { get } = useAPI({ handle500WithRedirect: true });
    const [hasLoaded, setHasLoaded] = useState(false);
    const [context, setContext] = useState<ConfigurationResponse>(initialState);

    useEffect(() => {
        (async () => {
            try {
                const result = await get<ConfigurationResponse>(configurationUrl);
                window.bscFeatures = result.features;
                window.cdnUrl = result.cdnUrl;
                setContext(result);
            } finally {
                setHasLoaded(true);
            }
        })();
    }, []);

    if (!hasLoaded) {
        return (
            <div className="h-100 d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                <p>Loading Configuration</p>
                <Spinner className="align-self-center" />
            </div>
        );
    }

    return (
        <ConfigurationContext.Provider value={context}>
            {children}
        </ConfigurationContext.Provider>
    );
};

ConfigurationProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export {
    useConfigurationContext,
    ConfigurationProvider,
};
