import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import { OrderItemPrice } from "@/Components/OrderItemPrice";
import { ServiceTilePriceSection } from "@/Components/ServiceTilePriceSection";
import { ProductVariants } from "@/Utils/ProductVariants";
import { ConfigureBasketModal } from "@/Modals/ConfigureBasketModal";

const PackageServices = ({ services, onListedProductConfigured }) => {
    const [configuringService, setConfiguringService] = useState(null);

    const onAddService = (qty, variationData) => {
        onListedProductConfigured(configuringService.id, qty, variationData);
    };

    return (
        <>
            {services.map((x, index) => (

                <div
                    className={classnames("d-flex p-4 m-0 m-lg-2", index > 0 && "border-top")}
                    key={`${x.name}-${x.category}`}
                >
                    <div className="flex-grow-1">
                        <h5 className="font-weight-bold">{x.name}</h5>
                        <p className="mb-2">{x.categoryName} / {x.subCategoryName}</p>
                        <p className="mb-2"><Link to={`/marketplace/supplier-directory/${x.supplierId}`} target="_blank">{x.supplierName}</Link></p>
                        <p className="mb-0"><Link to={`/product/${x.slugId}/${x.slug}`} target="_blank">View Details</Link></p>
                        {x.productVariant === ProductVariants.listedProduct && (
                            <Button data-testid="configure-button" className="btn-shopping-cart mt-2" onClick={() => setConfiguringService(x)}>
                                <i className="fas fa-pencil-ruler" /> Configure your order
                            </Button>
                        )}
                        {x.productVariant === ProductVariants.customisableProduct && (
                            <Button data-testid="configure-button" className="btn-shopping-cart mt-2" onClick={() => setConfiguringService(x)}>
                                <i className="fas fa-pencil-ruler" /> Configure your order
                            </Button>
                        )}
                    </div>
                    <div>
                        <OrderItemPrice
                            id={x.id}
                            totalVat={x.isVatRequired ? x.initialChargeWithVatIfApplicable - x.initialCharge : 0}
                            price={x.initialCharge}
                            postage={x.postageCharge}
                            totalPrice={(x.initialChargeWithVatIfApplicable * x.quantity) + x.postageCharge}
                            quantity={x.quantity}
                            showPriceAsMinimum={x.completed === false && x.productVariant === ProductVariants.listedProduct}
                            paymentFrequency={x.paymentFrequency}
                            displayOnTwoLines
                            isOffsitePayment={x.isOffsitePayment}
                        />
                    </div>
                </div>
            ))}

            {configuringService && <ConfigureBasketModal
                serviceVariations={configuringService.configuredVariations}
                id={configuringService.id}
                variations={configuringService.variations.variationData}
                name={configuringService.name}
                onAddToBasket={onAddService}
                priceElement={<ServiceTilePriceSection
                    addQuantity={false}
                    disableAddService
                    postageCharge={configuringService.postageCharge}
                    paymentFrequency={configuringService.paymentFrequency}
                    initialChargeWithVatIfApplicable={configuringService.initialChargeWithVatIfApplicable}
                    containerClassNames="w-100 bg-white d-flex h-20px"
                    priceFontClassNames="font-weight-bold h6"
                />}
                toggle={() => setConfiguringService(null)}
                initialChargeWithVatIfApplicable={configuringService.initialChargeWithVatIfApplicable}
                postageCharge={configuringService.postageCharge}
                productVariant={configuringService.productVariant}
            />}
        </>);
};

PackageServices.propTypes = {
    services: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        supplierId: PropTypes.string.isRequired,
        supplierName: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired,
        subCategoryName: PropTypes.string.isRequired,
        initialCharge: PropTypes.number,
        isVatRequired: PropTypes.bool,
        postageCharge: PropTypes.number.isRequired,
        productVariant: PropTypes.string.isRequired,
        variations: PropTypes.shape({}),
        quantity: PropTypes.number.isRequired,
        initialChargeWithVatIfApplicable: PropTypes.number.isRequired,
        completed: PropTypes.bool.isRequired,
    })).isRequired,
    onListedProductConfigured: PropTypes.func,
};

export default PackageServices;
