import { EncodedFile } from "@/Utils/base64EncodeHelper";

export interface DocumentType {
    id: string,
    isRmc: boolean,
    rmcId: string,
    userId: string,
    userEmail: string,
    companyName: string,
    orderNumber: string,
}

export interface DocumentUser {
    userId: string,
    userEmail: string,
    companyName: string,
}

export interface Document extends EncodedFile {
    dateCreated: string,
    // Search fields
    customerEmail: string,
    companyName: string,
    orderNumber: string,
    status: DocumentStatus,
}

export interface CompanyDocumentType {
    isRmc: boolean,
    companyName: string,
    orderNumber: string,
}

export enum DocumentStatus {
    New,
    Edit,
    Delete,
}
