import React from "react";
import classnames from "classnames";
import { Placement } from "popper.js";

import styles from "./styles.module.scss";

import FreeTrialLabel from "@/Components/FreeTrialLabel";
import { Popper } from "@/Components/Popper";
import { getFrequencyBasic, getFrequencyAlternate, getDelayedPaymentPeriodValue, toCurrency } from "@/Utils/formatStringHelper";
import { ProductPriceSummary } from "@/Apis/Products/ProductType";

interface Props {
    productId: string;
    initialChargeWithVatIfApplicable: number;
    initialCharge: number;
    paymentFrequency: string;
    postageCharge: number;
    minimumQuantity: number;
    isVatRequired: boolean;
    className?: string;
    placement?: Placement;
    componentName: string; // needed to prevent duplicate targetIds
    displayOnTwoLines?: boolean;
    subscriptionSummary?: Array<ProductPriceSummary>;
    packageDisplay?: boolean; // whether this is a package price summary
    isOffsitePayment?: boolean;
    hasNAProducts?: boolean;
    tileDisplay?: boolean;
    isDelayedPayment?: boolean;
    delayedPaymentFor?: number;
    delayedPaymentPeriod?: number;
    discountRate?: number;
}
// eslint-disable-next-line max-len
const PricingPopover = ({ initialChargeWithVatIfApplicable = 0, paymentFrequency, postageCharge, minimumQuantity, initialCharge, isVatRequired, productId, className,
    placement = "bottom", componentName, displayOnTwoLines = false, subscriptionSummary, packageDisplay = false, tileDisplay = false, isOffsitePayment = false,
    hasNAProducts = false, isDelayedPayment = false, delayedPaymentPeriod = 0, delayedPaymentFor = 0, discountRate = 0 }: Props) => {
    const minimumQuantityCalc = minimumQuantity <= 0 ? 1 : minimumQuantity;
    const vat = isVatRequired ? (initialChargeWithVatIfApplicable - initialCharge) * minimumQuantityCalc : 0;
    const total = (((initialCharge * minimumQuantityCalc) + vat) * ((100 - discountRate) / 100)) + postageCharge;

    return (
        <div className={className}>
            <Popper
                placement={placement}
                targetId={`pricingPopover-${productId}-${componentName}`}
                popoverClassName={classnames(styles.popper)}
                popoverBodyClassName={classnames(styles.popperBody, "shadow-lg p-3")}
                icon={displayOnTwoLines ? (
                    <>
                        <h5
                            className="font-weight-bold mb-1 pointer text-primary whitespace-nowrap"
                            id={`pricingPopover-${productId}-${componentName}`}
                            data-testid={`pricingPopover${productId}`}
                        >
                            {paymentFrequency === "N/A"
                                ? "N/A"
                                : (<>£{toCurrency(initialCharge)}</>)}
                            <i className={classnames(packageDisplay && hasNAProducts ? "text-danger" : "", "ml-2 fas fa-info-circle")} />
                        </h5>
                        <span className="h6 text-primary mb-0">{getFrequencyBasic(paymentFrequency)}</span>
                        {isOffsitePayment && !tileDisplay && <p className="h6 text-muted font-weight-bold mb-0 mt-2">DUE TO SUPPLIER</p>}
                    </>
                ) : (
                    <div className="tw-space-x-2">
                        {isDelayedPayment && <FreeTrialLabel />}
                        <h5
                            className={classnames(isDelayedPayment ? "!tw-text-3xs !tw-inline-block !tw-italic" : "font-weight-bold",
                                "mb-1 pointer text-primary tw-whitespace-nowrap")}
                            id={`pricingPopover-${productId}-${componentName}`}
                            data-testid={`pricingPopover${productId}`}
                        >
                            {/* Don't display this in the basket for Delayed Payment products */}
                            {(componentName === "basket" && isDelayedPayment)
                                || (
                                    <>
                                        {paymentFrequency === "N/A"
                                            ? "N/A"
                                            : (<>£{toCurrency(initialCharge)}</>)}
                                        <span className={classnames(!isDelayedPayment ? "h6" : "!tw-text-3xs")}>
                                            {/* eslint-disable-next-line max-len */}
                                            {getFrequencyAlternate(paymentFrequency)} {isDelayedPayment && (<>after {delayedPaymentFor} {getDelayedPaymentPeriodValue(delayedPaymentPeriod, delayedPaymentFor)}</>)}
                                        </span>
                                    </>
                                )}
                            <i className={classnames(packageDisplay && hasNAProducts ? "text-danger" : "", "ml-2 fas fa-info-circle")} />
                            {isOffsitePayment && !tileDisplay && <p className="h6 text-muted font-weight-bold mb-0 mt-2">DUE TO SUPPLIER</p>}
                        </h5>
                    </div>
                )}
            >
                {packageDisplay && hasNAProducts
                    && (<>This package contains N/A priced items. See supplier Terms and Conditions before you check out for a full price breakdown.</>)}

                {!packageDisplay && hasNAProducts
                    && (<>Select &apos;View Details&apos; to see a price breakdown from this supplier. Payment will be defined within order contract schedule.</>)}

                {!hasNAProducts
                    && (
                        <div className="w-100">
                            <div className="d-flex justify-content-between">
                                <p>{!packageDisplay ? "Product/Service" : "Package"}</p>
                                <p>
                                    {(isDelayedPayment && "FREE TRIAL")
                                        || (paymentFrequency === "N/A"
                                            ? "N/A"
                                            : (<>£{toCurrency(initialCharge)}</>))}
                                </p>
                            </div>
                            {minimumQuantity > 0 && (
                                <div className="d-flex justify-content-between">
                                    <p>Quantity</p>
                                    <p>{minimumQuantity}</p>
                                </div>)}
                            {(postageCharge > 0 || isVatRequired) && (
                                <div>
                                    <p className="text-muted text-uppercase font-size-sm font-weight-bold">Additional Fees</p>
                                </div>)}
                            {postageCharge > 0 && (
                                <div className="d-flex justify-content-between">
                                    <p>Postage Price</p>
                                    <p>£{toCurrency(postageCharge)}</p>
                                </div>)}
                            {isVatRequired && (
                                <div className="d-flex justify-content-between">
                                    <p>VAT</p>
                                    <p>£{toCurrency(vat)}</p>
                                </div>)}
                            {!!discountRate && (
                                <div className="d-flex justify-content-between">
                                    <p>DISCOUNTS</p>
                                    <p>{toCurrency(discountRate)}%</p>
                                </div>)}
                            <hr className="p-0 m-0 pb-3" />
                            <div className="d-flex justify-content-between text-primary font-weight-bold">
                                <p className="text-uppercase">Total</p>
                                <p>
                                    {(isDelayedPayment && "£0.00")
                                        || (paymentFrequency === "N/A"
                                            ? "N/A"
                                            : (<>£{toCurrency(total)}</>))}
                                </p>
                            </div>
                            {isOffsitePayment && (
                                <div className="text-primary text-muted small font-weight-bold">
                                    PAYMENT DUE TO SUPPLIER
                                </div>
                            )}
                            {!isOffsitePayment && (
                                <div className={classnames(
                                    styles.frequency,
                                    !isDelayedPayment ? "text-uppercase text-muted font-weight-bold" : "!tw-text-brand-primary !tw-italic",
                                    "text-right font-size-sm",
                                )}
                                >
                                    {(!isDelayedPayment && paymentFrequency)
                                        || (
                                            <>
                                                {(paymentFrequency === "N/A"
                                                    ? "N/A"
                                                    : (<>£{toCurrency(total)}</>))}
                                                {/* eslint-disable-next-line max-len */}
                                                {getFrequencyAlternate(paymentFrequency)} {isDelayedPayment && (<>after {delayedPaymentFor} {getDelayedPaymentPeriodValue(delayedPaymentPeriod, delayedPaymentFor)}</>)}
                                            </>)}
                                </div>)}
                            {packageDisplay && (
                                <>
                                    {subscriptionSummary && subscriptionSummary.length > 0 && (
                                        <div
                                            className="d-flex
                                            justify-content-between
                                            text-primary
                                            font-size-sm
                                            font-weight-bold
                                            text-uppercase
                                            mt-2"
                                        >
                                            On-going subscriptions
                                        </div>
                                    )}
                                    {subscriptionSummary && subscriptionSummary.map((x) => (
                                        <div key={x.paymentFrequency}>
                                            <p className="text-primary text-right font-weight-bold">£{toCurrency(x.initialCharge)}</p>
                                            <div className={classnames(styles.frequency, "text-right text-muted text-uppercase font-size-sm font-weight-bold")}>
                                                {x.paymentFrequency}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>)}
            </Popper>
        </div>
    );
};

export { PricingPopover };
