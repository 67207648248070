/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { ParticipantSignupFormState } from "../Types/ParticicpantSignupFormState";

import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { EncodedFile } from "@/Utils/base64EncodeHelper";
import { exceedsMaxStringLength, isEmptyOrSpaces, isValidDate } from "@/Utils/stringHelper";
import { validateEmail } from "@/Utils/validator";
import { isValidNdisNumber, isValidPhone } from "@/FlexPlan/Utils/validtor";
import { DropZoneFile } from "@/Components/Dropzones/DropZoneMulti";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { PlanType } from "@/FlexPlan/Types/Plan";
import { ParticipantContactRelationship, Participant } from "@/FlexPlan/Types/Participant";
import { enumDisplay } from "@/Utils/formatStringHelper";
import { LoqateAddress } from "@/FlexPlan/Types/Address";

const useParticipantSignUpForm = () => {
    const history = useHistory();
    const { get, post, loading: processingSignUp } = useAPI({ handle500WithToastMessage: true });
    const [invalidFields, setInvalidFields] = useState<any>();
    const [apiError, setApiError] = useState<string>();
    const [participantAlreadyExists, setParticipantAlreadyExists] = useState<Participant>(); // Based on NDIs search

    const [formState, setFormState] = useState<ParticipantSignupFormState>({
        planType: PlanType.planManaged,
        address: { premise: "", addressLine1: "", addressLine2: "", city: "", postCode: "" } as LoqateAddress,
        supportingDocuments: [] as EncodedFile[],
        ndisVisible: true,
        fundedVisible: true,
        approveInvoices: false,
        receiveInvoice: false,
    } as ParticipantSignupFormState);

    const relationshipOptions = Object.keys(ParticipantContactRelationship).map(x => ({
        label: enumDisplay(ParticipantContactRelationship[x]),
        value: ParticipantContactRelationship[x],
    }));

    const removeErrorField = (field: string) => setInvalidFields(invalidFields => {
        if (!invalidFields) return invalidFields;
        const updatedInvalidFields = invalidFields;
        delete updatedInvalidFields[field];
        return updatedInvalidFields;
    });

    const onChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(formState => ({
            ...formState,
            [field]: e.target.value,
        }));
        removeErrorField(field);
    };

    const setInvalidField = (fieldName: string) => setInvalidFields(invalidFields => ({
        ...invalidFields,
        [fieldName]: true,
    }));

    useEffect(() => {
        if (isValidNdisNumber(formState.ndisNumber)) {
            get<Participant[]>(FlexPlanUrls.participants.search({ ndisNumber: formState.ndisNumber }))
                .then(response => {
                    if (response && response.length > 0) { // We've found a match
                        const firstMatch = response[0];
                        setParticipantAlreadyExists({ ...firstMatch, fullname: `${firstMatch.firstname} ${firstMatch.surname}` });
                        setInvalidField("ndisNumberAlreadyExists");
                    }
                });
        }
        if (invalidFields?.ndisNumberAlreadyExists) removeErrorField("ndisNumberAlreadyExists");
    }, [formState.ndisNumber]);

    const onAddDocument = async (files: EncodedFile[]) => {
        setFormState(formState => ({
            ...formState,
            supportingDocuments: formState.supportingDocuments.concat(files),
        }));
    };

    const onDeleteDocument = async (file: DropZoneFile) => {
        setFormState(formState => ({
            ...formState,
            supportingDocuments: formState.supportingDocuments.filter(x => x.name !== file.fileName),
        }));
    };

    const editDob = (date) => {
        setFormState(prevState => ({ ...prevState, dateOfBirth: date }));
        removeErrorField("dateOfBirth");
    };

    const editPlanStartDate = (date) => {
        setFormState(prevState => ({ ...prevState, planStartDate: date }));
        removeErrorField("planStartDate");
    };

    const editPlanEndDate = (date) => {
        setFormState(prevState => ({ ...prevState, planEndDate: date }));
        removeErrorField("planEndDate");
    };

    const onRadioChange = (field: string, value: any) => () => {
        setFormState(prevState => ({ ...prevState, [field]: value }));
    };

    const onAddressSaved = (address: LoqateAddress) => {
        setFormState(prevState => ({ ...prevState, address }));
        removeErrorField("address");
    };

    const onNdisBlur = () => {
        if (!isValidNdisNumber(formState.ndisNumber)) {
            setInvalidField("ndisNumber");
        }
    };

    const hasErrors = (): boolean => {
        // Set any errors
        let errorFound: boolean = false;

        if (!isValidNdisNumber(formState.ndisNumber)) {
            setInvalidField("ndisNumber");
            errorFound = true;
        }

        if (isEmptyOrSpaces(formState.firstName) || exceedsMaxStringLength(formState.firstName)) {
            setInvalidField("firstName");
            errorFound = true;
        }

        if (isEmptyOrSpaces(formState.lastName) || exceedsMaxStringLength(formState.lastName)) {
            setInvalidField("lastName");
            errorFound = true;
        }

        if (isEmptyOrSpaces(formState.dateOfBirth) || !isValidDate(formState.dateOfBirth)) {
            setInvalidField("dateOfBirth");
            errorFound = true;
        }

        if (isEmptyOrSpaces(formState.address?.addressLine1) || isEmptyOrSpaces(formState.address.city) || isEmptyOrSpaces(formState.address.postCode)) {
            setInvalidField("address");
            errorFound = true;
        }

        if (!isEmptyOrSpaces(formState.alternateName) && exceedsMaxStringLength(formState.alternateName)) {
            setInvalidField("alternateName");
            errorFound = true;
        }

        if (formState.relationshipToParticipant === ParticipantContactRelationship.other
                && (isEmptyOrSpaces(formState.otherRelationship) || exceedsMaxStringLength(formState.otherRelationship))) {
            setInvalidField("otherRelationship");
            errorFound = true;
        }

        if (!isValidPhone(formState.phoneNumber)) {
            setInvalidField("phoneNumber");
            errorFound = true;
        }

        if (!validateEmail(formState.email)) {
            setInvalidField("email");
            errorFound = true;
        }

        return errorFound;
    };

    const onConfirmParticipantAlreadyExists = () => {
        if (!participantAlreadyExists) return;

        history.push(`/participants/${participantAlreadyExists.id}/personal-information`);
    };

    const onCloseParticipantAlreadyExists = () => {
        setParticipantAlreadyExists(undefined);
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (hasErrors()) {
            return;
        }

        // fix daylight saving bug
        const fixedPayload: ParticipantSignupFormState = { ...formState, dateOfBirth: moment(formState.dateOfBirth).format("YYYY-MM-DD") };

        post<{ participantId: string }>(FlexPlanUrls.participants.register, fixedPayload)
            .then(response => {
                history.push(`/participants/${response.participantId}/personal-information`); // Push to participant profile
            })
            .catch(error => {
                if (typeof error === "string") {
                    setApiError(error);
                } else if (error.validationFailed) {
                    setInvalidFields(error.errors);
                }
            });
    };

    const onAddressChange = () => removeErrorField("address");

    return {
        processingSignUp,
        onChange,
        onFormSubmit,
        onDeleteDocument,
        onAddDocument,
        editDob,
        editPlanStartDate,
        editPlanEndDate,
        // onAddressChange,
        invalidFields,
        formState,
        setFormState,
        apiError,
        onRadioChange,
        relationshipOptions,
        onAddressSaved,
        onNdisBlur,
        participantAlreadyExists,
        onConfirmParticipantAlreadyExists,
        onCloseParticipantAlreadyExists,
        onAddressChange,
    };
};

export default useParticipantSignUpForm;
