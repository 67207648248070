import AwesomeDebouncePromise from "awesome-debounce-promise";

import api from "@/Utils/api";
import { Urls } from "@/Apis/urls";

// The debouncing function returned by the lib is stateful.
// If you want deboucing to work fine, make sure to avoid recreating this function everytime.
const onPackageRetrieve = (code, productIds) => {
    if (code) {
        const productIdsParam = productIds?.trim() ? encodeURI(productIds) : "";
        return api.get(Urls.packages.redeemPackages(code, productIdsParam));
    }
    return null;
};

export const onPackageRetrieveDebounce = AwesomeDebouncePromise(onPackageRetrieve, 400);
