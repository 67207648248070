import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import { BasketContents } from "@/Components/BasketContents";
import { CheckoutLeavingGuard } from "@/Components/CheckoutLeavingGuard";
import BasketEmpty from "@/Components/BasketEmpty";
import { useBasketContext } from "@/Context/BasketContext";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { Payment } from "@/Pages/CheckoutPage/Payment";
import { ConfirmDeleteBasketItemModal } from "@/Modals/ConfirmDeleteBasketItemModal";

const CheckoutPage = () => {
    document.title = "BSC - Checkout";
    const location = useLocation();
    const [itemToDelete, setItemToDelete] = useState<any>();
    const [displayConfirmDeleteModal, setDisplayConfirmDeleteModal] = useState(false);
    const { basket, removeItemFromBasket, clearBasket, saveBasketForm } = useBasketContext();
    const { stripeKey } = useConfigurationContext();
    const [stripePromise] = useState(() => loadStripe(stripeKey)); // Removes a console error
    const history = useHistory();
    const [shouldPreventNavigation, setShouldPreventNavigation] = useState(false);
    const isLoggedIn = useSelector<any>(state => state.user.isLoggedIn);

    const onPaymentSuccess = () => {
        setShouldPreventNavigation(false);
        clearBasket();
    };

    useEffect(() => {
        if (isLoggedIn) {
            saveBasketForm();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (location?.state) { // can be set from PartnerBridge
            setShouldPreventNavigation((location.state as any).cfUser);
        }
    }, []);

    useEffect(() => {
        if (!basket.groups.flatMap(_ => _.items).every(x => x.requiredFieldsCompleted && !x.invalidFields)) {
            history.replace("/basket");
        }
    }, [basket.groups]);

    const removeItem = () => {
        removeItemFromBasket(itemToDelete.orderId);
        setItemToDelete(null);
        setDisplayConfirmDeleteModal(false);
    };

    if (basket.totalItems === 0) {
        return (
            <>
                <BasketEmpty />
            </>
        );
    }

    const confirmedNavigate = (path) => {
        setShouldPreventNavigation(false);
        clearBasket();
        history.push(path);
    };

    return (
        <>
            <CheckoutLeavingGuard
                shouldPreventNavigation={shouldPreventNavigation}
                navigate={confirmedNavigate}
            />
            <div className={classnames(styles.flexColumnTop, "d-flex m-0 mt-lg-5 mx-lg-5")}>
                <div className={classnames(styles.sideCartContainer)}>
                    <div className={classnames(styles.sideCartContent, "rounded box-shadow bg-white")}>
                        <BasketContents hideMobileBacktButton hideCartButton />
                    </div>
                </div>
                <div className={classnames(styles.fieldContainer, "rounded box-shadow mr-0 mr-lg-4 px-3 pt-2 px-md-5 pt-md-4 bg-white d-flex flex-column")}>
                    <div>
                        <h4 className="h4 font-weight-bold">Payment Details</h4>
                        {stripePromise && (
                            <div className="m-2 m-md-3 mx-md-auto p-0 p-md-4">
                                <Elements stripe={stripePromise}>
                                    <Payment
                                        onPaymentSuccess={onPaymentSuccess}
                                        isAmendPayment={false}
                                        amendPaymentOrderId={undefined}
                                    />
                                </Elements>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {displayConfirmDeleteModal && (
                <ConfirmDeleteBasketItemModal
                    itemName={itemToDelete?.name}
                    onConfirm={removeItem}
                    onClose={() => setDisplayConfirmDeleteModal(false)}
                />
            )}
            <Footer />
        </>
    );
};

export { CheckoutPage };
