import React from "react";
import { Button, Table } from "reactstrap";

import Modal from "@/Modals/Modal";
import { toCurrencyWithCommas } from "@/FlexPlan/Utils/currency";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { downloadUseAPIFile } from "@/Utils/dowloadHelper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { FlexPlanOrder } from "@/FlexPlan/Pages/Orders/OrderManagement/Types";

interface Props {
    isOpen: boolean,
    rows: FlexPlanOrder[],
    toggle: () => void,
    refreshOrders: () => void,
}

const CreatePayFileModal = ({ isOpen, rows, toggle, refreshOrders }: Props) => {
    const { postFile, loading: downloading } = useAPI({ handle500WithToastMessage: true });
    const { setPopupErrorMessage, setSuccessMessage } = useToastMessageContext();
    const onSubmit = () => {
        postFile(FlexPlanUrls.orders.payFile, {
            orderIds: rows.map(x => x.id),
        })
            .then(response => {
                downloadUseAPIFile("text/plain", response);
                setSuccessMessage("Pay File generated", true);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                }
            })
            .finally(() => {
                toggle(); // Close the modal
                refreshOrders();
            });
    };

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            title="Calculation for Approved"
            toggle={toggle}
            closeButton
        >
            <>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th style={{ width: "16.6" }}>ORDER NO.</th>
                            <th style={{ width: "25" }}>PARTICIPANT</th>
                            <th style={{ width: "16.6" }}>SUPPLIER</th>
                            <th style={{ width: "16.6" }}>SUPPLIER INVOICE NO.</th>
                            <th style={{ width: "16.6" }}>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length === 0 && (
                            <tr>
                                <td colSpan={5}>No orders have been selected.</td>
                            </tr>
                        )}
                        {rows.length > 0 && rows.map(x => (
                            <tr key={x.id}>
                                <td>{x.orderNumber}</td>
                                <td>{x.participantName}</td>
                                <td>{x.supplierBusinessName}</td>
                                <td>{x.invoiceNumber}</td>
                                <td>{toCurrencyWithCommas(x.approvedTotal)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="tw-flex tw-justify-center tw-mt-8">
                    <Button
                        color="primary"
                        onClick={onSubmit}
                        disabled={downloading || rows.length === 0}
                    >
                        Export Payment File
                    </Button>
                </div>
            </>
        </Modal>
    );
};

export { CreatePayFileModal };
