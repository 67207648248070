import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import FloatLabelTextbox from "../FloatLabelTextbox";
import FloatLabelDate from "../FloatLabelDate";
import { FloatLabelAddressLookUp } from "../FloatLabelAddressLookup";
import { FloatLabelFileUpload } from "../FloatLabelFileUpload";
import FloatLabelPhone from "../FloatLabelPhone";

import { YesNoButtons } from "@/Components/YesNoButtons";
import TextArea from "@/Components/TextArea";

class ComponentBuilder extends PureComponent {
    componentDict = {
        PHONE: FloatLabelPhone,
        TEXT: FloatLabelTextbox,
        TEXTAREA: TextArea,
        DOB: FloatLabelDate,
        ADDRESSLOOKUP: FloatLabelAddressLookUp,
        DATE: FloatLabelDate,
        YESNO: YesNoButtons,
        UPLOADATTACHMENT: FloatLabelFileUpload,
    };

    renderField = (field) => {
        let address = { addressLine1: "", addressLine2: "", city: "", postCode: "" };
        if (field.type.toUpperCase() === "ADDRESSLOOKUP" && field.value) {
            address = JSON.parse(field.value);
        }

        let uploadProps = {};
        const onChange = this.props.onChange({ orderIds: field.orderIds,
            serviceFieldIds: field.serviceFieldIds,
            description: field.description,
            orderId: field.orderId,
            fieldId: field.fieldId,
            serviceFieldId: field.serviceFieldId });

        if (field.type.toUpperCase() === "UPLOADATTACHMENT") {
            uploadProps = {
                heading: field.isRequired ? field.displayText : `${field.displayText} [optional]`,
                onFileUploaded: onChange,
                autoSaveRoute: "basket/uploads",
                documentName: field.value,
                isIdentityDocument: field.isIdentityDocument,
            };
        }

        const commonProps = {
            key: field.displayText,
            onChange,
            changeDate: onChange,
            label: field.isRequired ? field.displayText : `${field.displayText} [optional]`,
            value: field.value,
            currentDate: field.value,
            heading: field.displayText,
            address,
            onSave: onChange,
            disabled: this.props.disabled,
            ...uploadProps,
            "data-testid": `field-${field.displayText}`,
            displaySingleLine: true,
            fieldId: field.fieldId,
            isRequired: field.isRequired,
            className: field.className,
        };
        // if the field is required and there is an error and the field doesnt have a value, show the error
        if (field.isRequired && this.props.isError && (!field.value || field.value === "null")) {
            commonProps.error = "This field is required";
        }
        const Component = this.componentDict[field.type.toUpperCase()];

        return Component ? <Component {...commonProps} /> : false;
    };

    render() {
        return (
            <div>
                {this.props.fields && this.props.fields.map(x => this.renderField(x))}
            </div>
        );
    }
}

ComponentBuilder.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        serviceFieldId: PropTypes.string,
        displayText: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        // eslint-disable-next-line
        value: PropTypes.any,
        className: PropTypes.string,
        isIdentityDocument: PropTypes.bool,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    orderIds: PropTypes.arrayOf(PropTypes.string),
    isError: PropTypes.bool,
    disabled: PropTypes.bool,
    orderId: PropTypes.string,
    serviceFieldId: PropTypes.string,
};

export default ComponentBuilder;
