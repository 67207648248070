import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";

export interface FeaturesProps {
    customisedProducts?: boolean;
    reCaptcha?: boolean;
    signalR?: boolean;
    googleSignIn?: boolean;
}

export interface AnalyticsProps {
    gaKey?: string;
    hotjarId?: number;
    hotjarSnippetVersion?: number;
}

export interface SignalRConfigurationProps {
    uri: string;
}

export interface FeaturedCategory {
    id: string;
    name: string;
}

export interface ConfigurationResponse {
    features: FeaturesProps;
    cdnUrl: string;
    paypalApiKey: string;
    analytics: AnalyticsProps;
    recaptchaKey: string;
    stripeKey: string;
    instrumentationKey: string;
    raygunApiKey: string;
    signalRConfiguration: SignalRConfigurationProps;
    featuredCategories: FeaturedCategory[];
    featuredSuppliers: MiniSiteType[];
    adminConfiguration: AdminConfiguration;
    googleSignInConfiguration: GoogleSignInConfiguration;
}

export interface GoogleSignInConfiguration {
    clientId?: string,
    providerName?: string;
}

export interface AdminConfiguration {
    messagingRecipientId?: string | null;
}

export const configurationUrl = "configuration";
