import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { useLocation, useParams } from "react-router";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import { General } from "./General";
import { Pricing } from "./Pricing";
import { Configure } from "./Configure";
import { MoreInfo } from "./MoreInfo";
import { Forms } from "./Forms";
import { getNavigationItems } from "./NavigationType";
import styles from "./styles.module.scss";
import { PostPurchase } from "./PostPurchase";

import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { createEditorState } from "@/Components/RichTextEditor/Utils";
import { AddProductBreadcrumbs } from "@/Components/AddProductBreadcrumbs";
import { ServiceTilePriceSection } from "@/Components/ServiceTilePriceSection";
import { features } from "@/Utils/features";
import { ProductVariations } from "@/Utils/ProductVariations";
import { ProductVariants } from "@/Utils/ProductVariants";
import { serviceValidator } from "@/Utils/validator";
import { initialChargeWithVatIfApplicable } from "@/Utils/vatHelper";
import sortingUtil from "@/Utils/sortingUtil";
import { ConfigureBasketModal } from "@/Modals/ConfigureBasketModal";
import { Upsell } from "@/Modals/ServiceModal/Upsell";
import { LessDetailedProductModal } from "@/Modals/LessDetailedProductModal";

const ServiceModal = ({ metaData, selectedService, addService, deleteService }) => {
    // Hooks
    const location = useLocation();
    const dispatch = useDispatch();
    const routeParams = useParams();
    const allowFlexiblePayments = useSelector(state => state.user.allowFlexiblePayments);
    const onPush = path => () => dispatch(push(path));

    // State
    const [filteredFrequencies, setFilteredFrequencies] = useState(metaData.paymentFrequencies.filter(x => (allowFlexiblePayments || x !== "N/A")));
    const [service, setService] = useState({
        minimumQuantity: 0,
        paymentFrequency: filteredFrequencies[0],
        ...selectedService,
        initialCharge: parseFloat(selectedService.initialCharge).toFixed(2),
        postageCharge: parseFloat(selectedService.postageCharge).toFixed(2),
    });
    const [savingTerms, setSavingTerms] = useState(false);
    const [serviceValidation, setServiceValidation] = useState(serviceValidator(service, allowFlexiblePayments));
    const [showLessDetailsModal, toggleLessDetailsModal] = useState(false);
    const [showConfigurePreview, setShowConfigurePreview] = useState(false);
    const [confirmFormationCompanyCategoryModalOpen, setConfirmFormationCompanyCategoryModalOpen] = useState(false);
    const [confirmFormationCompanyCategoryText, setConfirmFormationCompanyCategoryText] = useState("");

    // Variables
    const navigation = getNavigationItems(service.productVariant);
    const navigationItem = navigation.find(x => location.pathname.indexOf(x.current) >= 0);
    const isEditingService = routeParams.serviceId !== "new";
    const formationCompanyCategoryId = metaData.categories.find(x => x.name === "Company Formations")?.id;
    const readyMadeCompanyCategoryId = metaData.categories.find(x => x.name === "Ready-made Company")?.id;
    const [confirmedCategoryId, setConfirmedCategoryId] = useState("");

    const toggleCompanyFormationsModal = () => setConfirmFormationCompanyCategoryModalOpen(prevState => !prevState);

    // Map the prop sub categories and any state values into the object structure required by react-select
    const subCategories = [...(metaData.subCategories[service.categoryId] || [])
        .sort((x, y) => sortingUtil.sort(x, y, "name", true))
        .map(x => ({ value: x.id, label: x.name }))];

    const onToggleLessDetailsModal = () => {
        toggleLessDetailsModal(!showLessDetailsModal);
    };

    const delayedPaymentPeriodList = [
        { name: "Weeks", value: 0 },
        { name: "Months", value: 1 },
        { name: "Years", value: 2 },
    ];

    const getRichTextEditorHtml = (richTextEditorContent) => {
        const editorState = createEditorState(richTextEditorContent);
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        return markup;
    };

    const onChange = key => (e) => {
        const value = e.target.value;
        setService(currentState => {
            const updatedService = { ...currentState };
            const configureNavIndex = navigation.findIndex(x => x.current === "configure");

            switch (key) {
                case "selectedFields":
                    updatedService.serviceFields = value;
                    break;
                case "productVariant":
                    updatedService.productVariant = value;
                    if (value !== ProductVariants.singleProduct) updatedService.productType = null;
                    navigation[configureNavIndex].disabled = !ProductVariants.canBeCustomised(updatedService.productVariant);
                    break;
                case "delayedPaymentPeriod":
                    updatedService.delayedPaymentPeriod = parseInt(value, 10);
                    break;
                case "productType":
                    // If we enable Company Formations product type without Company Formations category, ask for confirmation via a modal
                    if (value === ProductVariants.formationsProduct && updatedService.categoryId !== formationCompanyCategoryId) {
                        const msgText = "You have selected a non Company Formations category for a Company Formations product, would you like to update the category?";
                        setConfirmFormationCompanyCategoryText(msgText);
                        setConfirmedCategoryId(formationCompanyCategoryId);
                        toggleCompanyFormationsModal();
                    }
                    // If we enable Ready Made Company product type without Ready-made Company category, ask for confirmation via a modal
                    if (value === ProductVariants.readyMadeFormationsProduct && updatedService.categoryId !== readyMadeCompanyCategoryId) {
                        const msgText = "You have selected a non Ready-made Company category for a Ready-made Company product, would you like to update the category?";
                        setConfirmFormationCompanyCategoryText(msgText);
                        setConfirmedCategoryId(readyMadeCompanyCategoryId);
                        toggleCompanyFormationsModal();
                    }
                    updatedService[key] = value;
                    break;
                default:
                    updatedService[key] = value;
            }
            setServiceValidation(serviceValidator(updatedService, allowFlexiblePayments));
            return updatedService;
        });
    };

    const onChangeMultiple = (partialService) => {
        const updatedService = { ...service, ...partialService };

        setServiceValidation(serviceValidator(updatedService, allowFlexiblePayments));
        setService(updatedService);
    };

    const onImageDrop = (newImages) => {
        setService((prevState) => ({ ...prevState, images: [...prevState.images || [], ...newImages] }));
    };

    const onDropPostPurchaseAttachments = (documents) => {
        const newDocuments = documents?.map(x => (!x.id
            ? { ...x,
                id: uuid() }
            : x));
        setService((prevState) => ({ ...prevState, postPurchaseAttachments: [...prevState.postPurchaseAttachments || [], ...newDocuments] }));
    };

    const onDeleteService = () => {
        deleteService(service.id);
    };

    const onAddService = () => {
        const postPurchaseInformationHtml = getRichTextEditorHtml(service.postPurchaseInformation);
        const moreInformationHtml = getRichTextEditorHtml(service.moreInformation);
        const serviceToAdd = {
            ...service,
            postPurchaseInformationHtml,
            moreInformationHtml,
            upsellItems: service.upsellItems.filter(x => x !== ""),
        };
        if (!service.paymentFrequency) {
            serviceToAdd.paymentFrequency = filteredFrequencies[0];
        }

        serviceToAdd.initialChargeWithVatIfApplicable = initialChargeWithVatIfApplicable(serviceToAdd);

        let productVariant = features.isEnabled(features.customisedProducts) ? serviceToAdd.productVariant : ProductVariants.singleProduct;

        if (serviceToAdd.productType === ProductVariants.formationsProduct) { // Override the product variant on submission if company formations is selected
            productVariant = ProductVariants.formationsProduct;
        }

        if (serviceToAdd.productType === ProductVariants.readyMadeFormationsProduct) { // Override the product variant on submission if ready made company is selected
            productVariant = ProductVariants.readyMadeFormationsProduct;
        }

        if (serviceToAdd.productType === ProductVariants.bankAccountProduct) { // Override the product variant on submission if bank account is selected
            productVariant = ProductVariants.bankAccountProduct;
        }

        addService({ ...serviceToAdd, productVariant });
    };

    const checkValidationOnAddService = () => {
        if (service.serviceFields.length === 0) {
            toggleLessDetailsModal(!showLessDetailsModal);
        } else {
            onAddService();
        }
    };

    const navigateNext = () => {
        let nextNavigationItem = navigationItem.next;
        if (features.isEnabled(features.customisedProducts) && !ProductVariants.canBeCustomised(service.productVariant) && nextNavigationItem === "configure") {
            nextNavigationItem = "more-info";
        }
        onPush(nextNavigationItem)();
    };

    const navigatePrevious = () => {
        let previousNavigationItem = navigationItem.previous;
        if (features.isEnabled(features.customisedProducts) && !ProductVariants.canBeCustomised(service.productVariant) && previousNavigationItem === "configure") {
            previousNavigationItem = "pricing";
        }
        onPush(previousNavigationItem)();
    };

    useEffect(() => {
        setFilteredFrequencies(metaData.paymentFrequencies.filter(x => (!allowFlexiblePayments ? x !== "N/A" : true)));
    }, [metaData.paymentFrequencies]);

    return (
        <>
            <Modal isOpen centered size="xl" contentClassName="bg-white">
                <ModalBody className={classnames(styles.container, "d-flex flex-column p-3 p-sm-4 flex-grow-1 position-relative")} data-testid="service-modal">
                    <h3 className="font-weight-bold mb-4">
                        {isEditingService ? "Update Service" : "Add a Service"}
                    </h3>
                    <AddProductBreadcrumbs navigation={navigation} />
                    <div className={classnames("overflow-y-auto overflow-x-hidden", styles.content)}>
                        <Route
                            render={({ match: { url } }) => (
                                <Switch>
                                    <Route
                                        path={[`${url}/post-purchase`]}
                                        render={() => (
                                            <PostPurchase
                                                postPurchaseInformation={service.postPurchaseInformation}
                                                isPostPurchaseEnabled={service.isPostPurchaseEnabled}
                                                postPurchaseAttachments={service.postPurchaseAttachments}
                                                onChange={onChange}
                                                onDropPostPurchaseAttachments={onDropPostPurchaseAttachments}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={[`${url}/upsell`]}
                                        render={() => (
                                            <Upsell
                                                upsellItems={service.upsellItems}
                                                onItemsUpdated={onChange}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${url}/forms`}
                                        render={() => (
                                            <Forms
                                                fields={metaData.fields}
                                                serviceFields={service.serviceFields}
                                                onChange={onChange}
                                                errors={serviceValidation.errors}
                                                isEnquiry={false}
                                            />
                                        )}

                                    />
                                    <Route
                                        path={`${url}/more-info`}
                                        render={() => (
                                            <MoreInfo
                                                moreInformation={service.moreInformation}
                                                images={service.images}
                                                onChange={onChange}
                                                onImageDrop={onImageDrop}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${url}/auto-messages`}
                                        render={() => (
                                            <div className="m-5 d-flex flex-column justify-content-center align-items-center"><h3>Auto-messaging</h3><p>Coming soon</p></div>
                                        )}
                                    />
                                    <Route
                                        path={`${url}/configure`}
                                        render={() => (
                                            <Configure
                                                serviceId={service.id}
                                                serviceName={service.name}
                                                minimumQuantity={service.minimumQuantity}
                                                requireMinimumQuantity={service.addQuantity}
                                                allowMultipleSelection={service.allowMultipleSelection}
                                                onChange={onChange}
                                                productVariant={service.productVariant}
                                                variations={ProductVariations.getVariationsFromServiceOrDefault(service)}
                                                showPreview={showConfigurePreview}
                                                previewElement={
                                                    <ConfigureBasketModal
                                                        id={service.id}
                                                        minimumQuantity={service.minimumQuantity}
                                                        variations={ProductVariations.getVariationsFromServiceOrDefault(service)}
                                                        name={service.name}
                                                        preview
                                                        onAddToBasket={() => { }}
                                                        priceElement={<ServiceTilePriceSection
                                                            initialChargeWithVatIfApplicable={initialChargeWithVatIfApplicable(service)}
                                                            quantity={0}
                                                            onQtyChanged={() => { }}
                                                            addQuantity={false}
                                                            disableAddService
                                                            {...service}
                                                            containerClassNames="w-100 bg-white d-flex h-20px"
                                                            priceFontClassNames="font-weight-bold h6"
                                                        />}
                                                        toggle={() => setShowConfigurePreview(!showConfigurePreview)}
                                                        initialChargeWithVatIfApplicable={service.initialChargeWithVatIfApplicable}
                                                        postageCharge={service.postageCharge}
                                                        productVariant={service.productVariant}
                                                    />
                                                }
                                            />)}
                                    />
                                    <Route
                                        path={`${url}/pricing`}
                                        render={() => (
                                            <Pricing
                                                frequencies={filteredFrequencies}
                                                paymentFrequency={service.paymentFrequency || filteredFrequencies[0]}
                                                addQuantity={service.addQuantity}
                                                initialCharge={service.initialCharge}
                                                minimumQuantity={service.minimumQuantity}
                                                postageCharge={service.postageCharge}
                                                isVatRequired={service.isVatRequired}
                                                productVariant={service.productVariant}
                                                isOffsitePayment={service.isOffsitePayment}
                                                allowFlexiblePayments={allowFlexiblePayments}
                                                isFreeProduct={service.isFreeProduct}
                                                isOfflineInvoiceable={service.isOfflineInvoiceable}
                                                supplierCanOfflineInvoice={service.supplierCanOfflineInvoice}
                                                hideInMarketplace={service.hideInMarketplace}
                                                isAHexagonProduct={service.isAHexagonProduct}
                                                isCompanyFormationsProduct={service.isCompanyFormationsProduct}
                                                isDelayedPayment={service.isDelayedPayment}
                                                delayedPaymentFor={service.delayedPaymentFor}
                                                delayedPaymentPeriod={service.delayedPaymentPeriod || delayedPaymentPeriodList[0]}
                                                delayedPaymentPeriodList={delayedPaymentPeriodList}
                                                onChange={onChange}
                                                onChangeMultiple={onChangeMultiple}
                                                errors={serviceValidation.errors}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={[`${url}/general`, url]}
                                        render={() => (
                                            <General
                                                serviceId={service.id}
                                                categories={metaData.categories.filter(x => x.name !== "Enquiry Form")}
                                                subCategories={subCategories}
                                                categoryId={service.categoryId}
                                                subCategoryName={service.subCategoryName}
                                                name={service.name}
                                                description={service.description}
                                                image={service.image}
                                                onChange={onChange}
                                                onChangeMultiple={onChangeMultiple}
                                                errors={serviceValidation.errors}
                                                termsDocument={service.termsDocument}
                                                termsDocumentSaving={setSavingTerms}
                                                productVariant={service.productVariant}
                                                isCompanyFormationsProduct={service.isCompanyFormationsProduct}
                                                productType={service.productType}
                                            />
                                        )}
                                    />
                                </Switch>
                            )}
                        />
                    </div>

                    {serviceValidation.errors.offsitePaymentTermsDocument
                        && (
                            <div className="d-flex justify-center w-100 p-3 rounded font-weight-bold text-danger">
                                <i
                                    id="addAService-offsitePayment"
                                    className="font-weight-bold text-danger fas fa-info-circle mr-2"
                                    data-testid="addAService-offsitePayment"
                                />
                                {serviceValidation.errors.offsitePaymentTermsDocument}&nbsp;
                            </div>
                        )}

                    <div className="d-flex justify-content-between">
                        <div className="d-flex mt-4 pt-3 justify-content-center">
                            <Button color="secondary" disabled={!navigationItem.previous} onClick={navigatePrevious} className="mr-2">Back</Button>
                            <Button
                                color="primary"
                                disabled={!navigationItem.next || !serviceValidation.canMoveToNextScreen}
                                title={(!navigationItem.next || !serviceValidation.canMoveToNextScreen) ? "Please fill out all the required fields on the general page" : ""}
                                onClick={navigateNext}
                                data-testid="service-modal-next"
                            >
                                Next
                            </Button>
                        </div>
                        <div className="d-flex mt-4 pt-3 justify-content-center">
                            {isEditingService && <Button color="danger" data-testid="delete-product-service" onClick={onDeleteService}>Delete this service</Button>}
                            <Button color="secondary" className="mx-2" data-testid="cancel-submit-service" onClick={onPush("/mini-site")}>Cancel</Button>
                            <Button
                                className={!serviceValidation.hasEnoughToSaveService ? "btn-secondary" : "btn-tertiary"}
                                disabled={!serviceValidation.hasEnoughToSaveService || savingTerms}
                                onClick={checkValidationOnAddService}
                                data-testid="submit-service"
                            >
                                {isEditingService ? "Update this Service" : "Add this Service"}
                            </Button>
                        </div>
                    </div>
                </ModalBody>

                {showLessDetailsModal && <LessDetailedProductModal onClose={onToggleLessDetailsModal} onAddService={onAddService} />}
            </Modal>
            <ConfirmCancelModal
                isOpen={confirmFormationCompanyCategoryModalOpen}
                text={confirmFormationCompanyCategoryText}
                onConfirm={() => {
                    setService(prevState => ({ ...prevState, categoryId: confirmedCategoryId }));
                    toggleCompanyFormationsModal();
                }}
                onClose={toggleCompanyFormationsModal}
                buttonCancelText="No"
                buttonConfirmText="Yes"
            />
        </>
    );
};

ServiceModal.propTypes = {
    metaData: PropTypes.shape({
        categories: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        })).isRequired,
        subCategories: PropTypes.shape({}).isRequired,
        paymentFrequencies: PropTypes.arrayOf(PropTypes.string).isRequired,
        fields: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            displayText: PropTypes.string.isRequired,
            ordinal: PropTypes.number.isRequired,
            description: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
    selectedService: PropTypes.shape({
        id: PropTypes.string,
        categoryId: PropTypes.string,
        subCategoryName: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        initialCharge: PropTypes.number,
        postageCharge: PropTypes.number,
        upsellItems: PropTypes.arrayOf(PropTypes.string),
        productVariant: PropTypes.string,
        isOffsitePayment: PropTypes.bool,
        isFreeProduct: PropTypes.bool,
        isOfflineInvoiceable: PropTypes.bool,
        hideInMarketplace: PropTypes.bool,
        isDelayedPayment: PropTypes.bool,
        delayedPaymentFor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        delayedPaymentPeriod: PropTypes.number,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    addService: PropTypes.func.isRequired,
    deleteService: PropTypes.func.isRequired,
};

export { ServiceModal };
