import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Rating from "@/Components/Rating";
import { Image } from "@/Components/Image";
import { MultilineTextTruncate } from "@/Components/MultilineTextTruncate";

const SupplierTile = ({ id, name, description, categoryId, categoryName, primarySiteColour, logo, rating, onSupplierClick, selectedSupplierId, selectedCategoryId }) => (
    <button
        type="button"
        data-testid={name}
        onClick={onSupplierClick}
        className={
            classnames(styles.container,
                "tw-w-full tw-m-0 tw-p-0 tw-h-60 tw-max-h-60 tw-relative tw-rounded-lg tw-bg-transparent tw-mx-auto",
                !onSupplierClick && styles.notActive)
        }
        // used to style a selected supplier as well as disable the button
        disabled={(selectedSupplierId === id && selectedCategoryId === categoryId) || !onSupplierClick}
    >
        <div className="tw-w-5 tw-absolute tw-top-0 tw-bottom-0 tw-rounded-[3px]" style={{ backgroundColor: primarySiteColour }} />
        <div className={classnames(
            "tw-w-[calc(100%-theme(space.3))] tw-z-[100] tw-left-[10px] tw-shadow-bsc tw-bg-white tw-text-main-text",
            "tw-h-full tw-relative tw-p-3 tw-flex tw-flex-col tw-shadow tw-rounded-lg tw-overflow-hidden",
        )}
        >
            <div className="tw-flex tw-m-2 tw-ml-0">
                <div className="tw-w-10 tw-mr-4">
                    {logo
                        ? <Image src={logo} alt="logo" testId="supplierlogo" className="tw-w-full tw-h-10 tw-object-contain tw-aspect-square" />
                        : <span className="tw-w-10 tw-h-10 tw-bg-light-border tw-block tw-m-auto" />}
                </div>
                <div>
                    <p className="tw-mb-1 tw-font-bold tw-text-left tw-overflow-hidden tw-text-sm">{name}</p>
                    {rating > 0 && (<Rating rating={rating} className="tw-text-left" />)}
                    {rating < 1 && (<div className="tw-text-left">Not rated</div>)}
                </div>
            </div>
            <MultilineTextTruncate lines={10} text={description} className="tw-flex-grow tw-text-left tw-ml-0.5 tw-mb-6" contentClassName="!tw-text-xs tw-break-words" />
            <div className={classnames(styles.category, " tw-text-brand-primary tw-text-xs tw-text-left tw-text-bold tw-ml-0.5")}>{categoryName}</div>
        </div>
    </button>
);

SupplierTile.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    categoryId: PropTypes.string,
    categoryName: PropTypes.string.isRequired,
    primarySiteColour: PropTypes.string.isRequired,
    logo: PropTypes.string,
    rating: PropTypes.number.isRequired,
    onSupplierClick: PropTypes.func,
    selectedSupplierId: PropTypes.string,
    selectedCategoryId: PropTypes.string,
};

export default SupplierTile;
