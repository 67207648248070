import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";

import FlexPlanBasicWrapper from "@/FlexPlan/Wrappers/FlexPlanBasicWrapper";
import Spinner from "@/Components/Spinner";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { orderStatusToDisplay, OrderSummary } from "@/FlexPlan/Pages/Orders/OrderManagement/Types";
import { OrderListing } from "@/FlexPlan/Pages/Orders/OrderManagement/Components/Orders";
import { FlexPlanOrderStatus } from "@/FlexPlan/Types/Order";
import { SupplierMissingBankDetailsModal } from "@/FlexPlan/Pages/Orders/OrderManagement/Modals/SupplierMissingBankDetailsModal";

const FlexPlanSupplierOrders = () => {
    const { get } = useAPI({ handle500WithToastMessage: true });
    const [orderSummary, setOrderSummary] = useState<OrderSummary>();
    const [error, setError] = useState<string>();

    // Get the order summary
    useEffect(() => {
        get<OrderSummary>(FlexPlanUrls.suppliers.supplierOrders.summary)
            .then(orderSummary => setOrderSummary(orderSummary))
            .catch(error => setError(error));
    }, []);

    const renderSummaryBlock = () => orderSummary && Object
        .keys(orderSummary)
        .map(field => (
            <div
                className="tw-flex tw-flex-col tw-p-10 tw-items-center tw-flex-grow tw-relative"
                key={field}
            >
                <h2>{orderSummary[field]}</h2>
                <span className="tw-font-semibold">{orderStatusToDisplay(field as FlexPlanOrderStatus)}</span>
                {field !== "invalid"
                    && <div
                        className="tw-border-r-2 tw-absolute tw-top-[15%] tw-bottom-[15%] tw-right-0 tw-bottom-0"
                    />}
            </div>
        ));

    return (
        <FlexPlanBasicWrapper title="Supplier Order Dashboard">
            {error && <Alert color="danger">{error}</Alert>}
            {/* Top Banner */}
            <div className="tw-hidden md:tw-flex tw-flex-row tw-shadow-md tw-mb-5">
                {orderSummary
                    ? renderSummaryBlock()
                    : <Spinner className="tw-mx-auto tw-my-10" />}
            </div>
            <OrderListing />
            <SupplierMissingBankDetailsModal />
        </FlexPlanBasicWrapper>
    );
};

export { FlexPlanSupplierOrders };
