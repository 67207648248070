import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Alert, Button, Form } from "reactstrap";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import PasswordWithConfirmField from "@/Components/PasswordWithConfirmField";
import Spinner from "@/Components/Spinner";
import api from "@/Utils/api";
import urlHelper from "@/Utils/urlHelper";
import LoginBackgroundWrapper from "@/Wrappers/LoginBackgroundWrapper";
import { decodeCustomToken } from "@/Utils/authentication";

class ResetPassword extends React.PureComponent {
    state = {
        loading: true,
        errors: {},
    };

    componentDidMount() {
        const token = urlHelper.getUrlParam(this.props.location.search, "token");
        const isNewCustomer = decodeCustomToken(token)?.isNewCustomer === "True";

        this.setState({ loading: false, token, isNewCustomer });

        document.title = isNewCustomer ? "BSC - Set Password" : "BSC - Reset Password";
    }

    onResetPassword = (e) => {
        e.preventDefault();

        if (this.state.password !== this.state.confirmPassword) {
            this.setState(prevState => ({ ...prevState, errors: { ...prevState.errors, confirmPassword: true } }));
            return;
        }

        this.setState({ loading: true });
        api.post("account/resetpassword", {
            token: this.state.token,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
        })
            .then(() => {
                this.setState({
                    loading: false,
                    displayPasswordChanged: true,
                    password: "",
                    confirmPassword: "",
                });
            })
            .catch((error) => {
                if (error.validationFailed) {
                    this.setState({
                        loading: false,
                        ...error,
                    });
                } else {
                    this.setState({
                        loading: false,
                        errors: {
                            token_error: "Unable to reset password. Unfortunately an error occurred.",
                        },
                    });
                }
            });
    };

    edit = name => (e) => {
        this.setState({
            [name]: e.target.value,
            errors: {},
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <LoginBackgroundWrapper>
                    <div className="d-flex justify-content-center align-items-center h-50">
                        <Spinner size="45" />
                    </div>
                </LoginBackgroundWrapper>
            );
        }

        if (!this.state.token) {
            return (
                <LoginBackgroundWrapper>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className={styles.error}>
                            {this.state.isNewCustomer
                                ? "Invalid Token, please request another \"Set Password\" invitation."
                                : "Invalid token, please resend forgot password email to reset your password."}
                        </div>
                    </div>
                </LoginBackgroundWrapper>
            );
        }

        const errors = this.state.errors;
        const confirmPasswordInvalid = !!errors.confirmPassword || !!errors.password;

        return (
            <LoginBackgroundWrapper>
                <Form onSubmit={this.onResetPassword} className="m-3 pt-4">
                    {this.state.errors?.token_error && <div className={styles.error}>{this.state.errors?.token_error}</div>}
                    <PasswordWithConfirmField
                        onChange={this.edit}
                        password={this.state.password}
                        confirmPassword={this.state.confirmPassword}
                        passwordInvalid={!!errors.password}
                        confirmPasswordInvalid={confirmPasswordInvalid}

                    />

                    <div className={classnames(styles.passwordValidation, "pt-2")}>
                        <ul className="pl-2 pl-sm-4">
                            <li className={classnames(errors.password && "text-danger font-weight-bold")}>
                                Password must be at least 8 characters with mix of letters, numbers &amp; symbols
                            </li>
                            <li className={classnames(errors.passwordUpperLower && "text-danger font-weight-bold")}>
                                Letters should be a combination of uppercase and lowercase characters
                            </li>
                            <li className={classnames(this.state.password !== this.state.confirmPassword && confirmPasswordInvalid && "text-danger font-weight-bold")}>
                                The passwords must match
                            </li>
                        </ul>
                    </div>

                    {this.state.displayPasswordChanged && <Alert color="success">Your password has been updated. Please login with your new password.</Alert>}

                    {!this.state.displayPasswordChanged && (
                        <div className="d-flex justify-content-center mt-4 pt-3">
                            <Button
                                color="primary"
                                type="submit"
                                disabled={Object.keys(this.state.errors)?.length > 0}
                            >
                                {this.state.isNewCustomer ? "Set Password" : "Reset Password"}
                            </Button>
                        </div>
                    )}
                </Form>
                <div className="d-flex justify-content-center mt-4">
                    <Link className={styles.login} to="/login">Login</Link>
                </div>
            </LoginBackgroundWrapper>
        );
    }
}

ResetPassword.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
};

export default ResetPassword;
