/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { Button } from "reactstrap";
import classnames from "classnames";
import { v4 as uuid } from "uuid";

import { Document, DocumentStatus, DocumentType } from "@/Apis/Documents";
import { useForm } from "@/Hooks/useForm";
import formatDateHelper from "@/Utils/formatDateHelper";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { useDocuments } from "@/Hooks/Documents";
import Spinner from "@/Components/Spinner";
import Textbox from "@/Components/Textbox";
import { DropZoneArea } from "@/Components/Dropzones/DropZoneArea";
import { acceptedFileTypes } from "@/Utils/constants";
import styles from "@/Components/Dropzones/DropZoneArea/styles.module.scss";
import { SendDocumentNotificationModal } from "@/Modals/Documents/SendDocumentNotificationModal";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import DocumentCapture from "@/Pages/DocumentsManagement/DocumentCapture";
import useClientSidePagination from "@/Hooks/useClientSidePagination";
import { Paging } from "@/Components/Paging";
import { isValidOrderNumber } from "@/Utils/stringHelper";
import PrintLabelModal from "@/Modals/PrintLabelModal";
import { downloadBase64File } from "@/Utils/dowloadHelper";

const DocumentUpload = () => {
    document.title = "BSC - Document Details";

    const location = useLocation();
    const history = useHistory();
    const [companyNameEdit, setCompanyNameEdit] = useState<string>("");
    const [orderNumberEdit, setOrderNumberEdit] = useState<string>("");
    const { setPopupErrorMessage, setSuccessMessage } = useToastMessageContext();

    const [confirmBackModalOpen, setConfirmBackModalOpen] = useState<boolean>(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);

    const [newDocuments, setNewDocuments] = useState<Document[]>([]);
    const [activeDocument, setActiveDocument] = useState<Document>();

    const {
        formState,
        setFormState,
    } = useForm<DocumentType>(location.state);

    const {
        setItemsForPagination,
        paginatedItems,
        pageNumber,
        onPageSelected,
        totalPages,
    } = useClientSidePagination<Document>(undefined, undefined, 5);

    const {
        onDownload,
        onSave,
        processingAction,
    } = useDocuments();

    useEffect(() => {
        if (!formState.userId) {
            setPopupErrorMessage("Please select the customer.", true);
            history.push("/admin/document-management");
            return;
        }
        setCompanyNameEdit(formState.companyName);
        setOrderNumberEdit(formState.orderNumber);
        history.replace();
    }, [formState.userId]);

    const onSaveDocument = () => {
        onSave(newDocuments,
            formState?.userId,
            { isRmc: formState?.isRmc,
                companyName: companyNameEdit,
                orderNumber: orderNumberEdit },
            true)
            .then(result => {
                if (result && result.length) {
                    const newDocs = result.map(x => ({ ...x, status: DocumentStatus.Edit }));
                    setNewDocuments(newDocs);
                    setItemsForPagination(newDocs);
                    setFormState(formState => ({
                        ...formState,
                        companyName: companyNameEdit,
                        orderNumber: orderNumberEdit,
                    }));
                }
            });
    };

    const onAddDocuments = (docs: Document[]) => {
        const newDocs = docs?.map(x => ({ ...x, id: uuid(), status: DocumentStatus.New, dateCreated: new Date().toUTCString() }));
        setNewDocuments(current => [...newDocs, ...current]);
        setItemsForPagination(current => [...newDocs, ...current || []]);
    };

    const deleteDocument = () => {
        if (!activeDocument || !activeDocument.id) {
            setPopupErrorMessage("No document primed for delete");
            return;
        }
        const newDocs = (activeDocument.status === DocumentStatus.New)
            ? newDocuments?.filter(x => x.id !== activeDocument.id)
            : newDocuments?.map(x => (x.id === activeDocument.id ? { ...x, status: DocumentStatus.Delete } : x));
        setNewDocuments(newDocs);

        // If you delete the last document on the last page, select the previous page.
        const isLastOneOnLastPage = paginatedItems && paginatedItems?.length === 1 && pageNumber > 0 && pageNumber === totalPages - 1;
        if (isLastOneOnLastPage) {
            onPageSelected(pageNumber - 1);
        }
        setItemsForPagination(current => [...current ?? []]?.filter(x => x.id !== activeDocument.id));
        setSuccessMessage("File successfully deleted", true);
        setConfirmDeleteModalOpen(false);
    };

    const onDownloadDocument = (doc: Document) => {
        if (doc.status === DocumentStatus.New) {
            downloadBase64File(doc.name, doc.data);
            setSuccessMessage(`${doc.name} downloaded`, true);
            return;
        }
        onDownload(doc?.id);
    };

    const onBack = () => {
        if (formState?.companyName !== companyNameEdit
                || formState?.orderNumber !== orderNumberEdit
                || newDocuments?.some(x => x.status === DocumentStatus.New || x.status === DocumentStatus.Delete)) {
            setConfirmBackModalOpen(true);
            return;
        }
        history.push("/admin/document-management");
    };

    const onClickDeleteDocument = (doc: Document) => {
        setActiveDocument(doc);
        setConfirmDeleteModalOpen(true);
    };

    // Send notification
    const [sendNotificationModalOpen, setSendNotificationModalOpen] = useState<boolean>(false);
    const toggleNotificationModal = () => setSendNotificationModalOpen(prev => !prev);

    // Print label
    const [printLabelModalOpen, setPrintLabelModalOpen] = useState<boolean>(false);
    const togglePrintLabelModal = () => setPrintLabelModalOpen(prev => !prev);

    return (
        <div className="tw-p-4 tw-w-full lg:tw-max-w-[1240px] 3xl:tw-max-w-[1920px] tw-mx-auto">
            <button
                type="button"
                className="tw-flex tw-space-x-2 tw-items-center tw-font-bold"
                onClick={onBack}
            >
                <i className="fa fa-arrow-circle-left tw-text-xl tw-text-brand-primary" />
                <span className="tw-text-sm tw-text-brand-primary">Back</span>
            </button>
            <div className="tw-text-lg lg:tw-text-2xl 2xl:tw-text-3xl tw-font-bold tw-py-4">Documents Management</div>
            <div className="tw-w-full lg:tw-max-w-[850px] tw-mx-auto">
                <div className="tw-border tw-p-2 md:tw-p-4 tw-w-full tw-rounded-md">
                    <div className="tw-text-lg lg:tw-text-xl 2xl:tw-text-2xl tw-font-bold tw-py-4">Company Details</div>
                    <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-3">
                        <Textbox
                            label="Customer Email Address"
                            className="tw-w-full md:tw-w-1/3"
                            value={formState?.userEmail}
                            disabled
                        />
                        <Textbox
                            label="Company Name"
                            className="tw-w-full md:tw-w-1/3"
                            value={companyNameEdit}
                            onChange={(e) => setCompanyNameEdit(e.target.value)}
                            disabled={formState?.isRmc || (!formState?.isRmc && !!formState?.companyName)}
                            maxLength={255}
                        />
                        <Textbox
                            label="Order Number"
                            className="tw-w-full md:tw-w-1/3"
                            value={orderNumberEdit}
                            onChange={(e) => setOrderNumberEdit(e.target.value)}
                            error={!isValidOrderNumber(orderNumberEdit) ? "Please input the order number format" : ""}
                        />
                    </div>
                    <DropZoneArea
                        onAdd={onAddDocuments}
                        accept={acceptedFileTypes.images + acceptedFileTypes.documents}
                        disabled={processingAction}
                        className={classnames(styles.dropzone, "tw-mt-4")}
                    >
                        {processingAction ? (
                            <Spinner className="tw-mx-auto tw-my-5" />
                        ) : (
                            <div className="tw-py-6 tw-text-center">
                                <div>Drag and drop documents to upload here</div>
                                <div>Or Tap to select documents to upload</div>
                            </div>
                        )}
                    </DropZoneArea>
                    {!processingAction && (
                        <DocumentCapture
                            formState={formState}
                            onAdd={onAddDocuments}
                        />
                    )}
                    <div className="tw-mt-7">
                        <div className="tw-flex tw-flex-col tw-space-y-2">
                            {processingAction && (
                                <Spinner className="tw-mx-auto tw-my-10" text="Loading documents..." />
                            )}
                            {paginatedItems && !processingAction && paginatedItems.map(document => (
                                <div
                                    className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-border tw-border-brand-primary tw-px-3 tw-py-1 tw-rounded-md"
                                    key={document.id}
                                >
                                    <span className="tw-text-brand-primary tw-break-all">{document.name}</span>
                                    <div className="tw-flex tw-flex-row tw-items-center">
                                        <span className="tw-italic">{formatDateHelper.format(document.dateCreated, "DD/MM/YYYY")}</span>
                                        <Button color="transparent" onClick={() => onDownloadDocument(document)} disabled={processingAction}>
                                            <i className="fa fa-download tw-text-brand-primary" />
                                        </Button>
                                        <Button color="transparent" onClick={() => onClickDeleteDocument(document)} disabled={processingAction}>
                                            <i className="fa fa-trash tw-text-red-600" />
                                        </Button>
                                    </div>
                                </div>
                            ))}
                            {paginatedItems && paginatedItems.length === 0 && (
                                <div
                                    className="tw-border-brand-primary tw-pb-4 tw-rounded-md"
                                >
                                    No results
                                </div>
                            )}
                            {paginatedItems && !processingAction && paginatedItems.length > 0 && (
                                <Paging activePage={pageNumber} onChange={onPageSelected} pageCount={totalPages} />
                            )}
                        </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-space-y-3 tw-mx-auto tw-w-2/3 md:tw-flex-row md:tw-space-x-3 md:tw-w-full md:tw-space-y-0">
                        <Button
                            color="primary"
                            onClick={onSaveDocument}
                            className="tw-h-9"
                            disabled={processingAction || newDocuments?.length === 0 || !isValidOrderNumber(orderNumberEdit)}
                        >
                            Save
                        </Button>
                        <Button
                            color="primary"
                            onClick={toggleNotificationModal}
                            className="tw-h-9"
                            disabled={processingAction}
                        >
                            Send notification
                        </Button>
                        <Button
                            color="primary"
                            onClick={togglePrintLabelModal}
                            className="tw-h-9"
                            disabled={processingAction}
                        >
                            Print label
                        </Button>
                    </div>
                    <SendDocumentNotificationModal
                        isOpen={sendNotificationModalOpen}
                        userId={formState.userId}
                        onClose={toggleNotificationModal}
                    />
                    <PrintLabelModal
                        userId={formState.userId}
                        isOpen={printLabelModalOpen}
                        onClose={togglePrintLabelModal}
                    />
                    <ConfirmCancelModal
                        isOpen={confirmBackModalOpen}
                        text="Are you sure you want to leave without saving your changes?"
                        onConfirm={() => history.push("/admin/document-management")}
                        onClose={() => setConfirmBackModalOpen(false)}
                    />
                    <ConfirmCancelModal
                        isOpen={confirmDeleteModalOpen}
                        text={
                            <>
                                <p className="tw-text-base">{`Are you sure you want to delete document "${activeDocument?.name}"`}</p>
                                <p><em>You will still need to save to finalise this action.</em></p>
                            </>
                        }
                        onConfirm={deleteDocument}
                        onClose={() => setConfirmDeleteModalOpen(false)}
                        buttonConfirmText="Yes"
                    />
                </div>
            </div>
        </div>
    );
};

export { DocumentUpload };
