import React from "react";
import { Alert } from "reactstrap";

import { Heading } from "@/FlexPlan/Components/Heading";
import { ProgressBar } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Components/ProgressBar";
import { useFlexPlanSupplierSignUpContext } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Contexts/SupplierSignUpContext";
import { PublicInformationTab } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Tabs/PublicInformation";
import { PrivateInformationTab } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Tabs/PrivateInformation";
import { SupplierAlreadyExistsModal } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Modals/SupplierAlreadyExistsModal";
import { SupplierSignUpSuccessModal } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Modals/SupplierSignUpSuccessModal";

const SupplierSignUp = () => {
    const { errors, tab } = useFlexPlanSupplierSignUpContext();

    const renderTabMarkup = () => {
        switch (tab) {
            case "public":
                return <PublicInformationTab />;
            case "private":
                return <PrivateInformationTab />;
            default:
                return <></>;
        }
    };

    return (
        <>
            {/* Heading */}
            <Heading title="Supplier Sign Up" />
            {/* Progress Bar */}
            <ProgressBar className="tw-flex tw-justify-center tw-pt-10" />
            {/* Form */}
            <div
                className="tw-p-4 md:tw-p-10 tw-mt-5 tw-w-full tw-max-w-4xl tw-mx-auto"
            >
                {renderTabMarkup()}
                {Object.keys(errors).length > 0 && (
                    <Alert color="danger" className="tw-mt-4">
                        There is a problem with the some of values entered above. Please review.
                    </Alert>
                )}
            </div>
            {/* Modal */}
            <SupplierAlreadyExistsModal />
            <SupplierSignUpSuccessModal />
        </>
    );
};

export { SupplierSignUp };
