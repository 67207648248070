import React from "react";
import { Button, FormFeedback, FormGroup, Label } from "reactstrap";

import Textbox from "@/Components/Textbox";
import { getFieldName } from "@/FlexPlan/Pages/Suppliers/Types";
import { useFlexPlanSupplierSignUpContext } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Contexts/SupplierSignUpContext";
import Spinner from "@/Components/Spinner";

const PrivateInformationTab = () => {
    const {
        formState,
        onChange,
        errors,
        onBsbChange,
        bsbFirstPart,
        bsbSecondPart,
        formSubmitting,
        onTabChange,
        onSubmit,
    } = useFlexPlanSupplierSignUpContext();

    return (
        <>
            <Textbox
                label="Contact Person"
                className="tw-text-base"
                onChange={onChange}
                name={getFieldName("contactPerson")}
                value={formState.contactPerson}
                maxLength={255}
                error={errors[getFieldName("contactPerson")] && "Please enter a valid Name. Must be less than 255 characters."}
            />
            <Textbox
                label="Contact E-mail Address"
                className="tw-text-base"
                onChange={onChange}
                name={getFieldName("contactEmail")}
                value={formState.contactEmail}
                maxLength={255}
                error={(errors[getFieldName("contactEmail")] && "Please enter a contact email. Must be less than 255 characters.") || errors.email}
            />
            <Textbox
                label="Contact Number"
                className="tw-text-base"
                onChange={onChange}
                name={getFieldName("contactNumber")}
                value={formState.contactNumber}
                maxLength={255}
                error={errors[getFieldName("contactNumber")] && "Please enter a valid phone number."}
            />
            <div className="tw-border-2 tw-p-4 md:tw-p-8">
                <div className="tw-text-lg tw-font-semibold tw-mb-5">BANK DETAILS</div>
                <Textbox
                    label="Bank Name"
                    className="tw-text-base"
                    onChange={onChange}
                    name={getFieldName("bankName")}
                    value={formState.bankName}
                    maxLength={255}
                    error={errors[getFieldName("bankName")] && "Please enter a Bank Name. Must be less than 255 characters."}
                />
                <Textbox
                    label="Account Name"
                    className="tw-text-base"
                    onChange={onChange}
                    name={getFieldName("accountName")}
                    value={formState.accountName}
                    maxLength={255}
                    error={errors[getFieldName("accountName")] && "Please enter an Account Name. Must be less than 255 characters."}
                />
                <FormGroup>
                    <Label className="tw-text-base">BSB</Label>
                    <div className="tw-flex tw-flex-row tw-items-center tw-space-x-3">
                        <Textbox
                            maxLength={3}
                            onChange={onBsbChange(true)}
                            value={bsbFirstPart}
                            className="tw-w-20"
                            error={errors.bsb && " "} // We display the error below, spanning both fields
                        />
                        <i className="fa fa-minus fa-xs tw-mb-4" />
                        <Textbox
                            maxLength={3}
                            onChange={onBsbChange(false)}
                            value={bsbSecondPart}
                            className="tw-w-20"
                            error={errors.bsb && " "} // We display the error below, spanning both fields
                        />
                    </div>
                    {errors.bsb && (
                        <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !-tw-mt-2">
                            <>Please enter a valid BSB</>
                        </FormFeedback>
                    )}
                </FormGroup>
                <Textbox
                    label="Account Number"
                    className="tw-text-base"
                    onChange={onChange}
                    name={getFieldName("accountNumber")}
                    value={formState.accountNumber}
                    maxLength={9}
                    error={errors[getFieldName("accountNumber")] && "Please enter a valid Account Number."}
                />
            </div>
            <div className="tw-mt-5 tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-space-y-2 md:tw-space-y-0 md:tw-space-x-5">
                <Button
                    className="tw-w-full md:tw-w-72 !tw-text-base"
                    disabled={formSubmitting}
                    color="primary"
                    onClick={onTabChange("public")}
                    outline
                >
                    Back
                </Button>
                <Button
                    color="primary"
                    size="lg"
                    type="button"
                    className="tw-w-full md:tw-w-72 !tw-text-base"
                    disabled={formSubmitting || Object.keys(errors).length > 0}
                    onClick={onSubmit}
                >
                    {formSubmitting ? <Spinner size="20" className="tw-mx-auto" /> : "Submit"}
                </Button>
            </div>
        </>
    );
};

export { PrivateInformationTab };
