import React from "react";
import { Button, FormGroup, Label } from "reactstrap";

import { SupplierDropdown } from "./SupplierDropdown";
import { ParticipantDropdown } from "./ParticipantDropdown";

import Textbox from "@/Components/Textbox";
import NumberField from "@/Components/NumberField";
import { PreviousOrdersDropdown } from "@/FlexPlan/Pages/Orders/OrderDetails/Components/PreviousOrdersDropdown";
import Spinner from "@/Components/Spinner";
import { ServiceBookingDropdown } from "@/FlexPlan/Pages/Orders/OrderDetails/Components/ServiceBookingsDropdown";
import { ReactSelect } from "@/Components/ReactSelect";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { isSubmittedOrder } from "@/FlexPlan/Pages/Orders/OrderDetails/Utils";
import { FormError } from "@/FlexPlan/Components/FormError";

const OrderHeaderSection = () => {
    const {
        onChange,
        orderStatus,
        onGetPaymentStatusForOrder,
        updatingPaymentStatus,
        formState,
        orderLoaded,
        disableButtons,
        allowOrderTemplate,
        invalidFields,
    } = useFlexPlanOrderContext();

    if (!orderLoaded) {
        return (
            <Spinner className="tw-my-10 tw-mx-auto" />
        );
    }

    return (
        <>
            <div className="tw-flex tw-flex-row tw-space-x-3">
                <FormGroup>
                    <Label>Supplier</Label>
                    <SupplierDropdown />
                </FormGroup>
                <FormGroup>
                    <Label>Participant</Label>
                    <ParticipantDropdown />
                </FormGroup>
                <FormGroup>
                    <PreviousOrdersDropdown />
                </FormGroup>
                <FormGroup>
                    <Label>Service Booking</Label>
                    <ServiceBookingDropdown />
                </FormGroup>
            </div>
            <div className="tw-flex tw-flex-row tw-space-x-3 mt-3">
                <Textbox
                    label="Order number"
                    maxLength={255}
                    value={formState.orderNumber?.toString()}
                    disabled
                    className="tw-whitespace-nowrap tw-w-36"
                />
                <Textbox
                    label="Supplier Invoice No."
                    maxLength={255}
                    value={formState.invoiceNumber?.toString()}
                    onChange={onChange("invoiceNumber")}
                    disabled={isSubmittedOrder(formState)}
                    className="tw-whitespace-nowrap tw-w-36"
                    error={invalidFields.invoiceNumber}
                    errorClassName="!tw-w-36 !tw-whitespace-normal"
                />
                <NumberField
                    label="Invoice Total"
                    value={formState.invoiceTotal}
                    prefixChar="$"
                    min={0}
                    onChange={onChange("invoiceTotal")}
                    maxDecimalPlaces={2}
                    className="tw-w-36 tw-whitespace-nowrap"
                    disabled={isSubmittedOrder(formState)}
                    placeholder="0.00"
                />
                <NumberField
                    label="Running Total"
                    onChange={onChange("invoiceNumber")}
                    value={formState.runningTotal}
                    prefixChar="$"
                    min={0}
                    maxDecimalPlaces={2}
                    className="tw-w-36 tw-whitespace-nowrap"
                    disabled
                    placeholder="0.00"
                />
                <NumberField
                    label="Approved Running Total"
                    onChange={() => {}} // disabled
                    value={formState.approvedRunningTotal}
                    prefixChar="$"
                    min={0}
                    maxDecimalPlaces={2}
                    className="tw-w-36 tw-whitespace-nowrap"
                    disabled
                    placeholder="0.00"
                />
                <FormGroup>
                    <Label>Overall Status</Label>
                    <ReactSelect
                        className="tw-text-base tw-w-36"
                        options={orderStatus}
                        value={orderStatus.find(x => x.value === formState.orderStatus)}
                        onChange={e => onChange("orderStatus")({ target: { value: e.value } } as any)}
                    />
                    {invalidFields.orderStatus && (
                        <FormError className="!tw-w-36">
                            {invalidFields.orderStatus}
                        </FormError>
                    )}
                </FormGroup>
                <Button
                    color="primary"
                    className="tw-h-9 tw-mt-8 tw-whitespace-nowrap"
                    disabled={disableButtons || allowOrderTemplate}
                    onClick={onGetPaymentStatusForOrder}
                >
                    {updatingPaymentStatus ? <Spinner className="tw-mx-auto" size="25" /> : "Update PR Status"}
                </Button>
            </div>
        </>);
};

export { OrderHeaderSection };
