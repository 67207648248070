import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import { Button, Input, Modal, ModalBody, Spinner } from "reactstrap";
import { isMobile, isMobileOnly } from "react-device-detect";

import { Document, DocumentType } from "@/Apis/Documents";
import { isEmptyOrSpaces, isValidFileName } from "@/Utils/stringHelper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

interface ScreenshotType {
    windowWidth: number,
    windowHeight: number,
    isLandscape: boolean,
    aspectRatio: number,
}

interface Props {
    formState: DocumentType,
    onAdd: (doc: Document[]) => void,
}

const DocumentCapture = ({ formState, onAdd }: Props) => {
    const [img, setImg] = useState<string | null>(null);
    const webcamRef = useRef<Webcam>(null);

    const [isCaptureEnable, setCaptureEnable] = useState<boolean>(false);
    const [processingAction, setProcessingAction] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>("");

    const {
        setPopupErrorMessage,
    } = useToastMessageContext();

    const getScreenshotType = () : ScreenshotType => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const isLandscape = windowWidth >= windowHeight;
        const aspectRatio = isLandscape ? windowWidth / windowHeight : windowHeight / windowWidth;
        return {
            windowWidth,
            windowHeight,
            isLandscape,
            aspectRatio,
        };
    };

    const capture = useCallback(() => {
        const screenshot = getScreenshotType();
        if (webcamRef?.current) {
            const imageSrc = webcamRef.current.getScreenshot({
                width: screenshot.windowWidth * (isMobileOnly ? screenshot.aspectRatio : 1),
                height: screenshot.windowHeight * (isMobileOnly ? screenshot.aspectRatio : 1),
            });
            setImg(imageSrc);
        }
    }, [webcamRef]);

    const closeCamera = () => {
        const stream = webcamRef?.current?.stream;
        const tracks = stream?.getTracks();
        tracks?.forEach(track => track.stop());
        setImg(null);
        setFileName("");
        setCaptureEnable(false);
    };

    const retakePicture = () => {
        setImg(null);
        setFileName("");
    };

    const onChangeFileName = (event) => {
        const newFileName = event.target.value;
        if (isValidFileName(newFileName)) {
            setFileName(newFileName);
        }
    };

    const uploadPicture = () => {
        if (formState?.userId && isValidFileName(fileName) && !isEmptyOrSpaces(fileName) && img) {
            setProcessingAction(true);
            onAdd([{
                name: `${fileName.trim()}.jpg`,
                data: img,
            } as Document]);
            if (isCaptureEnable) {
                closeCamera();
            }
            setProcessingAction(false);
        } else {
            setPopupErrorMessage("No user id, upload not possible", true);
        }
    };

    if (!isMobile) {
        return <></>;
    }

    return (
        <div className="tw-pt-2">
            {isCaptureEnable || (
                <Link
                    to="#"
                    onClick={() => setCaptureEnable(true)}
                >
                    <div className="tw-flex tw-py-4 tw-border tw-rounded-md tw-border-brand-primary">
                        <div className="tw-flex tw-flex-col tw-space-y-2 tw-mx-auto">
                            <i className="fa fa-3x fa-camera tw-text-brand-primary tw-text-center" />
                            <div className="tw-text-brand-primary tw-font-semibold">Take photo to upload</div>
                        </div>
                    </div>
                </Link>
            )}
            <Modal
                isOpen={isCaptureEnable}
                fullscreen="true"
                className="tw-h-full !tw-m-0 !tw-max-w-full [&>*]:tw-h-full [&>*]:!tw-rounded-none"
            >
                <ModalBody
                    className="tw-h-full !tw-p-0 tw-relative"
                >
                    {(img === null) ? (
                        <>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    facingMode: { exact: "environment" },
                                    aspectRatio: getScreenshotType().aspectRatio,
                                }}
                                screenshotQuality={1}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                }}
                            />
                            <div className="tw-grid tw-grid-cols-1 tw-h-24 tw-items-center tw-absolute tw-bottom-0 tw-inset-x-0">
                                <div className="tw-col-span-1 tw-text-center">
                                    <button
                                        type="button"
                                        onClick={capture}
                                        disabled={processingAction}
                                        className="tw-p-6 tw-font-semibold tw-items-center tw-space-x-2 tw-rounded-full tw-bg-sky-50"
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <img src={img} alt="screenshot" className="tw-w-full tw-h-full tw-object-cover tw-object-center" />
                            <div className="tw-grid tw-grid-cols-5 tw-gap-1 tw-p-2 tw-items-center tw-absolute tw-bottom-0 tw-inset-x-0">
                                <Input
                                    placeholder="File name"
                                    className="tw-w-full tw-col-span-3"
                                    value={fileName}
                                    onChange={onChangeFileName}
                                    maxLength={255}
                                    disabled={processingAction}
                                />
                                <div className="tw-text-left">
                                    <Button
                                        color="primary"
                                        onClick={uploadPicture}
                                        disabled={!isValidFileName(fileName) || isEmptyOrSpaces(fileName) || processingAction}
                                        className="tw-col-span-1"
                                    >
                                        Upload
                                    </Button>
                                </div>
                                <div className="tw-text-center">
                                    <Button
                                        color="primary"
                                        onClick={retakePicture}
                                        disabled={processingAction}
                                        className="tw-col-span-1"
                                    >
                                        Retake
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="tw-grid tw-grid-cols-1 tw-p-2 tw-items-center tw-absolute tw-top-0 tw-inset-x-0">
                        <div className="tw-text-right">
                            <button
                                type="button"
                                onClick={closeCamera}
                                disabled={processingAction}
                                className="tw-col-span-1"
                            >
                                <i className="far fa-2x fa-times-circle tw-text-white" />
                            </button>
                        </div>
                    </div>
                    {processingAction && (
                        <div className="tw-grid tw-grid-cols-1 tw-p-2 tw-items-center tw-absolute tw-inset-y-0 tw-inset-x-0">
                            <div className="tw-text-center">
                                <Spinner className="tw-mx-auto tw-my-auto tw-text-white" />
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default DocumentCapture;
