import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { Col, Row, Media, Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { push } from "connected-react-router";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import queryString from "query-string";

import styles from "./styles.module.scss";

// eslint-disable-next-line import/extensions
import PlacedOrdersIcon from "@/Assets/Icons/Coloured/placed-orders.svg";
import Spinner from "@/Components/Spinner";
import Footer from "@/Components/Footer";
import PackageServices from "@/Components/PackageServices";
import { Visible } from "@/Components/Visible";
import { SubscriptionSummary } from "@/Components/SubscriptionSummary";
import { useBasketContext } from "@/Context/BasketContext";
import { ProductVariants } from "@/Utils/ProductVariants";
import globalActions from "@/Store/Global/actions";
import { onPackageRetrieveDebounce } from "@/Utils/redeemPackagesHelper";

interface State {
    code: string;
    productIds: string;
    products: any[],
    loading: boolean,
    success: boolean | null
}

const RedeemPackagesPage = () => {
    document.title = "BSC - Redeem Packages";

    const dispatch = useDispatch();
    const location = useLocation();
    const { applyPackage } = useBasketContext();

    const [state, setState] = useState<State>({
        code: "",
        productIds: "",
        products: [],
        loading: false,
        success: null,
    });

    const getPackage = async (code, productIds) => {
        try {
            setState(prevState => ({ ...prevState, loading: true }));

            const response = await onPackageRetrieveDebounce(code, productIds);
            if (response && Object.keys(response).length > 0) {
                const products = response.products.map(x => ({
                    ...x,
                    quantity: x.quantity,
                    completed: ProductVariants.canBeCustomised(x.productVariant),
                    configuredVariations: {
                        allowMultipleSelection: x.variations?.variationData?.allowMultipleSelection,
                        variationItems: [],
                    },
                }));

                setState(prevState => ({
                    ...prevState,
                    ...response,
                    productIds,
                    products,
                    success: true,
                    loading: false,
                }));
            } else {
                setState(prevState => ({ ...prevState, success: false, loading: false }));
            }
        } catch {
            setState(prevState => ({ ...prevState, success: false, loading: false }));
        }
    };

    useEffect(() => {
        if (location.search) {
            const newCode = queryString.parse(location.search).code;
            const newProductIds = queryString.parse(location.search).productIds;
            if (typeof newCode === "string" || newCode instanceof String) {
                // @ts-ignore
                setState(prevState => ({ ...prevState, code: newCode, productIds: newProductIds }));
                getPackage(newCode, newProductIds);
            }
        }
    }, []);

    const onChangeCodeSearch = async (e) => {
        setState(prevState => ({ ...prevState, code: e.target.value }));
        await getPackage(e.target.value, state.productIds);
    };

    const applyPackageLocal = () => {
        setState(prevState => ({ ...prevState, loading: true }));

        const request = {
            code: state.code,
            productIds: state.productIds,
            productConfigurations: state.products.map(x => ({
                serviceId: x.id,
                quantity: x.quantity,
                variations: {
                    ...x.configuredVariations,
                },
            })),
        };

        applyPackage(request)
            .then(() => {
                dispatch(push("/basket"));
            })
            .catch(() => dispatch(globalActions.setErrorToastMessage(true, "Failed to apply package")));
    };

    const onListedProductConfigured = (serviceId, quantity, variationData) => {
        const products = state.products.map(x => (x.id === serviceId ? { ...x, completed: true, quantity, configuredVariations: variationData } : x));
        setState(prevState => ({ ...prevState, products }));
    };

    const calculateTotalExcludingDueToSupplier = () => state.products
        .filter((product) => !product.isOffsitePayment)
        .reduce((total, x) => total + (x.initialChargeWithVatIfApplicable * x.quantity) + x.postageCharge, 0);

    const renderServices = () => {
        if (state.loading) {
            return <Spinner className="align-self-center" />;
        }

        if (state.success === false) {
            return <div data-testid="invalid-code" className="font-weight-bold text-center text-danger">The code you entered is incorrect. Please check and try again.</div>;
        }

        if (!state.code) {
            return <div className="text-center"><Media object src={`${(window as any).cdnUrl}misc/calvin-full-box.png`} alt="redeem Package" className={styles.image} /></div>;
        }

        return (
            <div className="mx-0 mx-lg-3">
                <p className="text-center">The items below are included in the package. Click on “Next” at the bottom to proceed.</p>
                <div className="box-shadow rounded-lg bg-white d-flex flex-column mx-3">

                    <PackageServices services={state.products} onListedProductConfigured={onListedProductConfigured} />

                    <div className={classnames(styles.subtotal, "d-flex justify-content-center p-4")}>
                        <h4 className="font-weight-bold flex-grow-1">Total Due Today</h4>
                        <h4 className="font-weight-bold">£{calculateTotalExcludingDueToSupplier().toFixed(2)}</h4>
                    </div>

                    <SubscriptionSummary services={state.products} />

                    <div className="d-flex justify-content-end p-4">
                        <Button color="primary" onClick={applyPackageLocal} className="pr-3">Next</Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.negativeMargin}>
            <div className={classnames(styles.header, "d-flex flex-column flex-lg-row pt-4 p-lg-4 bg-primary-gradient")}>
                <div className={classnames(styles.headerIconContainer, "rounded-circle bg-light p-3 mx-auto mx-lg-0")}>
                    <PlacedOrdersIcon />
                </div>
                <div className="d-flex flex-column align-content-center justify-content-center m-4">
                    <Visible below="Lg">
                        <h4 className="font-weight-bold mb-4">
                            Let’s Redeem Your Package!
                        </h4>
                    </Visible>
                    <Visible forAndAbove="Lg">
                        <h3 className="font-weight-bold mb-4">
                            Let’s Redeem Your Package!
                        </h3>
                    </Visible>
                    <p className="mt-2">
                        Automatically redeem a package and the BSC products and services within that package will be added to your basket for a fast and efficient checkout.
                    </p>
                </div>
            </div>
            <Row className="mt-4 p-4 d-block">
                <Col xs="12" className="d-flex justify-content-start justify-content-lg-center">
                    <p>
                        Please enter your package code below to retrieve the package details.
                        <Link to="/packages" className="font-weight-bold text-primary"> Interested in building a package and earning from it?</Link>
                    </p>
                </Col>
                <Col xs="12" md="10" lg="6" className="mx-auto">
                    <InputGroup className={classnames("mt-4 rounded border", state.success === false && "border-danger")}>
                        <Input
                            className={classnames(styles.input, "text-center text-uppercase shadow-none border-0")}
                            type="text"
                            onChange={onChangeCodeSearch}
                            placeholder="Enter Code"
                            value={state.code}
                        />
                        {state.success !== null && (
                            <InputGroupAddon addonType="append">
                                <span className={classnames("input-group-text bg-white border-0", {
                                    "text-success": state.success,
                                    "text-danger": !state.success,
                                })}
                                >
                                    {state.success && <i className="fa fa-check-circle h5 mb-0" />}
                                    {!state.success && <i className="fa fa-times-circle h5 mb-0" />}
                                </span>
                            </InputGroupAddon>
                        )}
                    </InputGroup>
                </Col>
            </Row>
            <Row className="flex-grow-1 d-block">
                <Col className="mt-4 d-flex flex-column">
                    {renderServices()}
                </Col>
            </Row>
            <Footer />
        </div>
    );
};

export default RedeemPackagesPage;
