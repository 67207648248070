import React, { useEffect, useState, useContext, ReactNode } from "react";
import { useSelector } from "react-redux";

import { useAPI } from "@/Apis/useAPI";
import { ReducedBasketFieldsType, BasketType, OrderType, SaveBasketFormRequest, ProductFieldAnswer, BasketGroupType, SupplierFieldDescriptions } from "@/Apis/Basket";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

export interface BasketContextType {
    loading: boolean;
    basket: BasketType;
    addToBasket({ productId, quantity, orderType, variations }: { productId: string, quantity?: number, orderType?: OrderType, variations?}): void;
    addReceiptToBasket({ receiptId }: { receiptId: string }): Promise<boolean>;
    updateQuantityInBasket({ productId, quantity }: { productId: string, quantity?: number }): void;
    removeItemFromBasket(orderId: string): Promise<void>;
    removeGroupFromBasket(groupKey: string): Promise<void>;
    clearBasket(): void;
    saveBasketForm(): void;
    agreeToTerms(orderId: string): void;
    updateBasketField(orderId: string, productFieldId: string, productFieldValue, productFieldIsValid: boolean): void;
    loadBasket(): Promise<void>;
    applyPackage(request): Promise<void>;
    reducedBasketFields: ReducedBasketFieldsType[]
    toggleBasket(show?: boolean): void,
    basketIsShowing: boolean,
}

const BasketContext = React.createContext<BasketContextType>({
    loading: true,
    basket: { groups: [] as BasketGroupType[] } as BasketType,
    addToBasket: () => { },
    addReceiptToBasket: () => new Promise(() => { }),
    updateQuantityInBasket: () => { },
    removeItemFromBasket: () => new Promise(() => { }),
    removeGroupFromBasket: () => new Promise(() => { }),
    clearBasket: () => { },
    saveBasketForm: () => { },
    agreeToTerms: () => { },
    updateBasketField: () => { },
    loadBasket: () => new Promise(() => { }),
    applyPackage: () => new Promise(() => { }),
    reducedBasketFields: [] as ReducedBasketFieldsType[],
    toggleBasket: () => { },
    basketIsShowing: false,
});
const useBasketContext = () => useContext(BasketContext);

interface RootState {
    user: UserType;
}

interface UserType {
    isLoggedIn: boolean,
}

const BasketContextProvider = ({ children }: { children: ReactNode }) => {
    const [basket, setBasket] = useState<BasketType>({ groups: [] as BasketGroupType[] } as BasketType);
    const [reducedBasketFields, setReducedBasketFields] = useState([] as ReducedBasketFieldsType[]);
    const { post, get, del, put } = useAPI({ handle500WithRedirect: true });
    const [loading, setLoading] = useState(true);
    const { setSuccessMessage, setAddToCartMessage } = useToastMessageContext();
    const [basketIsShowing, setBasketIsShowing] = useState(false);
    const loggedIn = useSelector<RootState>(state => state.user.isLoggedIn);

    useEffect(() => {
        if (!loggedIn) {
            setBasket({ groups: [] as BasketGroupType[], totalAmount: 0 } as BasketType);
        }
    }, [loggedIn]);

    const loadBasket = () => new Promise<void>(resolver => {
        get<BasketType>("basket")
            .then(response => {
                if (!response.groups) {
                    response.groups = [];
                    response.totalItems = 0;
                }
                setBasket(response);
                setLoading(false);
                resolver();
            });
    });

    const toggleBasket = (show?: boolean) => setBasketIsShowing(show ?? !basketIsShowing);

    useEffect(() => {
        loadBasket();
    }, []);

    useEffect(() => {
        setReducedBasketFields(() => (
            basket.groups.flatMap(g => g.items).reduce((previous: ReducedBasketFieldsType[], current) => {
                current.fields.map(field => {
                    let reducedBasketField = previous.find(x => x.fieldId === field.fieldId);
                    if (field.description === SupplierFieldDescriptions.CustomFields) {
                        // Separate each field based on the order
                        reducedBasketField = previous.find(x => x.fieldId === field.fieldId && x.orderId === current.orderId);
                    }
                    if (reducedBasketField) {
                        reducedBasketField.orderIds = [...reducedBasketField.orderIds, current.orderId];
                        reducedBasketField.serviceFieldIds = [...reducedBasketField.serviceFieldIds, field.serviceFieldId];
                        reducedBasketField.supplierIds = [...reducedBasketField.supplierIds, current.supplierId];
                        reducedBasketField.supplierNames = [...reducedBasketField.supplierNames, current.supplierName];
                        reducedBasketField.supplierLogos = [...reducedBasketField.supplierLogos, current.logo];
                        reducedBasketField.productNames = [...reducedBasketField.productNames, current.serviceName];
                        reducedBasketField.isRequired = reducedBasketField.isRequired ? true : field.isRequired;
                        reducedBasketField.isIdentityDocument = reducedBasketField.isIdentityDocument ? true : field.isIdentityDocument;
                    } else {
                        previous.push({
                            orderIds: [current.orderId],
                            serviceFieldIds: [field.serviceFieldId],
                            supplierIds: [current.supplierId],
                            supplierNames: [current.supplierName],
                            supplierLogos: [current.logo],
                            productNames: [current.serviceName],
                            description: field.description,
                            displayText: field.displayText,
                            fieldId: field.fieldId,
                            isRequired: field.isRequired,
                            type: field.type,
                            value: field.value,
                            serviceFieldId: field.serviceFieldId,
                            orderId: current.orderId,
                            isIdentityDocument: field.isIdentityDocument,
                        });
                    }
                    return reducedBasketField as ReducedBasketFieldsType;
                });

                return previous;
            }, [])
        ));
    }, [basket]);

    const removeItemFromBasket = async (orderId: string) => {
        setLoading(true);
        const response = await del(`basket/${orderId}`, {}).finally(() => setLoading(false));
        setBasket(response);
        setSuccessMessage("Successfully removed item.", true);
    };

    const removeGroupFromBasket = async (groupKey: string) => {
        setLoading(true);
        const response = await del(`basket/group/${groupKey}`, {}).finally(() => setLoading(false));
        setBasket(response);
        setSuccessMessage("Successfully removed group.", true);
    };

    const updateQuantityInBasket = ({ productId = "", quantity = 1 } = {}) => {
        setLoading(true);
        put("basket", { serviceId: productId, quantity }).then(response => {
            setBasket(response);
        }).finally(() => setLoading(false));
    };

    const addToBasket = ({ productId = "", quantity = 1, orderType = OrderType.Product, variations = null } = {}) => {
        setLoading(true);
        post<BasketType>("basket", { serviceId: productId, quantity, orderType, variations }).then(response => {
            setBasket(response);
            const product = response.groups.flatMap(x => x.items).find(x => x.productId === productId); // get the product just added to show on toast
            if (product) {
                setAddToCartMessage({ product, quantity, orderType, variations }, true, () => {
                    removeItemFromBasket(product.orderId);
                }, response.totalItems, toggleBasket, updateQuantityInBasket);
            }
        }).finally(() => setLoading(false));
    };

    const addReceiptToBasket = async ({ receiptId = "" } = {}): Promise<boolean> => new Promise<boolean>(resolver => {
        setLoading(true);
        post(`receipts/add-to-basket/${receiptId}`, {})
            .then(async () => {
                await loadBasket();
                resolver(true);
            })
            .catch(() => resolver(false))
            .finally(() => setLoading(false));
    });

    const clearBasket = () => {
        setLoading(true);
        del("basket", {}).then(response => {
            setBasket(response);
            setSuccessMessage("Order placed successfully.", true);
        }).finally(() => setLoading(false));
    };

    const saveBasketForm = () => {
        setLoading(true);
        const saveBasketFormRequest: SaveBasketFormRequest = { saveBasketOrders: [] };

        reducedBasketFields.forEach(field => {
            for (let i = 0; i < field.orderIds.length; i++) {
                const sOrderId = field.orderIds[i];
                const sFieldId = field.serviceFieldIds[i];
                const sValue = field.value;
                const answer: ProductFieldAnswer = {
                    serviceFieldId: sFieldId,
                    value: sValue,
                };

                let basketOrder = saveBasketFormRequest.saveBasketOrders.find(i => i.orderId === sOrderId);
                if (basketOrder) {
                    basketOrder.serviceFieldsAnswers.push(answer);
                } else {
                    const sfAnswers: ProductFieldAnswer[] = [];
                    sfAnswers.push(answer);
                    basketOrder = {
                        orderId: sOrderId,
                        serviceFieldsAnswers: sfAnswers,
                    };
                    saveBasketFormRequest.saveBasketOrders.push(basketOrder);
                }
            }
        });

        post<BasketType>("basket/saveBasketForm", saveBasketFormRequest).then(response => {
            setBasket(response);
        }).finally(() => setLoading(false));
    };

    const agreeToTerms = (orderId: string) => {
        setBasket((currentState) => {
            const order = currentState.groups.flatMap(_ => _.items).find(_ => _.orderId === orderId);
            if (order) {
                order.termsAccepted = order.termsAccepted === undefined ? true : !order.termsAccepted;
            }
            return {
                ...currentState,
            };
        });
    };

    const updateBasketField = (orderId: string, productFieldId: string, productFieldValue, productFieldIsValid = true) => {
        setBasket(currentState => {
            // get the index of the order item we are editing
            const groupIndex = currentState.groups.findIndex(x => x.items.find(y => y.orderId === orderId) != null);
            const itemIndex = currentState.groups[groupIndex].items.findIndex(x => x.orderId === orderId);
            const serviceFieldIndex = currentState.groups[groupIndex].items[itemIndex].fields.findIndex(x => x.serviceFieldId === productFieldId);
            // update the value of the field in the basket
            const newState: BasketType = {
                ...currentState,
                groups: currentState.groups.map((g, gIndex) => ({
                    ...g,
                    items: g.items.map((itemToMap, itemIndexToMap) => {
                        if (itemIndexToMap === itemIndex && gIndex === groupIndex) {
                            return {
                                ...itemToMap,
                                fields: itemToMap.fields.map((fieldToMap, fieldIndexToMap) => {
                                    if (fieldIndexToMap === serviceFieldIndex) {
                                        return { ...fieldToMap, value: productFieldValue, isValid: productFieldIsValid };
                                    }
                                    return fieldToMap;
                                }),
                            };
                        }
                        return itemToMap;
                    }),
                })),
            };
            // check if there are any unanswered required fields left
            const unansweredRequiredFields = newState.groups[groupIndex].items[itemIndex].fields.some(x => x.isRequired && (!x.value || x.value === "null"));
            const invalidFields = newState.groups[groupIndex].items[itemIndex].fields.some(x => !x.isValid);
            return {
                ...newState,
                groups: newState.groups.map((g, gIndex) => ({
                    ...g,
                    items: g.items.map((item, index) => {
                        if (index === itemIndex && gIndex === groupIndex) {
                            return {
                                ...item,
                                requiredFieldsCompleted: !unansweredRequiredFields,
                                invalidFields,
                            };
                        }
                        return item;
                    }),
                })),
            } as BasketType;
        });
    };

    const applyPackage = (request) => (post("packages/apply", request).then(response => {
        setBasket(response as BasketType);
    }));

    return (
        <BasketContext.Provider value={
            {
                basket,
                loading,
                addToBasket,
                addReceiptToBasket,
                updateQuantityInBasket,
                removeItemFromBasket,
                removeGroupFromBasket,
                clearBasket,
                saveBasketForm,
                agreeToTerms,
                updateBasketField,
                loadBasket,
                applyPackage,
                reducedBasketFields,
                toggleBasket,
                basketIsShowing,
            } as BasketContextType
        }
        >
            {children}
        </BasketContext.Provider>);
};

export { useBasketContext, BasketContextProvider };
