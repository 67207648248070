import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router";

import UsersSearch from "@/Pages/DocumentsManagement/UsersSearch";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { DocumentType } from "@/Apis/Documents";
import Textbox from "@/Components/Textbox";
import Checkbox from "@/Components/Checkbox";
import { RmcListingType } from "@/Apis/Rmcs/RmcListingType";
import { Urls } from "@/Apis/urls";
import { isValidOrderNumber } from "@/Utils/stringHelper";

const DocumentsManagement = () => {
    document.title = "BSC - Documents Management";
    const { get } = useAPI({ handle500WithToastMessage: true });
    const { setPopupErrorMessage } = useToastMessageContext();

    const [documentType, setDocumentType] = useState<DocumentType>();
    const [rmcOptions, setRmcOptions] = useState<any>();

    useEffect(() => {
        get<RmcListingType[]>(Urls.rmcs.base)
            .then((response) => {
                const options = response.map(x => ({
                    label: x.name,
                    value: x.id,
                    id: x.id,
                    userId: x.userId,
                    userEmail: x.userEmail,
                    companyName: x.name,
                }));
                setRmcOptions(options);
            })
            .catch(error => {
                setRmcOptions([]);
                setPopupErrorMessage(error, true);
            });
    }, []);

    const onChangeIsRmc = (value) => {
        setDocumentType({
            ...documentType,
            isRmc: value,
            rmcId: "",
            userId: "",
            userEmail: "",
            companyName: "",
        } as DocumentType);
    };

    const onChangeOrderNumber = (event) => {
        const newCode = event.target.value;
        setDocumentType({
            ...documentType,
            orderNumber: newCode,
        } as DocumentType);
    };

    const onChangeSelect = (key: string) => (selectedOption) => {
        switch (key) {
            case "rmc":
                setDocumentType({
                    ...documentType,
                    rmcId: selectedOption?.id,
                    userId: selectedOption?.userId,
                    userEmail: selectedOption?.userEmail,
                    companyName: selectedOption?.companyName,
                } as DocumentType);
                break;
            case "user":
                setDocumentType({
                    ...documentType,
                    rmcId: "",
                    userId: selectedOption?.value,
                    userEmail: selectedOption?.label,
                    companyName: selectedOption?.companyName,
                } as DocumentType);
                break;
            default:
                break;
        }
    };

    const history = useHistory();

    const onUpload = () => {
        history.push({
            pathname: "/admin/document-upload",
            state: documentType,
        });
    };

    const onSearch = () => {
        history.push({
            pathname: "/admin/document-search",
            state: documentType,
        });
    };

    return (
        <div className="tw-p-4 tw-w-full lg:tw-max-w-[1240px] 3xl:tw-max-w-[1920px] tw-mx-auto">
            <div className="tw-text-lg lg:tw-text-2xl 2xl:tw-text-3xl tw-font-bold tw-py-4">Documents Management</div>
            <div className="tw-w-full lg:tw-max-w-[850px] tw-mx-auto">
                <div className="tw-flex tw-flex-col">
                    <div className="tw-border tw-p-2 md:tw-p-4 tw-w-full tw-rounded-md">
                        <FormGroup>
                            <Checkbox
                                checked={documentType?.isRmc ?? false}
                                onChange={onChangeIsRmc}
                                id="chk-isrmc"
                                label="This is an RMC."
                                className="tw-font-semibold"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{documentType?.isRmc ? "Company Name *" : "Email Address/Company Name *"}</Label>
                            {!documentType?.isRmc && (
                                <UsersSearch
                                    value={
                                        { value: documentType?.userId,
                                            label: documentType?.userEmail,
                                            companyName: documentType?.companyName }
                                    }
                                    onChange={onChangeSelect("user")}
                                />)}
                            {documentType?.isRmc && (
                                <Select
                                    id="company-name"
                                    options={rmcOptions}
                                    value={rmcOptions?.find(x => x.value === documentType?.rmcId)}
                                    onChange={onChangeSelect("rmc")}
                                    isClearable
                                />
                            )}
                        </FormGroup>
                        <Textbox
                            label="Order Number"
                            className="md:tw-w-1/2"
                            value={documentType?.orderNumber}
                            onChange={onChangeOrderNumber}
                            error={!isValidOrderNumber(documentType?.orderNumber) ? "Please input the order number format" : ""}
                        />
                        <div className="tw-flex tw-flex-row tw-space-x-3 tw-w-full md:tw-w-1/2">
                            <Button
                                color="primary"
                                onClick={onUpload}
                                className="tw-h-9"
                                disabled={!documentType?.userId || (documentType?.isRmc && !documentType?.rmcId) || !isValidOrderNumber(documentType?.orderNumber)}
                            >
                                Upload
                            </Button>
                            <Button
                                color="primary"
                                onClick={onSearch}
                                className="tw-h-9"
                                disabled={!documentType?.userId || (documentType?.isRmc && !documentType?.rmcId)}
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { DocumentsManagement };
