import React, { useState } from "react";
import { Badge } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { Variations } from "@/Components/ConfigureProductRow/ColumnTypes";
import { CircleButton } from "@/Components/CircleButton";
import { ConfigureBasketModal } from "@/Modals/ConfigureBasketModal";
import { ProductVariant, ProductVariants } from "@/Utils/ProductVariants";

interface Props {
    id: string;
    name: string;
    minimumQuantity: number;
    onAddService(qty: number, variationData: any): void
    disableAddService: boolean;
    productVariant: keyof ProductVariant;
    variations: Variations;
    priceElement: React.ReactElement;
    postageCharge: number;
    initialChargeWithVatIfApplicable: number;
    isBeingUsedForPackages: boolean;
    onCartClick: () => void;
    quantityInBasket: number | 0,
}

const AddToBasket = (
    {
        id,
        minimumQuantity,
        name,
        onAddService,
        disableAddService,
        productVariant,
        variations,
        priceElement,
        initialChargeWithVatIfApplicable,
        postageCharge,
        isBeingUsedForPackages,
        onCartClick,
        quantityInBasket = 0,
    }: Props,
) => {
    const [showConfigureBasketModal, setShowConfigureBasketModal] = useState(false);
    const onConfigureClick = () => setShowConfigureBasketModal(!showConfigureBasketModal);

    const qtyToDisplay = quantityInBasket > 9 ? "9+" : quantityInBasket;

    const Cart = () => (
        <div className="position-relative">
            <CircleButton
                className={classnames(styles.basketButton, "text-dark")}
                onClick={onCartClick}
                disabled={disableAddService}
                testId="add-to-basket-button"
            >
                {isBeingUsedForPackages ? (<i className="fas fa-plus" data-testid="add-to-package-icon" />)
                    : <i className="fas fa-shopping-cart mt-1" data-testid="add-to-basket-icon" />}
            </CircleButton>
            <Badge
                className={classnames(styles.basketBadge, `${quantityInBasket === 0 || disableAddService ? "d-none" : ""}`, "rounded-circle position-absolute")}
                color="secondary"
            >
                <small>{qtyToDisplay}</small>
            </Badge>
        </div>
    );

    const Configure = () => (
        <CircleButton
            className={classnames(styles.basketButton, "text-dark")}
            onClick={onConfigureClick}
            disabled={disableAddService}
            testId="configure-button"
        >
            {isBeingUsedForPackages ? (<>Add to Package</>) : <i className="fas fa-pencil-ruler" />}
        </CircleButton>
    );

    return (
        <>
            {ProductVariants.canBeCustomised(productVariant) && !isBeingUsedForPackages ? <Configure /> : <Cart />}
            {showConfigureBasketModal && <ConfigureBasketModal
                id={id}
                minimumQuantity={minimumQuantity}
                variations={variations}
                name={name}
                onAddToBasket={onAddService}
                priceElement={priceElement}
                toggle={onConfigureClick}
                initialChargeWithVatIfApplicable={initialChargeWithVatIfApplicable}
                postageCharge={postageCharge}
                productVariant={productVariant}
            />}
        </>);
};

export { AddToBasket };
