import React, { useState } from "react";
import { Row, Col, Button, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { useForm } from "react-hook-form";

import styles from "./styles.module.scss";

import ShowHideIconLabel from "@/Components/ShowHideIconLabel";
import { useAPI } from "@/Apis/useAPI";
import actions from "@/Store/Global/actions";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { features } from "@/Utils/features";

const AccountPage = () => {
    const dispatch = useDispatch();
    const { loading, post } = useAPI();
    const email: string = useSelector<{ user: { email: string } }>(state => state.user.email) as string;

    const [editing, toggleEditing] = useState(false);
    const [showPasswords, toggleShowPasswords] = useState(false);
    const onEdit = () => toggleEditing(true);
    const onCancel = () => toggleEditing(false);
    const onToggleShowPasswords = () => toggleShowPasswords(!showPasswords);

    const { register, handleSubmit, errors, setError, reset } = useForm();
    const [generalErrors, setGeneralErrors] = useState<string[]>(); // ASP.Net identity errors don't map to the property in question

    const onSubmit = async (data) => {
        let body = { email: data.email };
        if (data.currentPassword) {
            body = data;
        }

        const epiEndpoint = features.isEnabled(features.FlexPlan) ? FlexPlanUrls.account.updateSecurity : "account/security";

        post(epiEndpoint, body)
            .then(() => {
                dispatch(actions.setToastMessage(true));
                // dispatch change email to email
                reset({ email: data.email });
            })
            .catch((error) => {
                if (error.validationFailed) {
                    if (error.errors) {
                        Object.keys(error.errors).forEach((x) => {
                            setError(x, "notMatch", error.errors[x]);
                        });
                    } else if (error.errorList && error.errorList.length > 0) {
                        setGeneralErrors(error.errorList);
                    }
                }
            });
    };

    const renderEditForm = () => (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="pb-3">
                <Label>Email Address *</Label>
                <Input
                    type="text"
                    name="email"
                    innerRef={register({ required: "This is a required field." })}
                    defaultValue={email}
                />
                {errors.email && <span className="text-danger font-weight-bold">{errors.email.message}</span>}
            </FormGroup>

            <hr className="my-3" />

            <div className="d-flex justify-content-end">
                <ShowHideIconLabel show={showPasswords} label="password" onToggle={onToggleShowPasswords} />
            </div>

            <FormGroup>
                <Label>Current Password *</Label>
                <Input
                    type={showPasswords ? "text" : "password"}
                    name="currentPassword"
                    innerRef={register}
                    placeholder="Current password"
                    invalid={!!errors.currentPassword}
                />
                {errors.currentPassword && <span className="text-danger font-weight-bold">{errors.currentPassword.message}</span>}
            </FormGroup>

            <FormGroup>
                <Label>New Password *</Label>
                <Input
                    type={showPasswords ? "text" : "password"}
                    name="newPassword"
                    innerRef={register}
                    placeholder="New password"
                    invalid={!!errors.newPassword}
                />
                {errors.newPassword && <span className="text-danger font-weight-bold">{errors.newPassword.message}</span>}
            </FormGroup>

            <FormGroup>
                <Label>Confirm Password *</Label>
                <Input
                    type={showPasswords ? "text" : "password"}
                    name="confirmPassword"
                    innerRef={register}
                    placeholder="Confirm password"
                    invalid={!!errors.confirmPassword}
                />
                {errors.confirmPassword && <span className="text-danger font-weight-bold">{errors.confirmPassword.message}</span>}
            </FormGroup>

            <div className={classnames(styles.passwordValidation, "pt-2")}>
                <ul className="pl-2 pl-sm-4">
                    <li className={classnames(errors.password && "text-danger font-weight-bold")}>
                        Password must be at least 8 characters with mix of letters, numbers &amp; symbols
                    </li>
                    <li className={classnames(errors.passwordUpperLower && "text-danger font-weight-bold")}>
                        Letters should be a combination of uppercase and lowercase characters
                    </li>
                    <li className={classnames((errors.confirmPassword && errors.password !== errors.confirmPassword) && "text-danger font-weight-bold")}>
                        The passwords must match
                    </li>
                </ul>
            </div>
            {generalErrors && (
                <div className="tw-pt-2">
                    <ul className="pl-2 pl-sm-4">
                        {generalErrors.map(error => (
                            <li className="text-danger font-weight-bold">
                                {error}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <Row>
                <Col className="d-flex justify-content-end">
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        className="mr-1"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" disabled={loading} color="primary">
                        {loading ? <Spinner size="sm" /> : "Save"}
                    </Button>
                </Col>
            </Row>
        </Form>
    );

    return (
        <div className="border rounded-lg p-4 m-lg-3 mb-4">
            <Row>
                <Col xs="12" className="d-flex justify-content-between mb-3">
                    <h4 className="font-weight-bold">Account information</h4>
                    {!editing && <Button onClick={onEdit} className="px-3" color="primary">Edit</Button>}
                </Col>
            </Row>
            {editing ? renderEditForm() : (
                <>
                    <FormGroup className="d-flex w-75">
                        <Label className="w-25">Email Address</Label>
                        <Label>{email}</Label>
                    </FormGroup>
                    <hr />
                    <FormGroup className="d-flex w-75 mt-3">
                        <Label className="w-25">Current Password</Label>
                        <Label>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</Label>
                    </FormGroup>
                </>
            )}
        </div>
    );
};

export { AccountPage };
