import React, { useEffect, useState } from "react";
import { Col, Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { Link, Route, Switch } from "react-router-dom";
import { useLocation, useParams } from "react-router";

import styles from "./styles.module.scss";

import SideBarLink from "@/Components/SideBarLink";
// eslint-disable-next-line import/extensions
import ProfileIcon from "@/Assets/Icons/Coloured/profile.svg";
import urlHelper from "@/Utils/urlHelper";
import { PublicInformationTab } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Tabs/PublicInformationTab";
import { useFlexPlanSupplierProfileContext, useFlexPlanSupplierProfileDispatchContext } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Contexts/SupplierProfileContext";
import { PrivateInformationTab } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Tabs/PrivateInformationTab";
import { FlexPlanSupplier, SupplierType } from "@/FlexPlan/Pages/Suppliers/Types";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useAPI } from "@/Apis/useAPI";
import { isNumericString, isValidABN, isValidAccountNumber, isValidBsb, isValidPhone } from "@/FlexPlan/Utils/validtor";
import { exceedsMaxStringLength, isEmptyOrSpaces } from "@/Utils/stringHelper";
import { validateEmail } from "@/Utils/validator";
import { instanceOf } from "@/Utils/objectHelper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { SupplierProfileActions } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types/Reducer";
import { SupplierSections } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types";
import { useInvalidFields } from "@/Hooks/useInvalidFields";

interface LocationState {
    from: string,
}

const SupplierProfile = () => {
    // Context
    const supplierProfileState = useFlexPlanSupplierProfileContext();
    const dispatch = useFlexPlanSupplierProfileDispatchContext();
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    // Hooks
    const location = useLocation<LocationState>();
    const { id } = useParams();
    const { get, put, loading } = useAPI({ handle500WithToastMessage: true });
    const { errors, setInvalidField, setAllInvalidFields, removeInvalidField } = useInvalidFields<FlexPlanSupplier>();

    // State
    const [isOpen, toggleOpen] = useState(false);
    const [openSections, setOpenSections] = useState<SupplierSections[]>([]);

    // Functionality
    const toggle = () => toggleOpen(!isOpen);

    useEffect(() => {
        get<FlexPlanSupplier>(FlexPlanUrls.suppliers.getSingle(id))
            .then(profile => {
                dispatch({
                    value: profile,
                    type: SupplierProfileActions.Seed,
                });
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message);
                }
            });
    }, [id]);

    const publicFieldsHasError = (formData: FlexPlanSupplier): boolean => {
        let errorFound: boolean = false;

        if (formData.supplierType === SupplierType.Abn && !isValidABN(formData.abn)) {
            errorFound = true;
            setInvalidField("abn", "Please enter a valid ABN. Must be eleven numeric characters.");
        }

        if (isEmptyOrSpaces(formData.businessName) || exceedsMaxStringLength(formData.businessName)) {
            errorFound = true;
            setInvalidField("businessName", "Please enter a business name. Must be less than 255 characters.");
        }

        if (isEmptyOrSpaces(formData.address)) {
            errorFound = true;
            setInvalidField("address", "Please enter an address.");
        }

        if (!isEmptyOrSpaces(formData.ndisRegistrationNumber) && !isNumericString(formData.ndisRegistrationNumber)) {
            errorFound = true;
            setInvalidField("ndisRegistrationNumber", "Please enter a valid NDIS number.");
        }

        return errorFound;
    };

    const privateFieldsHasError = (formData: FlexPlanSupplier): boolean => {
        let errorFound: boolean = false;

        if (formData.contactPerson && exceedsMaxStringLength(formData.contactPerson)) {
            errorFound = true;
            setInvalidField("contactPerson", "Please enter a valid Name. Must be less than 255 characters.");
        }

        if (formData.contactEmail && (!validateEmail(formData.contactEmail) || exceedsMaxStringLength(formData.contactEmail))) {
            errorFound = true;
            setInvalidField("contactEmail", "Please enter a contact email. Must be less than 255 characters.");
        }

        if (formData.contactNumber && (!isValidPhone(formData.contactNumber) || exceedsMaxStringLength(formData.contactNumber))) {
            errorFound = true;
            setInvalidField("contactNumber", "Please enter a valid phone number.");
        }

        if (formData.bankName && exceedsMaxStringLength(formData.bankName)) {
            errorFound = true;
            setInvalidField("bankName", "Please enter a Bank Name. Must be less than 255 characters.");
        }

        if (formData.accountName && exceedsMaxStringLength(formData.accountName)) {
            errorFound = true;
            setInvalidField("accountName", "Please enter an Account Name. Must be less than 255 characters.");
        }

        if (formData.bsb && !isValidBsb(formData.bsb)) {
            errorFound = true;
            setInvalidField("bsb", "Please enter a valid BSB");
        }

        if (formData.accountName && !isValidAccountNumber(formData.accountNumber)) {
            errorFound = true;
            setInvalidField("accountNumber", "Please enter a valid Account Number.");
        }

        return errorFound;
    };

    const checkForAndDisplayErrors = (formData: FlexPlanSupplier): boolean => {
        const publicFieldError = publicFieldsHasError(formData);
        const privateFieldError = privateFieldsHasError(formData);

        return publicFieldError || privateFieldError;
    };

    const onChangeEditableSections = (value: SupplierSections) => () => {
        if (openSections.includes(value)) {
            setOpenSections(prevState => prevState.filter(x => x !== value));
        } else {
            setOpenSections(prevState => [...prevState, value]);
        }
    };

    const onSave = (customForm?: FlexPlanSupplier) => {
        const formData: FlexPlanSupplier | undefined = instanceOf<FlexPlanSupplier>(customForm)
            ? customForm
            : supplierProfileState;

        if (!formData) {
            setPopupErrorMessage("No form data", true);
            return;
        }

        if (checkForAndDisplayErrors(formData)) {
            setPopupErrorMessage("Validation failed", true);
            return;
        }

        put(FlexPlanUrls.suppliers.update, {
            ...formData,
            id,
        })
            .then(() => {
                setSuccessMessage("Profile successfully saved", true);
                setOpenSections([]); // Close the editable sections on save
            })
            .catch((error) => {
                if (error.validationFailed) {
                    setAllInvalidFields(error.errors);
                } else if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error, true);
                }
            });
    };

    const renderTabs = () => (
        <Row className="border-bottom my-2 tw-mx-5">
            <Nav tabs className="border-0">
                <NavItem className={classnames(urlHelper.urlContains(location, "public-information") ? styles.activeTab : styles.tab, "font-weight-bold mx-4")}>
                    <NavLink className="px-0 border-0 text-primary" tag={Link} to={`/suppliers/${id}/public-information`}>
                        Public Information
                    </NavLink>
                </NavItem>
                <NavItem className={classnames(urlHelper.urlContains(location, "private-information") ? styles.activeTab : styles.tab, "font-weight-bold mx-4")}>
                    <NavLink className="px-0 border-0 text-primary" tag={Link} to={`/suppliers/${id}/private-information`}>
                        Private Information
                    </NavLink>
                </NavItem>
            </Nav>
        </Row>
    );

    return (
        <Row className="box-shadow bg-white mt-5 mb-5 d-block d-md-flex  mx-md-5">
            <Col md="4" lg="3" xl="2" className="border-right py-4 px-0 d-none d-md-flex flex-column ">
                <h3 className="m-3 ml-lg-4 pl-0 pl-lg-2 mb-md-4 d-none d-lg-block font-weight-bold">{supplierProfileState?.businessName}</h3>
                <h5 className="m-3 ml-lg-4 pl-0 pl-lg-2 mb-md-4 d-block d-lg-none font-weight-bold">{supplierProfileState?.businessName}</h5>
                <SideBarLink
                    icon={<ProfileIcon />}
                    text="Information"
                    isActive // It's the only sidebar link
                    to={`/suppliers/${id}/public-information`}
                />
            </Col>
            <Col>
                <Row>
                    <Col className={styles.header}>
                        <h4 className="d-inline text-white position-relative font-weight-bold">Company Information</h4>
                    </Col>
                </Row>
                <Row>
                    <Navbar light className="col-12 px-0 d-md-none">
                        <NavbarToggler onClick={toggle} className="ml-auto mr-3 my-2" />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav navbar className="my-3">
                                <NavItem>
                                    <SideBarLink
                                        icon={<ProfileIcon />}
                                        text="Company Information"
                                        isActive
                                        to={`/suppliers/${id}/public-information`}
                                    />
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Row>
                <Route
                    path="/suppliers"
                    render={({ match: { url } }) => (
                        <Switch>
                            <Route
                                exact
                                path={`${url}/${id}/public-information`}
                                render={() => (
                                    <>
                                        {renderTabs()}
                                        <PublicInformationTab
                                            onSave={onSave}
                                            removeErrorField={removeInvalidField}
                                            onChangeEditableSections={onChangeEditableSections}
                                            errors={errors}
                                            loading={loading}
                                            openSections={openSections}
                                        />
                                    </>
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/${id}/private-information`}
                                render={() => (
                                    <>
                                        {renderTabs()}
                                        <PrivateInformationTab
                                            onSave={onSave}
                                            removeErrorField={removeInvalidField}
                                            onChangeEditableSections={onChangeEditableSections}
                                            errors={errors}
                                            loading={loading}
                                            openSections={openSections}
                                        />
                                    </>
                                )}
                            />
                        </Switch>
                    )}
                />
            </Col>
        </Row>
    );
};

export { SupplierProfile };
